import React, {FC} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StyledDatePicker} from './StyledDatePicker.styles';
import {useTranslation} from 'react-i18next';
import {enUS, ptBR} from '@mui/x-date-pickers/locales';

export type DateProps = {
    label: string;
    onChange: (newValue: Date | null) => void;
    name: string;
    error: boolean;
    disablePast?: boolean;
    disableFuture?: boolean;
};

const MUIDatePicker: FC<DateProps> = ({label, onChange, name, error, disablePast = true, disableFuture = false}) => {
    const {i18n} = useTranslation();

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
            localeText={
                i18n.language === 'pt'
                    ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                    : enUS.components.MuiLocalizationProvider.defaultProps.localeText
            }
        >
            <StyledDatePicker
                format="DD-MM-YYYY"
                label={label}
                disablePast={disablePast}
                disableFuture={disableFuture}
                slotProps={{
                    textField: {
                        name: name,
                    },
                }}
                onChange={onChange}
                sx={{
                    border: error ? '1px solid red !important' : '#fff',
                    backgroundColor: error ? '#ffebee !important' : '#fff',
                }}
            />
        </LocalizationProvider>
    );
};

export default MUIDatePicker;
