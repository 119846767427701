import React from 'react';
import {ImageSmall, ImageBox} from './ClubDetails.styles';
import {useClubById} from '../../../../hooks/useClubById';
import {useCommon} from '../../../../context/CommonContext';
import {AppService} from '../../../../services/app.service';
import {Spinner} from '../../../../components/Common/Spinner/Spinner';

export type ClubImageDefaultProps = {
    clubId: string;
};

export const ClubImageDefaultForm = ({clubId}: ClubImageDefaultProps) => {
    const {data: club, isLoading} = useClubById('private', clubId);
    const {setModal} = useCommon();
    const appService = new AppService();

    const handleDefaultImage = async (photoUrl: string) => {
        const data = {
            isDefault: true,
            photoUrl: photoUrl,
        };
        await appService.api_updateClubPhotoDefault(clubId, data).then(() => {
            setModal({open: false, children: <></>});
        });
    };

    return isLoading ? (
        <Spinner fullPage={false} />
    ) : (
        <ImageBox>
            {club?.photos?.map((photo: any, index: number) => (
                <ImageSmall
                    isDefault={photo.isDefault}
                    key={index}
                    src={photo.photoUrl}
                    alt=""
                    onClick={() => handleDefaultImage(photo.photoUrl)}
                />
            ))}
        </ImageBox>
    );
};
