import {Box, DialogContent, Menu, Toolbar, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from 'styled-components';
import {AppButton} from '../Buttons/AppButton';

export const StyledToolbar = styled(Toolbar)`
    background: white;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
        overflow: auto;
        justify-content: flex-start;
    }
`;

export const MenuLink = styled(Typography).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isActive',
})<{isActive?: boolean}>`
    padding: 19px 16px;
    cursor: pointer;
    color: ${({isActive}) => (isActive ? 'var(--color-primary)' : 'var(--custom-secondary)')};
    border-bottom: ${({isActive}) => (isActive ? '2px solid var(--color-primary)' : '2px solid #fff')};

    @media (max-width: 600px) {
        padding: 19px 6px;
        font-size: 12px !important;
        min-width: fit-content;
    }
`;

export const MUIPersonIcon = styled(PersonIcon)`
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #1976d2;

    @media (max-width: 600px) {
        width: 20px;
        height: 20px;
    }
`;

export const MUIMenu = styled(Menu)`
    top: 50px !important;
    left: 22px !important;

    @media (max-width: 600px) {
        top: 40px !important;
    }
`;

export const StyledName = styled.div`
    position: absolute;
    left: 50px;
    width: 300px;
`;

export const StyledMenuBox = styled(Box)`
    display: flex;
    align-items: center;
    position: relative;
`;

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;

export const StyledDialogContent = styled(DialogContent)`
    @media (max-width: 600px) {
        padding: 10px !important;
    }
`;
