import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {StyledDatePicker, StyledSelect} from '../search-engine/SearchEngine.styles';
import {FormControl, InputLabel, MenuItem, FormHelperText} from '@mui/material';

import {timeUnits} from '../../utils/constants';
import {PropTypes} from './HourAndDateForm.types';
import {useSearch} from '../../hooks/useSearch';
import useSearchContext from '../../contexts/SearchContext';

export const HourAndDateForm: FC<PropTypes> = ({error, additionalSearchObj, ...remainingProps}) => {
    const {t} = useTranslation();
    const {
        availableHours,
        actions: {handleInputChange},
    } = useSearch();
    const {searchObj} = useSearchContext();
    return (
        <>
            <FormControl id="date-select" error={error?.field === 'date'} required>
                <StyledDatePicker
                    disablePast={true}
                    format="DD-MM-YYYY"
                    label={t('search.labels.date')}
                    value={searchObj.date}
                    slotProps={{
                        textField: {
                            required: true,
                            error: error?.field === 'date',
                            helperText: error?.field === 'date' ? error.message : undefined,
                        },
                    }}
                    onChange={(date: any) => handleInputChange('date', date, additionalSearchObj ?? {})}
                />
            </FormControl>
            <FormControl id="time-select" error={error?.field === 'time'} required>
                <InputLabel id="search-time">{t('search.labels.time')}</InputLabel>
                <StyledSelect
                    required
                    name="time"
                    variant="outlined"
                    labelId="search-time"
                    label={t('search.labels.time')}
                    value={searchObj.time}
                    onChange={(e) => handleInputChange('time', e.target.value as string, additionalSearchObj ?? {})}
                >
                    {availableHours.map((hour) => (
                        <MenuItem key={`time-${hour.value}`} value={hour.value}>
                            {hour.label}
                        </MenuItem>
                    ))}
                </StyledSelect>
                {error?.field === 'time' && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
            <FormControl id="time-unit-select" error={error?.field === 'timeUnit'} required>
                <InputLabel id="search-timeUnit">{t('search.labels.timeUnit')}</InputLabel>
                <StyledSelect
                    required
                    variant="outlined"
                    value={searchObj.timeUnit}
                    labelId="search-timeUnit"
                    name="timeUnit"
                    label={t('search.labels.timeUnit')}
                    onChange={({target: {name, value}}) =>
                        handleInputChange(name, value as string, additionalSearchObj ?? {})
                    }
                >
                    {timeUnits?.map((timeUnit) => (
                        <MenuItem key={`timeUnit-${timeUnit.value}`} value={timeUnit.value}>
                            {timeUnit.label}
                        </MenuItem>
                    ))}
                </StyledSelect>
                {error?.field === 'timeUnit' && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
        </>
    );
};
