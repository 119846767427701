import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Box, Button} from '@mui/material';
import {Input} from '../../components/Common/Input/TextField/Input';
import {Layout} from '../../components/Common/Layout/Layout';
import {GoBackButton} from '../../components/Common/GoBackButton';
import React from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {SnackbarAlert} from '../../components/Common/SnackbarAlert';
import {getURLWithReturn} from '../../utils/returnUrl';
import {StyledBackgroundImage} from './VerifyAccount.styles';

const VerifyAccount = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [code, setCode] = useState('');
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const email = searchParams.get('email') || '';
    const return_url = searchParams.get('return_url');

    //Translation
    const {t} = useTranslation();

    useEffect(() => {
        if (email === '') navigate(getURLWithReturn('/login', return_url));
    }, [email, navigate]);

    const handleSubmit = async () => {
        if (code === '') return;

        try {
            const res = await axios.post(
                `${window.location.origin}/api/auth/player/validate`,
                {email, code},
                {
                    headers: {'Content-Type': 'application/json'},
                }
            );

            if (res.status !== 200) throw new Error(t('verify.error.notVerified'));

            alert(t('verify.successMessage'));
            navigate(getURLWithReturn('/login', return_url));
            return;
        } catch (e: any) {
            const error = e.response?.data?.message || e.message || t('generalError');
            console.log(error);
            setErrorAlertOpen(true);
            return;
        }
    };

    return (
        <>
            <Layout>
                <Box
                    sx={{
                        width: '100%',
                        padding: '1rem 0 5rem',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{display: 'flex'}}>
                        <GoBackButton to="/login" />
                    </Box>
                    <img
                        src="/logo_aveiro_tech_city_white.png"
                        alt="FreeCourts: Courts for everyone"
                        style={{
                            margin: '24px auto',
                            height: '82px',
                            width: 'fit-content',
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            marginTop: 'auto',
                        }}
                    >
                        <Input
                            type="number"
                            label={t('verify.verificationCode')}
                            variant="filled"
                            value={code}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleSubmit}>
                            {t('verify.okButton')}
                        </Button>
                    </Box>
                </Box>

                <SnackbarAlert
                    vertical="top"
                    horizontal="center"
                    open={errorAlertOpen}
                    setOpen={setErrorAlertOpen}
                    variant="filled"
                    severity="error"
                >
                    {t('verify.error.notVerified')}
                </SnackbarAlert>
            </Layout>
            <StyledBackgroundImage src="/images/basketball.jpg" alt="Basketball background image" />
        </>
    );
};

export default VerifyAccount;
