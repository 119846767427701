import styled from 'styled-components';
import {Input} from '../../../../components/Common/Input/TextField/Input';
import {Box, FormLabel} from '@mui/material';

export const StyledInput = styled(Input)`
    margin-bottom: 2rem !important;
`;

export const StyledFormLabel = styled(FormLabel)`
    color: #d32f2f !important;
    font-size: small !important;
    margin-left: 32px;
`;

export const Asterisk = styled.span`
    color: #d32f2f;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const CardDetailsBox = styled(Box)`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 8px;
`;
