import React, {useEffect, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';
import {AppService} from '../../services/app.service';
import {useCommon} from '../../context/CommonContext';
import {t} from 'i18next';

const ExternalProvider = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const fetched = useRef(false);
    const [searchParams] = useSearchParams();
    const {setSnackbar} = useCommon();

    const return_url = sessionStorage.getItem('google.returnURL');

    useEffect(() => {
        if (fetched.current) return;
        fetched.current = true;

        const code = searchParams.get('code');
        const error = searchParams.get('error_description');
        if (error) {
            navigate(return_url ? `/login?return_url=${encodeURIComponent(return_url)}` : '/login');
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('validation.invalidSSO'),
            });
        }

        if (code) {
            new AppService()
                .api_loginWithGoogle({code: code})
                .then((res) => {
                    if (res.status !== 200) throw res;
                    login(res.data, 'player');
                    navigate(return_url ?? '/');
                })
                .catch((error) => {
                    console.log(error);
                    navigate(return_url ? `/login?return_url=${encodeURIComponent(return_url)}` : '/login');
                })
                .finally(() => sessionStorage.removeItem('google.returnURL'));
        }
    }, [navigate]);

    return <p>A entrar...</p>;
};

export default ExternalProvider;
