import {Box} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import Slider from 'react-slick';
import styled from 'styled-components';
import {disableTextFieldStyles} from '../../../search-page/components/search-engine/SearchEngine.styles';
import {commonContainerStyles, largeHeaderStyles} from '../../utils/shared.styles';

export const MainBox = styled(Box)`
    display: flex;
    position: relative;
    padding-bottom: 40px;
    align-items: flex-end;
    min-height: calc(100vh - 84px);

    @media (min-width: 600px) {
        min-height: unset;
        height: calc(100vh - 84px);
    }
`;

export const StyledSlider = styled(Slider)`
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;

    .slick-list,
    .slick-track,
    .slick-slide div {
        height: 100%;
    }
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
    filter: brightness(0.4);
`;

export const MainBoxInner = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;

    ${commonContainerStyles};
`;

export const MainTitle = styled.h1`
    color: white;
    max-width: 850px;
    position: relative;

    ${largeHeaderStyles};
`;

export const Form = styled(Box)`
    top: 50%;
    left: 50%;
    display: flex;
    max-width: 325px;
    position: absolute;
    width: calc(100% - 48px);
    transform: translate(-50%, -100%);
`;

export const StyledHomePageDatePicker = styled(DatePicker)`
    overflow: hidden;
    border-radius: 5px;
    background-color: white;

    @media (pointer: fine) {
        ${disableTextFieldStyles};
    }
`;

export const StyledButton = styled.button`
    width: 70px;
    color: white;
    border: unset;
    margin-left: 15px;
    border-radius: 5px;
    background-color: black;
`;
