import styled from 'styled-components';
import {Box} from '@mui/material';
import {mediumTextStyle, smallHeaderStyles, commonContainerStyles} from '../../utils/shared.styles';
import {Layout} from '../../../../components/Common/Layout/Layout';

export const StyledLayout = styled(Layout)`
    padding-left: 0 !important;
    padding-right: 0 !important;
`;

export const BookNowBox = styled(Box)`
    color: white;
    padding-block: 80px;
    background-color: #1976d2;

    > div {
        gap: 40px;
        display: flex;
        flex-direction: column;
        ${commonContainerStyles};
    }
`;

export const BookNowTitle = styled.h3`
    ${smallHeaderStyles};
`;

export const BookNowButtons = styled(Box)`
    gap: 15px;
    display: flex;

    ${mediumTextStyle};

    button {
        border: unset;
        cursor: pointer;
        padding: 10px 15px;

        ${mediumTextStyle};

        color: white;
        background-color: #21295c;
    }
`;

export const Footer = styled.footer`
    padding-block: 60px;
    background-color: #1976d2;
`;

export const InnerFooter = styled(Box)`
    display: flex;
    ${commonContainerStyles};
`;

export const FooterColumn = styled(Box)`
    flex: 1;
    gap: 20px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;

    ${mediumTextStyle};

    a {
        color: white;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`;
