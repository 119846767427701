import styled, {css} from 'styled-components';

import {Box} from '@mui/material';

export const ClubSport = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'selected',
})<{selected?: boolean}>`
    position: relative;
    display: inline-block;
    padding-right: 5px;

    img {
        width: 16px;
        height: 16px;

        ${({selected}) =>
            selected &&
            css`
                filter: invert(1);
            `}
    }
`;
