import React from 'react';
import {useTranslation} from 'react-i18next';
import Money from '../../../../assets/icons/money.svg';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import {getLabelForTimeUnit} from '../../../../utils/getLabelForTimeUnit';

export const PriceUnit = ({
    price,
    timeUnit,
    variant,
    privacy,
}: {
    price?: string;
    timeUnit?: string;
    variant: string;
    privacy?: string;
}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Money} />
                {t('club.headers.priceByTimeUnit')}
            </Header>
            {privacy === 'PUBLIC' ? 'NA' : !!price ? `${parseFloat(`${price}`).toFixed(2)} €` : '-'} /{' '}
            {!!timeUnit ? getLabelForTimeUnit(timeUnit) : '-'}
        </StyledBox>
    );
};
