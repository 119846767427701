import {AvailableHourType} from '../types/AvailableHour.type';
import {generateHoursArray} from './generateHoursArray';

export const districts = [
    'Viana do Castelo',
    'Castelo Branco',
    'Braga',
    'Porto',
    'Aveiro',
    'Viseu',
    'Coimbra',
    'Leiria',
    'Santarém',
    'Lisboa',
    'Setúbal',
    'Alentejo',
    'Algarve',
    'Madeira',
    'Açores',
];

export const hours = generateHoursArray('8:00', '23:00');
export const timeUnits = generateHoursArray('01:00', '02:00');

export const fieldsOrder = ['sport', 'date', 'time', 'timeUnit', 'district', 'clubName'];
export const privacies = ['NOT_SPECIFIED', 'PRIVATE', 'PUBLIC'];
export const environments = ['NOT_SPECIFIED', 'INDOOR', 'OUTDOOR'];

export const notRequiredFields = ['sport', 'clubName'];
export const valuesToLowerCase = ['sport', 'privacy', 'environment'];
export const valuesToTimeStamp = ['date'];
