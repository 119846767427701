import React, {useState} from 'react';
import {ModalStyled} from '../Common/Modal/Modal';
import {Input} from '../Common/Input/TextField/Input';
import Stack from '@mui/material/Stack';
import {AppButton} from '../Common/Buttons/AppButton';
import {AppService} from '../../services/app.service';
import {SnackbarAlert} from '../Common/SnackbarAlert';
import {AlertColor, Dialog, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {isPasswordValid} from '../../utils/validation';

export type ClubAdminFirstLoginProps = {
    open: boolean;
    onClose: () => void;
    email: string;
    password: string;
};

const formInitialValue = {
    newPassword: '',
    confirmNewPassword: '',
};

export const ClubAdminFirstLogin = ({open, onClose, email, password}: ClubAdminFirstLoginProps) => {
    const {t} = useTranslation();

    const [sendingForm, setSendingForm] = useState(false);
    const [formData, setFormData] = useState(formInitialValue);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alert, setAlert] = useState({
        severity: 'error',
        message: 'Erro',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const validatePassword = (password: string) => {
        return isPasswordValid(password);
    };

    const passwordIsValid = formData.newPassword === '' || validatePassword(formData.newPassword);
    const passwordsMatch = formData.confirmNewPassword === '' || formData.newPassword === formData.confirmNewPassword;

    const handleReset = () => {
        setFormData(formInitialValue);
        onClose();
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (
            formData.newPassword === '' ||
            formData.confirmNewPassword === '' ||
            !passwordIsValid ||
            !passwordsMatch ||
            sendingForm
        )
            return;

        const data = {...formData, email, password};

        setSendingForm(true);
        new AppService()
            .api_changeTemporaryPassword('club-admin', data)
            .then((res) => {
                if (res.status !== 200) throw new Error();

                setAlert({
                    severity: 'success',
                    message: t('firstLogin.success'),
                });
                setAlertOpen(true);
                handleReset();
            })
            .catch((error) => {
                console.log(error);
                setAlert({
                    severity: 'error',
                    message: t('firstLogin.error.onSubmit'),
                });
                setAlertOpen(true);
            })
            .finally(() => setSendingForm(false));
    };

    return (
        <>
            <Dialog open={open} onClose={handleReset} maxWidth={'xs'}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2} p={2}>
                        <Typography variant="h6">{t('firstLogin.title')}</Typography>
                        <Typography variant="body1">{t('firstLogin.description')}</Typography>
                        <label style={{marginTop: 24}} htmlFor="newPassword">
                            {t('firstLogin.newPasswordLabel')}
                        </label>
                        <Input
                            id="newPassword"
                            name="newPassword"
                            label={t('firstLogin.newPassword')}
                            type="password"
                            variant="filled"
                            autoComplete="off"
                            error={!passwordIsValid}
                            helperText={!passwordIsValid ? t('firstLogin.error.passwordNotValid') : undefined}
                            required
                            value={formData.newPassword}
                            onChange={handleInputChange}
                        />
                        <label style={{marginTop: 24}} htmlFor="confirmNewPassword">
                            {t('firstLogin.confirmNewPasswordLabel')}
                        </label>
                        <Input
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            label={t('firstLogin.newPassword')}
                            type="password"
                            variant="filled"
                            autoComplete="off"
                            error={!passwordsMatch}
                            helperText={!passwordsMatch ? t('firstLogin.error.passwordNotMatching') : undefined}
                            required
                            value={formData.confirmNewPassword}
                            onChange={handleInputChange}
                        />
                        <AppButton variant="contained" type="submit" disabled={sendingForm}>
                            {t('firstLogin.submitButton')}
                        </AppButton>
                        <AppButton variant="outlined" onClick={handleReset}>
                            {t('cancel')}
                        </AppButton>
                    </Stack>
                </form>
            </Dialog>
            <SnackbarAlert open={alertOpen} setOpen={setAlertOpen} severity={alert.severity as AlertColor | undefined}>
                {alert.message}
            </SnackbarAlert>
        </>
    );
};
