import React, {FC} from 'react';
import {sportIconsMapping} from '../../utils/sport-icons';
import {PropTypes} from './SportIcon.types';
import {ClubSport} from './SportIcon.styles';

export const SportIcon: FC<PropTypes> = ({category, selected, ...remainingProps}) => {
    return (
        <ClubSport selected={selected} {...remainingProps}>
            {typeof category === 'string' && <img src={sportIconsMapping[category.toLowerCase()]} alt="" />}
        </ClubSport>
    );
};
