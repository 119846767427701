import {useState} from 'react';
import {AppService} from '../../../services/app.service';
import {useNavigate} from 'react-router-dom';
import { useCommon } from '../../../context/CommonContext';
import { useTranslation } from 'react-i18next';

export const useCreateBooking = () => {
    const api_service = new AppService();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { setSnackbar } = useCommon();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createBooking = (role: string, booking: any, return_url: string) => {
        setIsLoading(true);
        api_service
            .api_createBooking(role, booking)
            .then((res) => {
                if ([401, 403].includes(res.status)) {
                    navigate(`/login?return_url=${encodeURIComponent(return_url)}`);
                    return;
                }
                if (res.status !== 201) throw new Error();

                navigate(`/booking-details/${res.data.id}`);
                setSnackbar({ open: true, setOpen: ()=>{setSnackbar({open: false, children:null, setOpen: ()=>{}})}, severity: "success", children: t('booking.label.bookingSuccess') })
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    return {isLoading, actions: {createBooking}};
};
