import {Category} from '../../../shared/types/Category.type';
import {getTimeUnit} from '../../../utils/getTimeUnit';
import {SearchObjectType} from '../types/SearchObject.type';
import {fromObjectToQueryString} from './fromObjectToQueryString';

export const getBookingUrlAndState = (
    role: string,
    searchObj: SearchObjectType,
    categories: Category[],
    clubId: string,
    area: {id: string; name: string},
    price: number,
    sport?: Category | undefined
) => {
    const finalSearchObj = {
        ...searchObj,
        ...(!!sport && {
            sport: sport.toLowerCase(),
        }),
    };
    if (role === 'club-admin') {
        const from = finalSearchObj.date.add(Number(finalSearchObj.time) + 3600, 'seconds');
        const to = from.add(Number(finalSearchObj.timeUnit), 'seconds');

        const state = {
            area: area.name,
            areaId: area.id,
            from: from.toDate(),
            to: to.utc().toDate(),
            day: finalSearchObj.date.toDate(),
            timeUnit: getTimeUnit(finalSearchObj.timeUnit),
            price,
            ...(finalSearchObj.sport && {
                sport: categories?.find((cat) => cat.toLowerCase() === finalSearchObj.sport),
            }),
        };
        return {url: `/club-admin/club/${clubId}/bookings/create`, state};
    }
    const searchObjProps = fromObjectToQueryString(finalSearchObj);
    const sportId = categories?.find((cat) => cat.toLowerCase() === finalSearchObj.sport);
    return {url: `/booking?area=${area.id}&price=${price}${searchObjProps}&sportId=${sportId}`, state: {}};
};
