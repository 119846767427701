import React, {FC} from 'react';
import {AccordionSummary, AccordionDetails, Alert, AlertTitle} from '@mui/material';

import useSearchContext from '../../../search-page/contexts/SearchContext';

import {areaSections} from '../../utils/constants';
import {renderSection} from '../../utils/renderSections';
import {PropTypes} from './ClubArea.types';

import {
    Area,
    AreaDescription,
    AreaHeader,
    AreaImage,
    AreaImageContainer,
    AreaInfoContainer,
    AreaPrice,
    AreaSearchContainer,
    AreaTitle,
    StyledAccordion,
    StyledAreaSearchResultActions,
    StyledTypography,
} from './ClubArea.styles';
import {FlexContainer} from '../club-description/ClubDescription.styles';
import {useTranslation} from 'react-i18next';
import Carousel from 'react-material-ui-carousel';

export const ClubArea: FC<PropTypes> = ({area, images, isSearchEnabled}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const {results} = useSearchContext();
    const {t} = useTranslation();

    const clubResult = results?.[0];

    const areaResult = clubResult?.areas.find((clubArea) => clubArea.id === area.id);
    return (
        <Area id={`area-${area.id}`}>
            <AreaHeader>
                <AreaImageContainer>
                    {images?.length > 0 ? (
                        <Carousel
                            className="carousel"
                            autoPlay={false}
                            navButtonsAlwaysVisible={images?.length > 1 ? true : false}
                            indicators={false}
                            navButtonsProps={{className: 'carousel-nav-buttons'}}
                        >
                            {images?.map((image: any, idx: number) => (
                                <AreaImage key={idx} src={image.photoUrl} />
                            ))}
                        </Carousel>
                    ) : (
                        <AreaImage src="https://placehold.co/400" />
                    )}
                </AreaImageContainer>
                <AreaInfoContainer>
                    <FlexContainer>
                        <AreaTitle>{area.name}</AreaTitle>
                        {areaResult ? (
                            <AreaPrice>
                                <h3>{areaResult.price} €</h3>
                            </AreaPrice>
                        ) : null}
                    </FlexContainer>
                    {!!area.description && <AreaDescription>{area.description}</AreaDescription>}
                    <StyledAccordion expanded={expanded} onChange={() => setExpanded((previous) => !previous)}>
                        <AccordionSummary>
                            <StyledTypography expanded={expanded}>
                                {expanded ? t('less') : t('more')} info
                            </StyledTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {areaSections.map((section) => renderSection(section, area, 'small'))}
                        </AccordionDetails>
                    </StyledAccordion>
                    {clubResult && areaResult && isSearchEnabled ? (
                        <AreaSearchContainer>
                            <StyledAreaSearchResultActions
                                categories={areaResult.categories}
                                clubId={clubResult.id}
                                areaId={areaResult.id}
                                price={areaResult.price}
                                privacy={areaResult.privacy}
                                areaName={areaResult.name}
                                areaSchedules={areaResult.schedules}
                                areaClosedSchedules={areaResult.closedSchedules}
                                reservations={areaResult.reservations}
                                displayOptions={{
                                    showPrice: false,
                                    showClosedClubLabel: true,
                                }}
                            />
                        </AreaSearchContainer>
                    ) : (
                        <AreaSearchContainer>
                            <Alert severity="info">
                                <AlertTitle>{t('clubAreas.message.noSportInfoTitle')}</AlertTitle>
                                {t('clubAreas.message.noSportInfoDescription')}
                            </Alert>
                        </AreaSearchContainer>
                    )}
                </AreaInfoContainer>
            </AreaHeader>
        </Area>
    );
};
