export function useRole() {
    const role = localStorage.getItem("role");

  if (role === "club-admin") {
    return "private";
  } else if (role === "admin") {
    return "admin";
  } else if (role === "player") {
    return "public";
  } else {
    return "unknown";
  }
}