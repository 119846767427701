import React, {FC} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StyledTimePicker} from './StyledTimePicker.styles';

export type TimerProps = {
    label: string;
    onChange: (newValue: Date | null) => void;
    name: string;
    error: boolean | undefined;
    value?: any;
    disablePast?: boolean;
    disableFuture?: boolean;
    minTime?: any;
    maxTime?: any;
};

const MUITimePicker: FC<TimerProps> = ({
    label,
    onChange,
    name,
    error,
    value,
    disablePast,
    disableFuture,
    minTime,
    maxTime,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledTimePicker
                format="HH:mm"
                label={label}
                disablePast={disablePast}
                disableFuture={disableFuture}
                ampm={false}
                disableIgnoringDatePartForTimeValidation={true}
                minTime={minTime}
                maxTime={maxTime}
                slotProps={{
                    textField: {
                        name: name,
                        error: error,
                    },
                }}
                timeSteps={{hours: 1, minutes: 15}}
                value={value}
                onChange={onChange}
                sx={{
                    backgroundColor: error ? '#ffebee !important' : '#fff',
                }}
            />
        </LocalizationProvider>
    );
};

export default MUITimePicker;
