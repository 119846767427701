import {TFunction} from 'i18next';
import * as yup from 'yup';
import {ReservationTypeEnum} from '../shared/enums/ReservationType.enum';

export const validationCreateBookingSchema = (t: TFunction) => {
    return yup.object({
        name: yup
            .string()
            .min(3, t('validation.toShort'))
            .max(50, t('validation.toLong'))
            .required(t('validation.requiredName')),
        email: yup.string().email(t('validation.requiredValidEmail')).required(t('validation.requiredEmail')),
        mobilePhone: yup
            .string()
            .matches(/^[0-9]+$/, t('validation.phoneOnlyNumber'))
            .phone('PT', t('validation.requiredValidPhone'))
            .required(t('validation.requiredPhone')),
        sport: yup
            .string()
            .oneOf(
                [
                    'PADEL',
                    'TENIS',
                    'BASQUETEBOL',
                    'ANDEBOL',
                    'FUTEBOL',
                    'FUT5',
                    'FUT7',
                    'FUTSAL',
                    'SQUASH',
                    'HOQUEI_PATINS',
                    'VOLEIBOL',
                ],
                t('validation.requiredField')
            ),
        reservationType: yup.string().default(ReservationTypeEnum.PRIVATE),
        from: yup.date().required(t('validation.requiredField')),
        to: yup.date().min(yup.ref('from'), t('validation.dateTo')).required(t('validation.requiredField')),
    });
};
