import {useQuery} from 'react-query';
import {AppService} from '../services/app.service';

export function useClubById(role: string, clubId: string) {
    const appService = new AppService();
    const {isLoading, data = []} = useQuery({
        queryKey: ['clubById'],
        queryFn: async () => {
            const res = await appService.api_getClubById(role, clubId);
            return res.data;
        },
    });

    return {data, isLoading};
}
