/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function Admin() {
    const client_id = 'ctdoikq17k5icfk5uhf05s19k';
    const redirect_uri = 'http://localhost:3000/admin';
    const logout_uri = 'http://localhost:3000';
    const api_url = 'http://localhost:3001/api/private';
    const aws_token_url = 'https://conexa-admin.auth.us-east-1.amazoncognito.com/oauth2/token';
    const aws_logout_url = `https://conexa-admin.auth.us-east-1.amazoncognito.com/logout?client_id=${client_id}&logout_uri=${logout_uri}`;

    const [message, setMessage] = useState('');
    useEffect(() => {
        getToken();
    }, []);

    async function getToken() {
        try {
            const url = window.location.href;
            const parsedUrl = new URL(url);
            const code = parsedUrl.searchParams.get('code');
            if (!code) return;
            const params = new URLSearchParams();
            params.append('grant_type', 'authorization_code');
            params.append('client_id', client_id);
            params.append('code', code);
            params.append('redirect_uri', redirect_uri);

            const response = await axios.post(
                aws_token_url,
                params.toString(), // Convert params to a string
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            await getAdminInfo(response.data.access_token);
        } catch (error) {
            console.log('error', error);
        }
    }

    async function getAdminInfo(access_token: string) {
        try {
            console.log('token', access_token);
            const response = await axios.get(api_url, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            setMessage(response.data.message);
        } catch (error) {
            console.log('error Admin', error);
        }
    }
    return (
        <div>
            {message.length > 0 ? (
                <>
                    <h1>{message}</h1>
                    <Link to={aws_logout_url}>LOGOUT</Link>
                </>
            ) : (
                <h1>Loading...</h1>
            )}
        </div>
    );
}

export default Admin;
