import {Popup, Tooltip} from 'react-leaflet';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
    border: unset;
    color: white;
    border-radius: 0;
    font-weight: bold;
    background-color: #000;
`;

export const StyledPopup = styled(Popup)`
    .leaflet-popup-content-wrapper {
        border-radius: 6px;
        padding: 20px;
    }

    .leaflet-popup-content {
        /* max-height: 11rem; */
        overflow: auto;
        width: 25rem;
        margin: 0;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ClubName = styled.span`
    font-weight: bold;
    margin: 8px 0;
    font-size: 1rem;
`;

export const ClubDescription = styled.span`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    margin-top: 5px;
    -webkit-box-orient: vertical;
`;

export const Areas = styled.ul`
    padding: 0;
    margin-bottom: 0;
`;

export const Area = styled.li`
    border-bottom: 1px solid #cfcfcf;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    padding-bottom: 6px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const AreaText = styled.span`
    margin: 0;
    max-width: 9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const AreaPrice = styled.p`
    flex: 1;
    margin: 0 !important;
    margin-right: 1rem !important;
    display: flex;
    flex-shrink: 0;
    text-align: end;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: flex-end;
    justify-content: flex-end;
    white-space: nowrap;
`;

export const AreaImage = styled.img`
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
    max-height: 200px;
`;
