import React, {FC, useEffect, useRef} from 'react';
import {useMap, MapContainer, TileLayer, Marker, useMapEvents} from 'react-leaflet';

import {MapType} from './Map.types';
import * as S from './Map.styles';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useSearchContext from '../../pages/search-page/contexts/SearchContext';
import {Button} from '@mui/material';
import {MinimalArea} from '../../pages/search-page/types/SearchResult.type';
import {getBookingUrlAndState} from '../../pages/search-page/utils/getBookingUrlAndState';
import {useAuth} from '../../hooks/useAuth';
import {fromObjectToQueryString} from '../../pages/search-page/utils/fromObjectToQueryString';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [17, 46],
    popupAnchor: [-3, -26],
});

let HighlightIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [17, 46],
    popupAnchor: [-3, -26],
    className: 'highlight',
});

const ChangeCenter = ({center}: {center: {lat: number; lng: number}}) => {
    const map = useMap();
    map.setView(center, map.getZoom());

    return null;
};

const ClickHandler = ({onClickHandler}: {onClickHandler: (value: {lat: number; lng: number}) => void}) => {
    useMapEvents({
        click(e) {
            onClickHandler(e.latlng);
        },
    });

    return null;
};

export const Map: FC<MapType> = ({
    center,
    mapInfos,
    zoom = 12,
    zoomControl = false,
    withPrice = true,
    showPopUp,
    isActive = true,
    onClickHandler,
    ...remaininProps
}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {role} = useAuth();
    const {pin} = useSearchContext();
    const {searchObj, categories, setPin} = useSearchContext();
    const markersRefs = useRef<any>([]);

    const handleBookingNavigation = (clubId: string, area: MinimalArea, price: number) => {
        if (searchObj.sport.length === 1) {
            const {url, state} = getBookingUrlAndState(
                role,
                searchObj,
                categories!,
                clubId,
                area,
                price,
                searchObj.sport[0]
            );
            navigate(url, {state: {...state}});
        } else if (area.categories.length === 1) {
            const {url, state} = getBookingUrlAndState(
                role,
                searchObj,
                categories!,
                clubId,
                area,
                price,
                area.categories[0]
            );
            navigate(url, {state: {...state}});
        } else {
            const url = fromObjectToQueryString(searchObj);
            navigate(`/club/${clubId}?area=${area.id}${url}`);
        }
        return;
    };

    useEffect(() => {
        if (showPopUp && pin) {
            const markerIndex = mapInfos.findIndex((marker) => marker.clubId === pin);
            if (markerIndex !== undefined) {
                const element = markersRefs.current[markerIndex];

                element.openPopup();
                element._icon?.classList?.add('highlight');
            }
        }
    }, [pin]);

    const popUpOpen = () => {
        setPin(pin);
    };

    const popUpClosed = () => {
        setPin(undefined);
    };

    return (
        <MapContainer center={center} minZoom={10} zoom={zoom} zoomControl={zoomControl} {...remaininProps}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {mapInfos.map(({center, clubId, clubName, address, areas, clubPhoto}, index) => {
                const isHighlighted = pin === clubId;
                return (
                    <Marker
                        key={index}
                        ref={(el) => (markersRefs.current[index] = el)}
                        position={center}
                        icon={showPopUp && isHighlighted ? HighlightIcon : DefaultIcon}
                        zIndexOffset={showPopUp && isHighlighted ? 1000 : 1}
                        eventHandlers={{
                            popupopen: (e) => {
                                popUpOpen();
                                e.target._icon?.classList?.add('highlight');
                            },
                            popupclose: (e) => {
                                popUpClosed();
                                e.target._icon?.classList?.remove('highlight');
                            },
                        }}
                    >
                        {showPopUp && (
                            <S.StyledPopup offset={[0, -5]}>
                                <S.Wrapper>
                                    {clubPhoto && <S.AreaImage src={clubPhoto} />}
                                    {!!clubName && <S.ClubName>{clubName}</S.ClubName>}
                                    {!!address && <S.ClubDescription>{address}</S.ClubDescription>}
                                </S.Wrapper>
                                <S.Areas>
                                    {areas?.map((area) => (
                                        <S.Area key={area.id}>
                                            <S.AreaText>{area.name}</S.AreaText>
                                            <S.AreaPrice>
                                                {area.privacy !== 'PUBLIC'
                                                    ? `${Number(area.price).toFixed(2)} €`
                                                    : 'N/A'}
                                            </S.AreaPrice>
                                            <Button
                                                id="book-now"
                                                variant="contained"
                                                disabled={!area.schedules.length || area.closedSchedules.length > 0}
                                                onClick={() => handleBookingNavigation(clubId!, area, area.price)}
                                            >
                                                {t('clubList.book')}
                                            </Button>
                                        </S.Area>
                                    ))}
                                </S.Areas>
                            </S.StyledPopup>
                        )}
                    </Marker>
                );
            })}
            <ChangeCenter center={center} />
            {onClickHandler && <ClickHandler onClickHandler={onClickHandler} />}
        </MapContainer>
    );
};
