import React from 'react';
import {useTranslation} from 'react-i18next';

import {advantages_cards} from '../../utils/constants';

import {
    AdvantagesBox,
    AdvantagesTitle,
    AdvantagesCardsContainer,
    AdvantagesCard,
    AdvantageImageContainer,
} from './Advantages.styles';

export const Advantages = () => {
    const {t} = useTranslation();

    return (
        <AdvantagesBox>
            <AdvantagesTitle>{t(`homepage.advantages.title`)}</AdvantagesTitle>
            <AdvantagesCardsContainer>
                {advantages_cards.map((advantages_card, index) => (
                    <AdvantagesCard key={`advantages-card-${index}`}>
                        <AdvantageImageContainer>
                            <img src={advantages_card.image} />
                        </AdvantageImageContainer>
                        <p>{t(`homepage.advantages.${advantages_card.text}`)}</p>

                        <span>{t(`homepage.advantages.${advantages_card.subtext}`)}</span>
                    </AdvantagesCard>
                ))}
            </AdvantagesCardsContainer>
        </AdvantagesBox>
    );
};
