import React, {FC, useMemo} from 'react';
import {LoadingContainer, StyledBox, StyledLoading, StyledMap} from './SearchMap.styles';
import useSearchContext from '../../contexts/SearchContext';
import {mapClubToMapInfo} from '../../../../shared/utils/mapClubToMapInfo';

export const SearchMap: FC = (props) => {
    const {isLoading, results} = useSearchContext();

    const center = useMemo(() => {
        if (!results?.length) {
            return {lat: 40.64217439827243, lng: -8.65346967664854};
        }
        const aggregate = results?.reduce(
            (acc, club) => ({lat: acc.lat + Number(club.address?.lat), lng: acc.lng + Number(club.address?.lng)}),
            {
                lat: 0,
                lng: 0,
            }
        );
        return {lat: aggregate?.lat / results?.length, lng: aggregate?.lng / results?.length};
    }, [results]);

    const mappedClubs = useMemo(() => (results?.length ? mapClubToMapInfo(results) : []), [results]);

    return (
        <StyledBox {...props}>
            <StyledMap center={center} mapInfos={mappedClubs} showPopUp={true} />
            {isLoading && (
                <LoadingContainer>
                    <StyledLoading />
                </LoadingContainer>
            )}
        </StyledBox>
    );
};
