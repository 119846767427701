import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import {PropTypes} from './BookingDetails.types';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
    StyledImage,
    StyledImageContainer,
    StyledBookingBox,
    StyledCard,
    StyledCardLabel,
    StyledContact,
    StyledContactBox,
    StyledNameTitle,
    StyledStack,
    StyledStackPrice,
    StyledCardContent,
    StyledLabel,
    IconBox,
    Category,
    TaxesInfo,
} from '../createBooking/Booking.styles';
import {Box} from '@mui/material';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ParkIcon from '@mui/icons-material/Park';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import LockerRoomDetails from './LockerRoomDetails';

export const BookingDetails: FC<PropTypes> = ({club, area, category, date, from, to}) => {
    const {t} = useTranslation();
    const address = club.address.address.replaceAll(' ', '+').replace(',', '');
    const navigationURL =
        club.address.lat != 0 && club.address.lng != 0
            ? `//maps.google.com/maps?&saddr=Current+Location&daddr=@${club.address.lat},${club.address.lng}`
            : `//maps.google.com/maps?&saddr=Current+Location&daddr=${address}+${club.address.district}+${club.address.postalCode}`;

    return (
        <>
            <StyledCard>
                <StyledCardContent>
                    <StyledImageContainer>
                        {club.photos?.length > 0 ? (
                            <Carousel
                                className="carousel"
                                autoPlay={false}
                                navButtonsAlwaysVisible={club.photos?.length > 1 ? true : false}
                                indicators={club.photos?.length > 1 ? true : false}
                                navButtonsProps={{className: 'carousel-nav-buttons'}}
                            >
                                {club.photos?.map((photo, i) => (
                                    <StyledImage key={i} src={photo.photoUrl} alt="" />
                                ))}
                            </Carousel>
                        ) : (
                            <StyledImage src="https://placehold.co/400" />
                        )}
                    </StyledImageContainer>
                    <StyledNameTitle>{club.name}</StyledNameTitle>
                    <StyledContact>
                        <LocationOnIcon sx={{width: '16px', height: '16px'}} className="mr-1" />
                        <a target="_blank" rel="noopener noreferrer" href={navigationURL}>
                            {club.address.address}, {club.address.postalCode}, {club.address.district}
                            <CallMadeIcon fontSize="small" className="p-2" />
                        </a>
                    </StyledContact>
                    {club.mobilePhone && (
                        <StyledContact>
                            <CallIcon sx={{width: '16px', height: '16px'}} className="mr-1" />
                            <Link to={`tel:${club.mobilePhone}`}>
                                {club.mobilePhone}
                                <CallMadeIcon fontSize="small" className="p-2" />
                            </Link>
                        </StyledContact>
                    )}

                    {club.email && (
                        <StyledContact>
                            <EmailIcon sx={{width: '16px', height: '16px'}} className="mr-1" />
                            <Link to={`mailto:${club.email}`}>
                                {club.email}
                                <CallMadeIcon fontSize="small" className="p-2" />
                            </Link>
                        </StyledContact>
                    )}
                    <StyledContactBox>
                        <IconBox>
                            <LocalParkingIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                            <StyledLabel>{t(`club.parking.${club.carParkType}`.toLowerCase())}</StyledLabel>
                        </IconBox>
                        <LockerRoomDetails lockerRoomType={club.lockerRoomType} />
                    </StyledContactBox>
                </StyledCardContent>
            </StyledCard>
            <StyledCard>
                <StyledCardContent>
                    <StyledImageContainer>
                        {area.photos?.length > 0 ? (
                            <Carousel
                                className="carousel"
                                autoPlay={false}
                                navButtonsAlwaysVisible={area.photos?.length > 1 ? true : false}
                                indicators={area.photos?.length > 1 ? true : false}
                                navButtonsProps={{className: 'carousel-nav-buttons'}}
                            >
                                {area.photos?.map((photo, i) => (
                                    <StyledImage key={i} src={photo.photoUrl} alt="" />
                                ))}
                            </Carousel>
                        ) : (
                            <StyledImage src="https://placehold.co/400" />
                        )}
                    </StyledImageContainer>
                    <StyledNameTitle>
                        {area.name} - <Category>{category.toLowerCase()}</Category>
                    </StyledNameTitle>
                    <StyledContactBox>
                        <IconBox>
                            {area.privacy === 'PRIVATE' ? (
                                <LockIcon color="disabled" sx={{width: '16px', height: '16px'}} />
                            ) : (
                                <NoEncryptionGmailerrorredIcon color="disabled" sx={{width: '16px', height: '16px'}} />
                            )}
                            <StyledContact>{area.privacy ? t(`${area.privacy}`.toLowerCase()) : '-'}</StyledContact>
                        </IconBox>

                        <IconBox>
                            {area.environment === 'INDOOR' ? (
                                <WarehouseIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                            ) : (
                                <ParkIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                            )}
                            <StyledContact>
                                {area.environment ? t(`${area.environment}`.toLowerCase()) : '-'}
                            </StyledContact>
                        </IconBox>
                    </StyledContactBox>
                    <StyledContact>{area.description}</StyledContact>
                    <StyledStack>
                        <StyledBookingBox>
                            <StyledCardLabel>{date}</StyledCardLabel>
                            <StyledContact>{from}</StyledContact>
                        </StyledBookingBox>
                        <Box>
                            <StyledCardLabel>{date}</StyledCardLabel>
                            <StyledContact>{to}</StyledContact>
                        </Box>
                    </StyledStack>
                </StyledCardContent>
            </StyledCard>
            {area.price && (
                <StyledCard>
                    <StyledCardContent>
                        <StyledCardLabel>{t('booking.label.priceSummary')}</StyledCardLabel>
                    </StyledCardContent>
                    <StyledStackPrice>
                        <StyledCardLabel>{t('booking.label.total')}</StyledCardLabel>
                        {area.privacy === 'PRIVATE' ? (
                            <Box>
                                <StyledCardLabel>&euro; {area.price}</StyledCardLabel>
                                <TaxesInfo>{t('booking.label.includedTaxes')}</TaxesInfo>
                            </Box>
                        ) : (
                            <Box>
                                <StyledCardLabel>&euro; {0}</StyledCardLabel>
                                <StyledContact>{t('booking.label.publicAreaInfo')}</StyledContact>
                            </Box>
                        )}
                    </StyledStackPrice>
                </StyledCard>
            )}
        </>
    );
};
