import {useQuery} from 'react-query';
import {AppService} from '../services/app.service';
import {useCommon} from '../context/CommonContext';
export function useAvailableSlots(clubId: string, startDate: string, endDate: string) {
    const appService = new AppService();
    const {setIsShareButtonsVisible} = useCommon();
    const {
        isLoading,
        data = [],
        isError,
    } = useQuery({
        queryKey: ['availableSlots'],
        queryFn: async () => {
            const res = await appService.api_getAvailableSlots(clubId, startDate, endDate);
            return res.data;
        },
        retry: false,
        onSuccess: () => {
            setIsShareButtonsVisible(true);
        },
        onError: () => {
            setIsShareButtonsVisible(false);
        },
    });
    return {data, isLoading, isError};
}
