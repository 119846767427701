import React from 'react';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../../../../context/CommonContext';
import {StyledAppButton, Description, ButtonBox} from './DeleteClubForm.styles';
import {useDeleteClub} from '../../../../hooks/useDeleteClub';

interface DeleteClubFormProps {
    clubId: string;
}

export const DeleteClubForm = ({clubId}: DeleteClubFormProps) => {
    const {t} = useTranslation();
    const {setModal} = useCommon();
    const {mutate} = useDeleteClub(clubId);

    const handleCloseModal = () => {
        setModal({open: false, children: <></>});
    };

    const handleDeleteClub = () => {
        mutate();
        setModal({open: false, children: <></>});
    };

    return (
        <>
            <Description>{t('club.message.deleteClubDescription')}</Description>
            <ButtonBox>
                <StyledAppButton id="cancel" variant="outlined" type="reset" onClick={handleCloseModal}>
                    {t('cancel')}
                </StyledAppButton>
                <StyledAppButton id="delete-club" variant="contained" color="error" onClick={handleDeleteClub}>
                    {t('delete')}
                </StyledAppButton>
            </ButtonBox>
        </>
    );
};
