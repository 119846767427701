import {DatePicker, DatePickerProps} from '@mui/x-date-pickers';
import styled from 'styled-components';

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<Date>>(() => ({
    backgroundColor: '#fff',
    border: 'transparent',
    borderRadius: '4px',
    boxShadow: 'var(--default-boxShadow)',

    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
    },
}));
