import {AvailableHourType} from '../types/AvailableHour.type';

export const generateHoursArray = (startingHour: string, endingHour: string): AvailableHourType[] => {
    const [startHour, startMinute] = startingHour.split(':').map(Number);
    const [endHour, endMinute] = endingHour.split(':').map(Number);

    const hours = [];

    for (let [hour, minute] = [startHour, startMinute]; hour * 60 + minute <= endHour * 60 + endMinute; minute += 60) {
        if (minute >= 60) {
            [hour, minute] = [hour + 1, minute - 60];
        }

        hours.push({
            label: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
            value: `${hour * 3600 + minute * 60}`,
        });
    }
    return hours;
};
