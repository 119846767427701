import React, {PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Club} from '../../../shared/types/Club.type';
import {HomePageContextType} from '../types/HomePageContext.types';
import {useHomePage} from '../hooks/useHomePage';

export const HomePageContext = createContext<HomePageContextType>({
    isLoading: true,
    setIsLoading: () => undefined,
    clubs: undefined,
    setClubs: () => undefined,
});

export const HomePageContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    const dataFetchedRef = useRef(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [clubs, setClubs] = useState<Partial<Club>[] | undefined>(undefined);

    const {
        actions: {fetch},
    } = useHomePage();

    const values = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            clubs,
            setClubs,
        }),
        [isLoading, setIsLoading, clubs, setClubs]
    );

    useEffect(() => {
        if (!dataFetchedRef.current) {
            dataFetchedRef.current = true;
            fetch().then((clubs) => {
                setClubs(clubs);
                setIsLoading(false);
            });
        }
    }, []);

    return <HomePageContext.Provider value={values} {...props} />;
};
const useHomePageContext = () => useContext(HomePageContext);
export default useHomePageContext;
