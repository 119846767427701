import {Box} from '@mui/material';
import styled from 'styled-components';

export const ScheduleItem = styled(Box)`
    display: flex;

    > div {
        flex: 1;
    }
`;

export const Image = styled.img``;
