{
    "welcome": "Bem-Vindo!",
    "generalError": "Aconteceu algo inesperado. Por favor, tente novamente.",
    "and": "e",
    "or": "ou",
    "error": "Erro",
    "less": "Menos",
    "more": "Mais",
    "price": "Preço",
    "back": "Retroceder",
    "iban": "IBAN",
    "bic": "BIC/SWIFT",
    "email": "E-mail",
    "continue": "Seguinte",
    "finish": "Terminar",
    "return": "Retroceder",
    "cancel": "Cancelar",
    "canceled": "Cancelada",
    "contact": "Contactar",
    "query": "Consulta",
    "stop": "Parar",
    "stopped": "Parada",
    "duplicate": "Duplicar",
    "filter": "Filtrar",
    "delete": "Apagar",
    "details": "Detalhes",
    "termsAndConditions": "Termos & Condições",
    "pageNotFound": "Oops, a página que procura não existe.",
    "goToHomepage": "Voltar para a página inicial",
    "signInSignUp": "Entrar/Registar",
    "paymentConfirmed": "Pagamento confirmado",
    "paymentNotConfirmed": "Pagamento por confirmar",
    "bookings": "Reservas",
    "noBookings": "Sem reservas",
    "cancelBooking": "Cancelar reserva",
    "cancelBookingReservation": "Cancelar reserva {{booking}}",
    "cancelBookingMessage": "Será enviado um e-mail a {{email}} a informar do cancelamento da reserva.",
    "bookingIsPaid": "Reserva {{id}} marcada como paga.",
    "bookingIsNotPaid": "Reserva {{id}} marcada como não paga.",
    "newClub": "Nova instalação desportiva",
    "selectClub": "Selecione uma instalação desportiva:",
    "area": "Área",
    "areasOfClub": "Áreas da instalação desportiva",
    "noAreas": "Sem áreas",
    "newArea": "Nova área",
    "areaIsClosed": "A área encontra-se parada. Período de encerramento: {{schedule}}",
    "areaDelete": "Apagar área",
    "areaDeleteDescription": "Se apagar esta área, compreende que irá cancelar automaticamente as reservas para a mesma. Esta ação é permanente e não pode ser anulada.",
    "scanThisQRCode": "Este QR Code permite aceder aos detalhes da reserva.",
    "missingInfo": "Informação em falta",
    "areasCount": "Número de áreas",
    "privacy": "Propriedade",
    "environment": "Ambiente",
    "not_specified": "Qualquer",
    "private": "Privado",
    "public": "Público",
    "indoor": "Interior",
    "outdoor": "Exterior",
    "clubCreated": "Instalação desportiva criada com sucesso!",
    "createAreaInfo": "Crie agora uma área para cada zona da instalação desportiva com condições/preços diferentes.",
    "createArea": "Criar Área",
    "noClubInfo": "Não existe nenhuma instalação desportiva criada. Por favor, crie uma nova instalação desportiva no botão + NOVA INSTALAÇÃO DESPORTIVA",
    "addContact": "Adicionar contacto",
    "bookingDetails": "Detalhes da Reserva",
    "sport": "Desporto",
    "day": "Dia",
    "schedule": "Horário",
    "noContactsTitle": "Sem contactos",
    "noContactsInfo": "Não existem outros contactos criados",
    "save": "Guardar",
    "close": "Fechar",
    "from": "De",
    "to": "a",
    "password": "Palavra-passe",
    "alreadyBooked": "Já está reservado neste horário",
    "clubImageDefaultTitle": "Foto padrão da instalação desportiva",
    "availableSlotsMessage": "Prepara-te para um jogo inesquecível! 🏀🏓⚽️ Reserva o teu horário e junta-te à diversão desportiva.",
    "availableSlotsDate": "📅 Horários disponíveis para: {{date}}",
    "shareAvailableSlotsMessage": "Prepara-te para um jogo inesquecível! 🏀🏓⚽️\nReserva o teu horário e junta-te à diversão desportiva.\n\n📅 Horários disponíveis para:\n{{date}}\n\n📍 Instalação desportiva:\n{{clubName}}\n\n ⛳️ Área(s):\n{{area}}\n🔗 Visita o nosso site e faz já a tua reserva:\n{{link}} ",
    "shareAvailableSlotsNoSchedulesError": "Esta área não tem horário disponível para amanhã.",
    "fileUploadInput": "Arraste e solte o arquivo aqui ou clique para selecionar",
    "fileUploadInputRules": "Apenas é permitido carregar uma imagem .jpeg ou .png até 2MB",
    "fileUploadErrorMaxFiles": "Limite de imagens ultrassado. Apenas é permitido o carregamento de uma imagem.",
    "fileUploadErrorMaxSize": "Imagem demasiado grande. Apenas é permitido o carregamento de uma imagem até 2MB.",
    "cancelBookingAlertMessage": {
        "200": "Reserva cancelada com sucesso.",
        "403": "Não tem permissões para cancelar a reserva.",
        "404": "Não existe nenhuma reserva com o múmero indicado.",
        "500": "Ocorreu um erro durante o cancelamento da reserva, por favor tente novamente.",
        "noData": "Ocorreu um erro inesperado, por favor tente novamente."
    },
    "newBooking": "Nova Reserva",
    "checkout": {
        "message": "Bem-vindo de volta! Por favor, faça o login para prosseguir com a reserva"
    },
    "validation": {
        "requiredPassword": "Palavra-passe é obrigatória.",
        "requiredName": "O campo nome é de preenchimento obrigatório.",
        "requiredClubName": "O campo nome da instalação desportiva é de preenchimento obrigatório.",
        "requiredValidEmail": "Por favor, introduza um e-mail válido.",
        "requiredEmail": "O campo e-mail é de preenchimento obrigatório.",
        "requiredPhone": "O campo telefone é de preenchimento obrigatório.",
        "requiredValidPhone": "Por favor, introduza um número de telefone válido.",
        "oldPassword": "Palavra-passe antiga é de preenchimento obrigatório.",
        "newPassword": "Palavra-passe nova é de preenchimento obrigatório.",
        "confirmPassword": "A password deve coincidir.",
        "toShort": "Este campo deve ter no mínimo {{char}} carateres.",
        "toLong": "Este campo deve ter no máximo {{char}} carateres.",
        "fiscalNumberOnlyNumber": "Campo NIF deve conter apenas dígitos.",
        "fiscalNumberMinNumber": "Campo NIF deve ter no mínimo 9 dígitos.",
        "fiscalNumberMaxNumber": "Campo NIF deve ter no máximo 9 dígitos.",
        "phoneOnlyNumber": "Campo número de telemóvel deve conter apenas dígitos.",
        "invalidPrivacy": "Propriedade selecionada é inválida.",
        "invalidEnvironment": "Ambiente selecionado é inválido.",
        "invalidTimeUnit": "Unidade de tempo selecionada é inválida.",
        "requiredField": "Este campo é obrigatório.",
        "requiredPaymentMethod": "Deve selecionar o método de pagamento.",
        "requiredTermsAndConditions": "Antes de avançar deve aceitar os termos e condições.",
        "requiredMinimumField": "Deve selecionar pelo menos uma opção.",
        "dateTo": "O horário de fecho deverá ser superior ao horário de abertura.",
        "minEndDate": "Data de fim deverá ser superior à data de início.",
        "invalidPassword": "Palavra-passe não é válida.",
        "requiredValidIban": "IBAN inválido. Remova espaços e digite PT seguido de 23 dígitos.",
        "requiredValidPostalCode": "Código postal inválido. Digite um código postal no formato 0000-000.",
        "invalidSSO": "Verifique se o e-mail é utilizado noutra conta."
    },
    "login": {
        "forgotPassword": "Recuperação da Palavra-Passe",
        "login": "Login",
        "loginWithGoogle": "Login com Google",
        "loginWithFacebook": "Login com Facebook",
        "register": "Ainda não registado? Criar conta",
        "clubAdminLogin": "Admin Login",
        "adminLogin": "Suporte Login",
        "okButton": "OK",
        "error": {
            "wrongEmailOrPassword": "E-mail ou palavra-passe incorretos, tente novamente."
        }
    },
    "verify": {
        "verificationCode": "CÓDIGO DE VERIFICAÇÃO",
        "errorMessage": "E-mail não verificado! Tente novamente!",
        "successMessage": "A sua conta foi verificada!",
        "okButton": "OK",
        "error": {
            "notVerified": "E-mail não-verificado! Tente novamente!"
        }
    },
    "paymentProof": {
        "message": {
            "failedUpload": "O carregamento do comprovativo de pagamento falhou. Por favor, tente novamente.",
            "successUpload": "Comprovativo carregado com sucesso."
        }
    },
    "clubAdmin": {
        "requestAccount": {
            "title": "Pedido de criação de conta",
            "adminName": "Nome do gestor da instalação desportiva",
            "clubName": "Nome da instalação desportiva",
            "email": "E-mail",
            "phone": "Número de telefone",
            "observations": "Observações",
            "success": "Submissão efetuada com sucesso.",
            "submitButton": "Submeter",
            "error": {
                "onSubmit": "Erro ao submeter formulário! Tente novamente, por favor!"
            }
        },
        "recoverPassword": {
            "title": "Alterar palavra-passe",
            "verificationCode": "Código de verificação",
            "verificationCodeLabel": "Introduzir o código de verificação",
            "newPassword": "Palavra-passe nova",
            "newPasswordLabel": "Introduzir a nova palavra-passe",
            "confirmNewPasswordLabel": "Confirme a nova palavra-passe",
            "success": "Palavra-passe alterada com sucesso.",
            "emailSent": "Foi enviado um e-mail com o código de verificação para redefinição da palavra-passe.",
            "submitButton": "Alterar palavra-passe",
            "error": {
                "passwordNotValid": "A sua palavra-passe deve ter pelo menos 8 caracteres, dos quais pelo menos uma letra maiúscula e uma minúscula, um número e um caracter especial.",
                "passwordNotMatching": "A password fornecida nos dois campos é diferente! Por favor, tente novamente!",
                "onSubmit": "Erro ao submeter formulário! Tente novamente, por favor!",
                "emailNotSent": "Não foi possível enviar o e-mail de redefinição da palavra-passe. Tente novamente."
            }
        },
        "menu": {
            "home": "Home",
            "noClubs": "Sem instalações desportivas",
            "createClub": "Criar instalação desportiva",
            "selectedClub": "Instalação desportiva selecionada",
            "clubManagement": "Gestão de Instalações desportivas",
            "clubAreas": "Áreas",
            "reviews": "Avaliações",
            "temporaryStop": "Parar temporariamente",
            "editAccount": "Editar conta",
            "help": "Ajuda",
            "logout": "Sair",
            "deleteClub": "Apagar instalação desportiva",
            "historyData": "Histórico de Ações",
            "editClub": "Editar instalação desportiva"
        },
        "bookings": {
            "allClubs": "Selecionar instalação desportiva",
            "bookings": "RESERVAS",
            "noBookings": "Sem reservas",
            "noBookingsResult": "Não há reservas efetuadas com os critérios de pesquisa selecionados.",
            "newBooking": "Nova Reserva",
            "allAreas": "Todas as áreas",
            "calendarButton": "Calendário",
            "listButton": "Lista",
            "selectCategory": "Selecionar desportos",
            "selectDate": "Selecionar data",
            "selectStartHour": "Selecionar hora de ínicio",
            "timeUnit": "Selecionar unidade de tempo ",
            "TRINTA_MINUTOS": "30 Minutos",
            "UMA_HORA": "1 Hora",
            "UMA_HORA_TRINTA_MINUTOS": "1 Hora and 30 Minutos",
            "DUAS_HORA": "2 Horas",
            "selectArea": "Selecionar área",
            "selectDay": "Dia",
            "selectTime": "Hora",
            "showFilterButton": "Filtros",
            "selectReservationType": "Selecionar categoria da reserva",
            "allReservationType": "Todas as categorias",
            "reservationTypes": {
                "selectReservationType": "Selecione o tipo da reserva",
                "private": "Privado",
                "classes": "Aulas",
                "classes_pack": "Conjunto de aulas",
                "team_building": "Team building",
                "tournament": "Torneio"
            },
            "payment": "Comprovativo de pagamento",
            "inputLabel": {
                "search": "ID de reserva, nome ou contacto telefónico",
                "clientName": "Nome do cliente",
                "clientEmail": "E-mail do cliente",
                "selectedDay": "Dia",
                "selectedTime": "Hora",
                "selectedSport": "Desporto",
                "reservationType": "Tipo"
            },
            "inputPlaceholder": {
                "clientName": "Nome do cliente",
                "clientEmail": "E-mail do cliente"
            },
            "success": {
                "title": "{{area}} reservado",
                "201": "O {{area}} foi reservado em nome de {{clientName}}, para o dia {{day}}, das {{from}}-{{to}} horas. A confirmação foi enviada para {{email}}."
            },
            "error": {
                "noSelectedClubTitle": "Selecione instalação desportiva",
                "noSelectedClub": "Deve selecionar uma instalação desportiva para poder prosseguir com a reserva.",
                "scheduleError": "Não é possível efetuar reservas fora do horário definido no campo selecionado.",
                "pastDate": "Não é possível fazer reservas em horários anteriores há data e hora atual.",
                "notAreaSelected": "Para criar uma reserva, por favor selecione uma área nos filtros.",
                "invalidInterval": "Horário não permitido. Por favor, selecione intervalos de {{time}}.",
                "overlappingEvents": "Já existe uma reserva válida no periodo selecionado.",
                "noData": "Erro. Ocorreu um erro durante a filtragem de áreas, por favor tente novamente.",
                "400": "Não foi possível processar a sua reserva. O campo não se encontra aberto no horário selecionado.",
                "403": "Não tem permissões para finalizar o pedido",
                "409": "Já existe uma reserva no período de tempo selecionado.",
                "500": "Erro. Ocorreu um erro durante a reserva, por favor tente de novo."
            },
            "modal": {
                "title": "Detalhes da reserva",
                "clientName": "Nome do cliente",
                "clientEmail": "E-mail do cliente",
                "mobilePhone": "Contacto telefónico do cliente",
                "day": "Dia",
                "time": "Hora",
                "cancelButton": "Cancelar reserva",
                "reservationTypeUpdateSuccess": "Tipo da reserva atualizada com sucesso.",
                "reservationTypeUpdateError": "Não foi possível atualizar o tipo da reserva, por favor tente novamente."
            }
        },
        "newCalendarBookingModal": {
            "title": "Nova reserva",
            "description": "Criar nova reserva das {{start}} às {{end}}?",
            "bookButton": "Reservar"
        },
        "clubAreas": {
            "error": {
                "serverError": "Não foi possível completar esta operação. Por favor, tente mais tarde.",
                "failedUpload": "O carregamento de fotos falhou. Por favor, tente novamente.",
                "failedDelete": "Não foi possível apagar a foto selecionada. Por favor, tente novamente."
            },
            "success": {
                "defaultClubImage": "Definida com sucesso a imagem padrão da instalação desportiva."
            }
        },
        "historyData": {
            "selectUsers": "Selecionar utilizador",
            "selectEndDate": "Selecionar data fim",
            "selectStartDate": "Selecionar data início",
            "createdBy": "Criado por",
            "at": "a",
            "canceledBy": "Cancelado por",
            "paidBy": "Pago por",
            "noHistory": "Sem reservas para este filtro.",
            "selectFilterToShowResults": "Aplique os filtros pretendidos e clique no botão FILTRAR para ver os resultados.",
            "accordionTitle": "Histórico de ações"
        }
    },
    "editAccount": {
        "username": "Nome de utilizador",
        "phoneNumber": "Número de telemóvel",
        "fiscalNumber": "NIF",
        "address": "Morada",
        "success": {
            "changePassword": "Password atualizada com sucesso.",
            "userUpdate": "Informação atualizada com sucesso.",
            "delete": "Conta cancelada com sucesso."
        },
        "error": {
            "forbiden": "Não tem permissões para alterar a palavra-passe.",
            "serverError": "Não foi possível completar esta operação. Por favor, tente mais tarde.",
            "userUpdate": "Não foi possível atualizar a sua informação de conta. Por favor, tente mais tarde."
        },
        "emailNote": {
            "noLink": "Para alterar o seu e-mail, entre em ",
            "link": "contacto com a equipa de suporte."
        },
        "button": {
            "changePassword": "Alterar Palavra-Passe",
            "deleteAccount": "Apagar conta",
            "cancel": "Cancelar"
        },
        "modal": {
            "delete": {
                "title": "Apagar conta",
                "description": "Se apagar a sua conta Freecourts, compreende que irá perder acesso a todas as reservas futuras e passadas e todos os dados inseridos. Esta ação é permanente e não pode ser anulada.",
                "helperText": "Para confirmar a eliminação da conta",
                "error": {
                    "wrongPassword": "Password incorreta! Tente novamente!"
                }
            },
            "change": {
                "title": "Alterar palavra-passe",
                "label": {
                    "oldPassword": "Introduza a palavra-passe antiga.",
                    "newPassword": "Introduza uma palavra-passe nova.",
                    "confirmPassword": "Confirme a palavra-passe nova."
                },
                "inputLabel": {
                    "newPassword": "Palavra-passe nova",
                    "oldPassword": "Palavra-passe antiga",
                    "confirmPassword": "Palavra-passe nova"
                }
            }
        }
    },
    "searchArea": "Procurar Área",
    "searchAvailableArea": "Procurar Área Livre",
    "noDataFound": "Sem registos",
    "requiredFields": "Introuza todos os campos obrigatóros",
    "book": "Reservar",
    "booking": {
        "label": {
            "clubInfo": "Informação da instalação desportiva",
            "bookingInfo": "Informação da reserva",
            "interested": "Interessados",
            "name": "Nome",
            "payment": "Transferência bancária",
            "acceptance": "Aceito os",
            "termsAndConditions": "Termos & Condições",
            "sportsFacility": "desta instalação desportiva.",
            "priceSummary": "Resumo de preço",
            "details": "Insira os seus dados",
            "total": "Total",
            "includedTaxes": "Inclui impostos e taxas",
            "publicAreaInfo": "Este campo é público, poderá estar ocupado.",
            "transferObservation": "Mencione nas observações da transferência bancária o número: ",
            "paymentNote": "IMPORTANTE: O prazo para pagamento e envio do comprovativo por e-mail é de 24 horas, após esse período a reserva será anulada. A confirmação pode ocorrer até 2 dias.",
            "importantNote": "IMPORTANTE: Este método de pagamento requer o pagamento e o envio do comprovativo da transferência bancária por e-mail até 24 horas após agendar a reserva.",
            "bookingSuccess": "Processo de reserva iniciado com sucesso.",
            "reservationNote": "Caso a reserva ainda não tenha sido confirmada pela instalação desportiva, poderá ser necessário apresentar o comprovativo da transferência no local.",
            "paymentConfirmed": "Pagamento da reserva confirmado.",
            "paymentMethods": "Métodos de Pagamento",
            "creditCardNote": "Pagamentos com cartão de crédito ou débito: para maior segurança, o sistema de autenticação 3D-Secure foi reforçado. Confirme a sua compra no seu telemóvel, via app do seu banco, ou introduza o código enviado via SMS na página de autenticação. Para mais informações consulte o seu banco."
        },
        "placeholder": {
            "name": "Inserir o nome",
            "email": "Inserir e-mail"
        },
        "lockerRoomType": {
            "both": "Balneários masculinos e femininos",
            "male": "Balneários masculinos",
            "female": "Balneários femininos",
            "mixed": "Balneários mistos",
            "noLockerRoom": "Sem balneários"
        },
        "button": {
            "book": "Reservar",
            "shareBooking": "Partilhar reserva",
            "next": "Reservar",
            "share": "Partilhar"
        },
        "details": "Resumo da reserva #{{bookingId}}",
        "payment": "Pagamento",
        "paymentDetails": "Detalhes do pagamento",
        "userInfo": "Informação da Reserva",
        "nameLabel": "Nome",
        "bookings": "Ver Reservas",
        "shareAvailableSlots": "Partilhe os horários disponíveis"
    },
    "timeUnit": "Unidade de reserva",
    "priceUnit": "Preço por unidade",
    "bookArea": "Reservar Área",
    "clubDetails": {
        "contacts": {
            "noContactsTitle": "Sem contactos",
            "noContactsInfo": "Não existem outros contactos criados",
            "contactList": "Lista de contactos",
            "message": {
                "success": "Contacto criado com sucesso",
                "deleteSuccess": "Contacto apagado com sucesso",
                "updateSuccess": "Contacto atualizado com sucesso",
                "error": "Erro! Não foi possível criar o contacto. Por favor, tente mais tarde."
            }
        },
        "step": {
            "label": {
                "clubData": "Dados da instalação desportiva",
                "clubPhotos": "Fotos da instalação desportiva",
                "paymentMethods": "Métodos de pagamento",
                "infrastructures": "Instalações desportivas",
                "schedules": "Horários",
                "clubContacts": "Outros contactos",
                "clubAddress": "Morada da instalação desportiva"
            },
            "inputLabel": {
                "clubName": "Nome da instalação desportiva",
                "offersAndNews": "Quero receber ofertas exclusivas e notícias da comunidade FreeCourts.",
                "clubPhone": "Telefone",
                "clubMobile": "Telemóvel",
                "clubAddress": "Morada",
                "clubDescription": "Descrição da instalação desportiva",
                "clubPhotos": "Fotos da instalação desportiva",
                "paymentMethods": "Selecione todos os métodos de pagamento suportados",
                "contactName": "Nome",
                "organizations": "Organizações",
                "address": "Morada",
                "postalCode": "Código postal",
                "district": "Distrito",
                "county": "Concelho",
                "lat": "Latitude",
                "lng": "Longitude"
            },
            "inputPlaceholder": {
                "clubName": "Nome da instalação desportiva",
                "clubEmail": "E-mail da instalação desportiva",
                "clubPhone": "Contacto telefónico",
                "clubMobile": "Contacto telefónico alternativo",
                "clubAddress": "Morada",
                "clubDescription": "Equipamentos disponíveis e indicações se são pagos ou gratuitos.",
                "clubPhotos": "Carregar fotos",
                "contactName": "Nome",
                "address": "Rua, avenida, lugar, estrada",
                "postalCode": "Código Postal",
                "lat": "Latitude",
                "lng": "Longitude"
            }
        },
        "message": {
            "getOrganizationsError": "Ocorreu um erro ao carregar a lista de organizações.",
            "createOrganizationSuccess": "Lista de organizações criada com sucesso",
            "createOrganizationError": "Ocorreu um erro quando tentava atualizar a lista de organizações.",
            "updateOrganizationSuccess": "Lista de organizações atualizada com sucesso",
            "defaultImage": "Depois de carregar as fotos da instalação desportiva, poderá escolher a imagem padrão ao clicar na imagem pretendida."
        }
    },
    "submitSuccess": "Submissão efetuada com sucesso",
    "search": {
        "labels": {
            "sport": "Desporto",
            "date": "Data",
            "startDate": "Data inicial",
            "endDate": "Data final",
            "time": "Hora de início",
            "timeUnit": "Duração",
            "district": "Localização",
            "clubName": "Instalação desportiva",
            "selectInfrastructure": "Instalações desportivas",
            "noInfrastructures": "Não existem opções"
        },
        "fields": {
            "sport": "Desporto",
            "date": "Data",
            "time": "Hora de início",
            "endTime": "Hora de fim",
            "timeUnit": "Duração",
            "district": "Localização",
            "clubName": "Nome da instalação desportiva"
        },
        "sports": {
            "any": "Todos",
            "padel": "Padel",
            "tenis": "Ténis",
            "basquetebol": "Basquetebol",
            "andebol": "Andebol",
            "futebol": "Futebol",
            "fut7": "Fut7",
            "futsal": "Futsal",
            "squash": "Squash",
            "hoquei_patins": "Hóquei em Patins",
            "fut5": "Fut5",
            "voleibol": "Voleibol"
        },
        "cta": "Procurar",
        "errors": {
            "isRequired": "O campo {{field}} é obrigatório"
        }
    },
    "clubList": {
        "book": "Reservar",
        "emptyList": "Não foram encontradas instalações desportivas que correspondam aos critérios de pesquisa atuais",
        "club": {
            "club_unavailable": "Instalação desportiva indisponível",
            "schedule_error": "Data escolhida é fora do horario de funcionamento da instalação desportiva",
            "closed_schedule_error": "Instalação desportiva temporariamente fechada de {{from}} a {{to}}"
        },
        "area": {
            "club_unavailable": "Campo indisponível",
            "schedule_error": "Data escolhida é fora do horario de funcionamento do campo",
            "closed_schedule_error": "Campo temporariamente fechado de {{from}} a {{to}}"
        }
    },
    "club": {
        "tabs": {
            "description": "Descrição",
            "areas": "Areas"
        },
        "headers": {
            "description": "Descrição",
            "paymentMethods": "Métodos de Pagamento",
            "parking": "Estacionamento",
            "lockerooms": "Balneários",
            "schedules": "Horários",
            "location": "Localização",
            "closed": "Datas de Fecho",
            "sports": "Desportos",
            "reservations": "Reservas",
            "priceByTimeUnit": "Preço / Intervalo de tempo",
            "selectAllSportsAvailable": "Selecione todos os desportos disponíveis na instalação desportiva",
            "whiciSportsOffer": "Quais os desportos que oferecem?",
            "clubGallery": "Galeria de imagens"
        },
        "paymentMethods": {
            "emptyList": "Não há métodos de pagamento disponíveis",
            "cash": "Dinheiro",
            "mbway": "MB Way",
            "credit_card": "Cartão de débito/crédito",
            "bank_transfer": "Transferência bancária"
        },
        "lockerooms": {
            "noInformation": "Não há informação relativa a balneários",
            "no_locker_room": "Não existem balneários",
            "women_locker_room": "Só há balneário feminino",
            "men_locker_room": "Só há balneário masculino",
            "mixed_locker_room": "Balneários mistos",
            "both_locker_room": "Balneários masculinos e femininos"
        },
        "parking": {
            "noInformation": "Não há informação relativa a estacionamentos",
            "none": "Não existem estacionamentos",
            "free": "Estacionamento grátis",
            "paid": "Estacionamento pago"
        },
        "schedules": {
            "noInformation": "Não há informação relativa a horários",
            "weekdays": {
                "monday": "Segunda-feira",
                "tuesday": "Terça-feira",
                "wednesday": "Quarta-feira",
                "thursday": "Quinta-feira",
                "friday": "Sexta-feira",
                "saturday": "Sábado",
                "sunday": "Domingo"
            }
        },
        "gallery": {
            "emptyList": "Não há imagens da instalação desportiva para mostrar"
        },
        "closed": {
            "noInformation": "Não há indicações de fecho."
        },
        "reservations": {
            "noInformation": "Não há reservas feitas",
            "success": "Reserva efetuada com sucesso.",
            "error": "Ocorreu um erro enquanto tentava efetuar uma reserva. Por favor, verifique se tem horários criados nesta área e se a reserva desejada se encontra dentro do horário definido."
        },
        "areas": {
            "noAreas": "Não há áreas disponíveis para esta instalação desportiva",
            "noCategories": "Não há desportos disponíveis nesta área"
        },
        "message": {
            "deleteClubTitle": "Apagar {{club}}",
            "deleteClubDescription": "Se apagar esta instalação desportiva, compreende que irá cancelar automaticamente as reservas para o mesmo. Esta ação é permanente e não pode ser anulada."
        }
    },
    "clubAreas": {
        "step": {
            "label": {
                "areaData": "Dados da Área",
                "areaSports": "Oferta de Desportos",
                "areaSportsOffer": "Qual a oferta de desportos?",
                "areaSportsSelect": "Selecione todos os desportos disponíveis nesta área",
                "areaPhotos": "Fotos da Área",
                "areaSchedules": "Horários",
                "repeatSchedule": "Repetir horário",
                "activityPeriod": "Período de Atividade",
                "closedPeriod": "Período de Encerramento"
            },
            "inputLabel": {
                "areaName": "Nome da Área",
                "areaDescription": "Descrição da área",
                "areaPhotos": "Fotos da Área",
                "areaSchedules": "Defina horário de abertura e de fecho",
                "areaClosedSchedules": "Defina data de início e fim de período de encerramento"
            },
            "inputPlaceholder": {
                "areaName": "Nome da área",
                "areaDescription": "Equipamentos disponíveis, tipo de pavimento, etc...",
                "areaPhotos": "Carregar fotos"
            }
        },
        "message": {
            "noSportInfoTitle": "Sem desporto",
            "noSportInfoDescription": "Desporto selecionado não existe neste campo.",
            "areaScheduleSuccess": "Operação efetuada com sucesso.",
            "areaScheduleError": "Ocorreu um erro. Por favor, tente novamente.",
            "schedules": {
                "409": "Já existe um horário definido para a hora de abertura e fecho selecionado."
            },
            "closedSchedules": {
                "409": "Já existe um período de encerramento definido para as datas de início e de fim selecionadas."
            }
        },
        "allWeekdays": "Todos os dias",
        "timeUnit": "Unidade de tempo",
        "newSchedule": "Novo Horário",
        "newClosedSchedule": "Novo Fecho",
        "repeat": "Dia",
        "startDate": "Data de Início",
        "endDate": "Data de Fim",
        "open": "Abertura",
        "close": "Fecho",
        "schedules": {
            "noSchedulesTitle": "Sem horários",
            "noSchedulesInfo": "Não existem horários de abertura criados para esta área.",
            "noClosedSchedulesTitle": "Sem períodos de encerramento.",
            "noClosedSchedulesInfo": "Não existem períodos de encerramento criados para esta área."
        },
        "address": {
            "selectCoordinatesTitle": "Selecionar coordenadas",
            "selectCoordinatesInfo": "Selecione no mapa a localização da instalação desportiva para recolher as coordenadas."
        }
    },
    "register": {
        "title": "CRIE A SUA CONTA",
        "name": {
            "label": "Nome",
            "required": "Nome é obrigatório."
        },
        "email": {
            "label": "E-mail",
            "invalid": "O e-mail é inválido.",
            "required": "E-mail é obrigatório."
        },
        "password": {
            "invalid": "A sua palavra-passe deve ter pelo menos 8 caracteres, dos quais pelo menos uma letra maiúscula e uma minúscula, um número e um caracter especial.",
            "required": "Palavra-passe é obrigatório."
        },
        "phone": {
            "label": "Número de telemóvel",
            "invalid": "O número é inválido."
        },
        "nif": {
            "label": "NIF",
            "invalid": "O NIF é inválido."
        },
        "address": {
            "label": "Morada",
            "invalid": "A morada é inválida."
        },
        "submitButton": "Criar conta",
        "success": "Conta criada com sucesso.",
        "error": {
            "onSubmit": "Falha na criação de conta! Tente novamente!",
            "409": "O utilizador {{email}}"
        }
    },
    "recoverPassword": {
        "title": "Alterar palavra-passe",
        "verificationCode": "Código de verificação",
        "newPassword": "Palavra-passe nova",
        "success": "Palavra-passe alterada com sucesso.",
        "emailSent": "Foi enviado um e-mail com o código de verificação para redefinição da palavra-passe.",
        "submitButton": "Alterar palavra-passe",
        "labels": {
            "verificationCode": "Introduzir o código de verificação",
            "newPassword": "Introduzir a nova palavra-passe",
            "confirmNewPassword": "Confirme a nova palavra-passe"
        },
        "error": {
            "passwordNotValid": "A sua palavra-passe deve ter pelo menos 8 caracteres, dos quais pelo menos uma letra maiúscula e uma minúscula, um número e um caracter especial.",
            "passwordNotMatching": "A password fornecida nos dois campos é diferente! Por favor, tente novamente!",
            "onSubmit": "Erro ao submeter formulário! Tente novamente, por favor!",
            "emailNotSent": "Não foi possível enviar o e-mail de redefinição da palavra-passe. Tente novamente."
        }
    },
    "playerMenu": {
        "home": "Home",
        "editAccount": "Editar conta",
        "help": "Ajuda",
        "logout": "Sair"
    },
    "shareBooking": {
        "bookingOf": "Reserva de {{name}}",
        "shareLink": "Partilhar link da reserva",
        "imInterested": "Estou interessado",
        "imNotInterested": "Não estou interessado",
        "interestedString": {
            "0": "Sê o primeiro a mostrar interesse.",
            "1a": "{{name}} estás interessado.",
            "1b": "{{name}} está interessado.",
            "2": "{{name1}} e {{name2}} estão interessados.",
            "3": "{{name1}}, {{name2}} e mais {{number}} estão interessados."
        },
        "shareTitle": "Partilhe a reserva",
        "copyLink": "Copiar link",
        "copied": "Copiado",
        "message": "⚽️ Acabei de fazer uma reserva na FreeCourts!\n\n📅 No dia {{date}} vamos jogar {{category}} das {{startTime}} as {{endTime}}.\n\n📍 Local:\n- {{clubName}}, {{areaName}}\n- {{clubAddress}}, {{clubPostalCode}} {{clubCounty}}\n\n👩‍👨‍👦‍👧 Quem ja confirmou: {{names}}\n\n🔗 Link para confirmarem a vossa presença e ver mais detalhes:\n{{url}}",
        "shareMessage": "Partilhar mensagem",
        "shareOnWhatsapp": "Partilhar no Whatsapp",
        "shareOnTwitter": "Partilhar no Twitter",
        "shareOnFacebook": "Partilhar no Facebook"
    },
    "firstLogin": {
        "title": "Alterar palavra-passe",
        "description": "Por razões de segurança deve alterar a sua palavra-passe.",
        "newPassword": "Palavra-passe nova",
        "newPasswordLabel": "Introduzir a nova palavra-passe",
        "confirmNewPasswordLabel": "Confirme a nova palavra-passe",
        "success": "Palavra-passe alterada com sucesso.",
        "submitButton": "Alterar palavra-passe",
        "error": {
            "passwordNotValid": "A sua palavra-passe deve ter pelo menos 8 caracteres, dos quais pelo menos uma letra maiúscula e uma minúscula, um número e um caracter especial.",
            "passwordNotMatching": "A password fornecida nos dois campos é diferente! Por favor, tente novamente!",
            "onSubmit": "Erro ao submeter formulário! Tente novamente, por favor!"
        }
    },
    "genericErrors": {
        "fileSizeExceed": "O arquivo selecionado excede o tamanho de 10mb",
        "maxNumberExceed": "Excedeu o limite de {{number}} imagens permitidas para upload."
    },
    "homepage": {
        "title": "Um campo para todos",
        "steps": {
            "title": "Tão simples como 1,2,3",
            "search": "Encontra o sitio",
            "book": "Reserva o espaço",
            "share": "Convida os teus amigos"
        },
        "statistics": {
            "title": "Locais",
            "text": "O concelho de Aveiro dispõe de pavilhões desportivos, espaços de acesso público que permitem praticar desportos desde Basquetebol, Futebol, Futsal, Voleibol, Andebol entre outros. Encontra um espaço que te encha as medidas e vem praticar desporto",
            "locations": "Locais disponíveis",
            "sports": "Diferentes desportos",
            "fun": "Diversão infinita"
        },
        "book": {
            "title": "Pronto para começar o jogo?",
            "bookNow": "Reserve já"
        },
        "advantages": {
            "title": "Vantagens",
            "first_text": "A actividade fisica é boa para o coração, o corpo e a mente. Quando feita regularmente ajuda a reduzir a incidencia de doenças cardiacas e ajuda com sintomas de depressão e ansiedade.",
            "first_subtext": "Directrizes da OMS via fundacaodesporto.pt",
            "second_text": "A alternância entre desportos considerados moderados e de alta intensidade ajuda  uma potenciação maior dos beneficios do desporto. Havendo a necessidade de praticar diferentes tipos de desportos.",
            "second_subtext": "Directrizes da OMS via fundacaodesporto.pt",
            "third_text": "O nivel de responsabilidade pessoal e social, de um cidadão, aumenta quando existe uma atitude positiva na prática competitiva de desportos de grupo",
            "third_subtext": "Dissertação Doutoramento da Ul-FMH"
        },
        "footer": {
            "courts": "Instalações desportivas",
            "sports": "Desportos",
            "tennis": "Ténis",
            "basketball": "Basquetebol",
            "soccer": "Futebol",
            "volleyball": "Voleibol",
            "padel": "Padel",
            "futsal": "Futsal",
            "help": "Ajuda"
        }
    }
}
