import React, {FC} from 'react';
import {StyledBackgroundImage} from './BackgroundImage.styles';

export type BackgroundImageProps = {
    src: string;
    alt?: string;
    rest?: any;
};

export const BackgroundImage: FC<BackgroundImageProps> = ({src, alt = 'Background Image', ...rest}) => {
    return <StyledBackgroundImage src={src} alt={alt} {...rest} />;
};
