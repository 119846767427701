import {Accordion, Box, DialogTitle} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../Common/Buttons/AppButton';

export const StyledAccordion = styled(Accordion)`
    background-color: #fafafa !important;
    box-shadow: none !important;

    &::before {
        background-color: #fff !important;
    }
`;

export const StyledDialogContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: end;
`;
