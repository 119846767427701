import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AppButton} from '../components/Common/Buttons/AppButton';
import {Layout} from '../components/Common/Layout/Layout';

const NotFound = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Layout headerType="menu">
            <Stack spacing={4} mt={4}>
                <Typography variant={'h1'} textAlign={'center'}>
                    404
                </Typography>
                <Typography variant={'h5'} textAlign={'center'}>
                    {t('pageNotFound')}
                </Typography>
                <AppButton variant="contained" onClick={() => navigate('/')}>
                    {t('goToHomepage')}
                </AppButton>
            </Stack>
        </Layout>
    );
};

export default NotFound;
