import React, {FC, useState, ReactNode, createContext, useContext} from 'react';
import {User} from '../../../models/user.model';

type FeatureToggle = {
    createdAt: string;
    id: string;
    name: string;
};
export type UserContextType = {
    user: User | null;
    setUser: (newUser: User | null) => void;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
};

export const UserContext = createContext<UserContextType>({
    user: null,
    setUser: () => {},
    isLoading: true,
    setIsLoading: () => {},
});

export type UserContextProviderProps = {
    children?: ReactNode;
};

export const UserContextProvider: FC<UserContextProviderProps> = (props) => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const values = {
        user,
        setUser,
        isLoading,
        setIsLoading,
    };

    return <UserContext.Provider value={values}>{props.children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
