import React, {useState} from 'react';
import {Box, Chip, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {BookingCardStyled, TertiaryButton, StyledAppButton} from './BookingCard.styles';
import {ShareLinkModal} from '../ShareLinkModal';
import {SportIcon} from '../SportIcon/SportIcon';
import {Category} from '../../shared/types/Category.type';
import {BookingCardProps} from './types/BookingCard.types';

dayjs.extend(utc);

const BookingCard = ({booking, onClick, onCancel, onContact}: BookingCardProps) => {
    const {t, i18n} = useTranslation();

    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

    const readableDate = dayjs(booking.day).locale(i18n.language).utc().format('ddd, DD MMMM YYYY');
    const isDeleted = booking.canceled;

    const handleCancelClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onCancel();
    };

    const handleCardClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick();
    };

    const handleShareClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShareModalOpen(true);
    };

    const handleContactClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onContact();
    };

    return (
        <BookingCardStyled>
            <Box sx={{opacity: isDeleted ? '0.7' : undefined}} onClick={handleCardClick}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">#{booking.shareId}</Typography>
                    <SportIcon category={booking.category as Category} />
                </Stack>
                <Typography variant="overline">
                    {booking.club.name} - {booking.name} - {t(`search.sports.${booking.category.toLocaleLowerCase()}`)}
                </Typography>
                <Stack direction="row" alignItems="center" flexWrap="wrap" useFlexGap spacing={1}>
                    <Typography variant="caption">{readableDate}</Typography>
                    <Chip
                        color="tealLighten5"
                        size="small"
                        label={`${dayjs(booking.from).utc().format('HH:mm')} - ${dayjs(booking.to)
                            .utc()
                            .format('HH:mm')}`}
                    />
                    {booking.isPayed ? (
                        <Chip color="success" size="small" label={t('paymentConfirmed')} />
                    ) : (
                        <Chip size="small" label={t('paymentNotConfirmed')} />
                    )}
                </Stack>
            </Box>
            <Box>
                {!isDeleted && (
                    <Stack>
                        <TertiaryButton onClick={handleShareClick}>{t('shareBooking.shareLink')} </TertiaryButton>
                        <ShareLinkModal
                            booking={booking}
                            open={shareModalOpen}
                            onClose={() => setShareModalOpen(false)}
                        />
                    </Stack>
                )}
            </Box>
            <Stack direction="row" spacing={2} mt={2}>
                {isDeleted ? (
                    <StyledAppButton fullWidth variant="contained" disabled>
                        {t('canceled')}
                    </StyledAppButton>
                ) : (
                    <StyledAppButton fullWidth variant="outlined" size="small" onClick={handleCancelClick}>
                        {t('cancel')}
                    </StyledAppButton>
                )}
                <StyledAppButton
                    fullWidth
                    variant="contained"
                    size="small"
                    color="darkGrey"
                    onClick={handleContactClick}
                >
                    {t('contact')}
                </StyledAppButton>
            </Stack>
        </BookingCardStyled>
    );
};

export default BookingCard;
