import {useQuery} from 'react-query';
import {AppService} from '../services/app.service';

export function useReservationTypes() {
    const appService = new AppService();
    const {isLoading, data = []} = useQuery({
        queryKey: ['reservationTypes'],
        queryFn: async () => {
            const res = await appService.api_getReservationTypes('private');
            return res.data;
        },
        retry: false,
    });

    return {data, isLoading};
}
