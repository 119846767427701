import React from 'react';
import {useTranslation} from 'react-i18next';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Person from '../../../../assets/icons/person.svg';
import {AreaCategory} from '../../../../shared/types/Category.type';
import {SportIcon} from '../../../../components/SportIcon/SportIcon';

export const Sports = ({
    AreaCategory: sports,
    variant,
    id: areaId,
}: {
    AreaCategory?: AreaCategory[];
    variant?: string;
    id?: string;
}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant!}>
            <Header>
                <img src={Person} alt="" />
                {t(`club.headers.sports`)}
            </Header>
            {sports?.length
                ? sports.map((sport) => (
                      <SportIcon category={sport.category} key={`area-${areaId}-${sport.category}`} />
                  ))
                : t('club.areas.noCategories')}
        </StyledBox>
    );
};
