import '@mui/material/styles';
import '@mui/material/Button';
import '@mui/material/Chip';

declare module '@mui/material/styles' {
  interface Palette {
    white?: Palette['primary'];
    black?: Palette['primary'];
    tealLighten5?: Palette['primary'];
    darkGrey?: Palette['primary'];
  }
  interface PaletteOptions {
    white?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
    tealLighten5?: PaletteOptions['primary'];
    darkGrey?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    darkGrey: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tealLighten5: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    black: true;
  }
}