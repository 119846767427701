import {Box} from '@mui/material';
import styled, {keyframes} from 'styled-components';

const loading = keyframes`
    from { 
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
`;

export const StyledLoading = styled(Box)`
    width: 5rem;
    height: 5rem;
    border: 0.3rem solid;
    border-radius: 50%;
    animation: ${loading} 1s linear infinite;
    border-color: black black black transparent;
`;
