import {useNavigate} from 'react-router-dom';
import {AppService} from '../../../services/app.service';
import useClubContext from '../context/ClubContext';
import {useAuth} from '../../../hooks/useAuth';

export const useClub = () => {
    const appService = new AppService();
    const navigate = useNavigate();
    const {role} = useAuth();
    const {setIsLoading, setClub} = useClubContext();

    const fetch = (clubId: string) => {
        setIsLoading(true);
        appService
            .api_getClub(role === 'club-admin' ? 'public' : 'open', {clubId})
            .then((res) => {
                if (res.status !== 200) {
                    navigate('/search');
                    throw new Error();
                }
                setClub(res.data);
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    return {actions: {fetch}};
};
