import React, {FC} from 'react';
import useSearchContext from '../../contexts/SearchContext';
import {Loading} from '../loading/Loading';
import {
    ClubLocation,
    Name,
    Description,
    Area,
    AreaImage,
    AreaInfo,
    AreaImageContainer,
    ClubInfo,
    ClubImageContainer,
    ClubImage,
    EmptyListMessage,
} from './ClubList.styles';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Pin from '../../../../assets/icons/pin.svg';
import {PrivacyEnvironment} from '../../../club/components/privacy-environment/PrivacyEnvironment';
import {AreaSearchResultActions} from '../../../club/components/area-search-result/AreaSearchResultActions';
import {fromObjectToQueryString} from '../../utils/fromObjectToQueryString';
import {Alert, Box} from '@mui/material';

export const ClubsList: FC = (props) => {
    const {isLoading, isFirstSearchPerformed, searchObj, results, setPin} = useSearchContext();

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div {...props}>
            {isLoading ? (
                <Loading />
            ) : !results?.length ? (
                <EmptyListMessage>
                    {isFirstSearchPerformed && !isLoading && <Alert severity="info">{t('clubList.emptyList')}</Alert>}
                </EmptyListMessage>
            ) : (
                results?.map(({id, name, photoUrl, description, address, areas}) => {
                    return (
                        <ClubLocation
                            key={id}
                            onMouseEnter={() => {
                                setPin(id);
                            }}
                        >
                            <ClubInfo>
                                <ClubImageContainer>
                                    <ClubImage src={photoUrl ? photoUrl : 'https://placehold.co/400'} />
                                </ClubImageContainer>
                                <Name
                                    onClick={() => {
                                        navigate(`/club/${id}?a=1${fromObjectToQueryString(searchObj)}`);
                                    }}
                                >
                                    {name}
                                </Name>
                                {!!address && (
                                    <Description>
                                        <img src={Pin} alt="" />
                                        {`${address.address}, ${address.postalCode} - ${address.county}, ${address.district}`}
                                    </Description>
                                )}
                                {!!description && <Description>{description}</Description>}
                            </ClubInfo>
                            <Box>
                                {areas.map((area) => {
                                    const {
                                        id: areaId,
                                        name,
                                        price,
                                        description,
                                        photoUrl,
                                        schedules,
                                        closedSchedules,
                                        reservations,
                                        privacy,
                                        environment,
                                        categories,
                                    } = area;

                                    const isAreaClosed = !schedules.length || closedSchedules.length > 0;
                                    return (
                                        <Area key={areaId} disabled={isAreaClosed || reservations?.length > 0}>
                                            <AreaImageContainer>
                                                <AreaImage src={photoUrl ? photoUrl : 'https://placehold.co/400'} />
                                            </AreaImageContainer>
                                            <AreaInfo>
                                                <Name
                                                    onClick={() => {
                                                        navigate(
                                                            `/club/${id}?area=${areaId}${fromObjectToQueryString(
                                                                searchObj
                                                            )}`
                                                        );
                                                    }}
                                                >
                                                    {name}
                                                </Name>
                                                {!!description && <Description>{description}</Description>}
                                                <PrivacyEnvironment privacy={privacy} environment={environment} />
                                                <AreaSearchResultActions
                                                    categories={categories}
                                                    clubId={id}
                                                    areaId={areaId}
                                                    areaName={name}
                                                    price={price}
                                                    privacy={privacy}
                                                    reservations={reservations}
                                                    areaSchedules={schedules}
                                                    areaClosedSchedules={closedSchedules}
                                                    displayOptions={{
                                                        showPrice: true,
                                                        showClosedClubLabel: false,
                                                    }}
                                                />
                                            </AreaInfo>
                                        </Area>
                                    );
                                })}
                            </Box>
                        </ClubLocation>
                    );
                })
            )}
        </div>
    );
};
