import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AppService} from '../../services/app.service';
import {useCommon} from '../../context/CommonContext';
import {StyledAppButton} from '../BookingModal/BookingModal.styles';
import {Booking} from '../../models/booking.model';

export type BookingCancelModalProps = {
    booking: Booking;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
};

export const BookingCancelModal = ({booking, open, onClose, onDelete}: BookingCancelModalProps) => {
    const {t} = useTranslation();
    const {setSnackbar} = useCommon();

    const [sendingRequest, setSendingRequest] = useState(false);

    const handleDelete = () => {
        setSendingRequest(true);
        new AppService()
            .api_deleteBooking('public', booking.id!)
            .then((res) => {
                if (res.status !== 200) throw new Error(res);

                onClose();
                onDelete();
            })
            .catch((error) => {
                console.log(error);
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t('cancelBookingAlertMessage.500'),
                });
            })
            .finally(() => setSendingRequest(false));
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('cancelBookingReservation', {booking: booking?.shareId})}</DialogTitle>
            <DialogContent dividers>{t('cancelBookingMessage', {email: booking?.email})}</DialogContent>
            <DialogActions>
                <StyledAppButton variant="outlined" onClick={onClose} disabled={sendingRequest}>
                    {t('return')}
                </StyledAppButton>
                <StyledAppButton color="error" variant="contained" disabled={sendingRequest} onClick={handleDelete}>
                    {t('cancelBooking')}
                </StyledAppButton>
            </DialogActions>
        </Dialog>
    );
};
