import styled from 'styled-components';
import {FormLabel, Select} from '@mui/material';

export const SelectStyled = styled(Select)`
    background-color: #fff;
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        box-shadow: var(--default-boxShadow);
    }

    & .Mui-error {
        border: 1px solid red;
        background: #ffebee;
    }

    & .MuiSelect-select {
        display: flex;
        gap: 10px;
        align-items: center;
    }
`;

export const InputLabelStyled = styled(FormLabel)`
    line-height: 2rem;
    color: var(--custom-secondary);
    font-size: 1rem;

    &.MuiInputlabel-asterisk {
        color: red;
    }

    &.Mui-focused {
        color: var(--custom-secondary);
    }
`;
