import React from 'react';
import {Input} from '../Common/Input/TextField/Input';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Form} from './RecoverPassword.styles';
import {useCommon} from '../../context/CommonContext';
import {ErrorMessage, Formik} from 'formik';
import {StyledError} from '../ClubAdminRegister/ClubAdminRegister.styles';
import {validationResetPasswordSchema} from '../../models/validationResetPasswordSchema.model';
import {useResetPassword} from '../../hooks/useResetPassword';

export type RecoverPasswordProps = {
    email: string;
    role: 'admin' | 'club-admin' | 'player';
};

type RecoverPasswordData = {
    newPassword: string;
    confirmNewPassword: string;
    verificationCode: string;
};

const initialValues: RecoverPasswordData = {
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: '',
};

export const RecoverPassword = ({email, role}: RecoverPasswordProps) => {
    const {t} = useTranslation();
    const {mutate: resetPassword} = useResetPassword(role);

    const {setModal, setSnackbar} = useCommon();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationResetPasswordSchema(t)}
            onSubmit={async (values: RecoverPasswordData, {resetForm}) => {
                const formData = {...values, email};

                resetPassword(formData, {
                    onSuccess: (data) => {
                        if (data.status !== 200) throw new Error();
                        resetForm();
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('recoverPassword.success'),
                        });
                        setModal({open: false, children: <></>});
                    },
                    onError: () => {
                        resetForm();
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'error',
                            children: t('recoverPassword.error.onSubmit'),
                        });
                        setModal({open: false, children: <></>});
                    },
                });
            }}
        >
            {(props) => (
                <Form onSubmit={props.handleSubmit} id="form-resetPassword">
                    <Box>
                        <Input
                            id="verificationCode"
                            name="verificationCode"
                            hasLabelTop={true}
                            labelTop={t('recoverPassword.labels.verificationCode')}
                            placeholder={t('recoverPassword.verificationCode')}
                            type="text"
                            autoComplete="off"
                            required
                            value={props.values.verificationCode}
                            onChange={props.handleChange}
                        />
                    </Box>
                    <Box>
                        <Input
                            id="newPassword"
                            name="newPassword"
                            hasLabelTop={true}
                            labelTop={t('recoverPassword.labels.newPassword')}
                            placeholder={t('recoverPassword.newPassword')}
                            type="password"
                            autoComplete="off"
                            error={props.touched.newPassword && Boolean(props.errors.newPassword)}
                            required
                            value={props.values.newPassword}
                            onChange={props.handleChange}
                        />
                        <ErrorMessage name="newPassword">
                            {(msg: string) => <StyledError>{msg}</StyledError>}
                        </ErrorMessage>
                    </Box>
                    <Box>
                        <Input
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            hasLabelTop={true}
                            labelTop={t('recoverPassword.labels.confirmNewPassword')}
                            placeholder={t('recoverPassword.newPassword')}
                            type="password"
                            autoComplete="off"
                            error={props.touched.confirmNewPassword && Boolean(props.errors.confirmNewPassword)}
                            required
                            value={props.values.confirmNewPassword}
                            onChange={props.handleChange}
                        />
                        <ErrorMessage name="confirmNewPassword">
                            {(msg: string) => <StyledError>{msg}</StyledError>}
                        </ErrorMessage>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
