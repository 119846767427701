import {Dayjs} from 'dayjs';

export const fromDayjsToTimeStamp = (date: Dayjs): number =>
    date
        .format('HH:mm')
        .split(':')
        .reduce((acc, key, index) => {
            if (index === 0) {
                return Number(key) * 3600;
            } else {
                return acc + Number(key) * 60;
            }
        }, 0);
