import dayjs from 'dayjs';

import {valuesToLowerCase, valuesToTimeStamp} from './constants';

export const threatSearchValues = (key: string, value: any) => {
    if (valuesToLowerCase.includes(key)) {
        return String(value).toUpperCase();
    } else if (valuesToTimeStamp.includes(key)) {
        const date = dayjs(value, 'DD-MM-YYYY HH:mm').hour(0).minute(0).second(0);
        return date.unix();
    }
    return value;
};
