import React, { FC, useState, ReactNode, createContext, useContext } from "react";
import { Booking } from "../../../../models/booking.model";

export enum BookingsContextKeys {
  Booking = "booking"
}

export type BookingsContextType = {
  booking: Booking | null;
  setBooking: (newBooking: Booking | null) => void;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
};

export const BookingsContext = createContext<BookingsContextType>({
  booking: null,
  setBooking: () => {},
  isLoading: true,
  setIsLoading: () => {}
});

export type BookingsContextProviderProps = {
  children?: ReactNode;
};

export const BookingsContextProvider: FC<BookingsContextProviderProps> = (props) => {
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const values = {
    booking,
    setBooking,
    isLoading,
    setIsLoading
  }

  return (
    <BookingsContext.Provider value={values}>
      {props.children}
    </BookingsContext.Provider>
  );
};

export const useBookings = () => useContext(BookingsContext);
