import React from 'react';
import Routes from './routes';
import {AuthProvider} from './context/AuthContext';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useTranslation} from 'react-i18next';
import {enUS, ptBR} from '@mui/x-date-pickers/locales';
import ReactGA from 'react-ga4';

import './mui.d.ts';
import './locales/pt/pt.js';
import './locales/en/en.js';

ReactGA.initialize(process.env.GOOGLE_ANALITICS_KEY!);

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor: string) => augmentColor({color: {main: mainColor}});
const theme = createTheme({
    palette: {
        white: createColor('#ffffff'),
        black: createColor('#000000'),
        tealLighten5: createColor('#E0F2F1'),
        darkGrey: createColor('#424242'),
        error: createColor('#ff5252'),
    },
});

const App = () => {
    const {i18n} = useTranslation();

    return (
        <AuthProvider>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language}
                localeText={
                    i18n.language === 'pt'
                        ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
                        : enUS.components.MuiLocalizationProvider.defaultProps.localeText
                }
            >
                <ThemeProvider theme={theme}>
                    <Routes />
                </ThemeProvider>
            </LocalizationProvider>
        </AuthProvider>
    );
};

export default App;
