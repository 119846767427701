import React, {FC} from 'react';
import PersonIcon from '@mui/icons-material/Person';
import {ReservationType} from '../../../../shared/enums/ReservationType.enum';
import {EmojiEvents} from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import Filter5Icon from '@mui/icons-material/Filter5';
import SchoolIcon from '@mui/icons-material/School';

interface ReservationTypeProps {
    reservationType: ReservationType;
}

const ReservationTypeIcon: FC<ReservationTypeProps> = ({reservationType}) => {
    switch (reservationType) {
        case 'PRIVATE':
            return <PersonIcon fontSize="small" />;
        case 'CLASSES':
            return <SchoolIcon fontSize="small" />;
        case 'CLASSES_PACK':
            return <Filter5Icon fontSize="small" />;
        case 'TEAM_BUILDING':
            return <GroupsIcon fontSize="small" />;
        case 'TOURNAMENT':
            return <EmojiEvents fontSize="small" />;
        default:
            return null;
    }
};

export default ReservationTypeIcon;
