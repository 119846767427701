import React, {FC, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FileUploadContainer, InputBox, FilesBox, ImagePreview} from './FileUpload.styles';
import {Alert, Box, Container, Typography} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {AppButton} from '../Common/Buttons/AppButton';
import {useTranslation} from 'react-i18next';
import {Photo} from '../../models/photo.model';
import {useReservationPaymentProof} from '../../hooks/useReservationPaymentProof';
import {useCommon} from '../../context/CommonContext';

export type FileType = {
    src: string;
    alt: string;
};

export type FileUploadProps = {
    maxFileNumber: number;
    reservationId: string;
    onClose: () => void;
};

export const FileUpload: FC<FileUploadProps> = ({reservationId, maxFileNumber, onClose}) => {
    const {t} = useTranslation();
    const {setSnackbar} = useCommon();
    const {acceptedFiles, getRootProps, getInputProps, fileRejections} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        maxFiles: maxFileNumber,
        maxSize: 2 * 1024 * 1024, // 2MB limit
    });

    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const {mutate} = useReservationPaymentProof(reservationId);

    useEffect(() => {
        // Update image previews when acceptedFiles change
        const previews: string[] = [];

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                previews.push(reader.result as string);
                setImagePreviews([...previews]);
            };
            reader.readAsDataURL(file);
        });

        // Cleanup object URLs when component unmounts
        return () => {
            previews.forEach((preview) => URL.revokeObjectURL(preview));
        };
    }, [acceptedFiles]);

    const files = acceptedFiles.map((file, index) => (
        <FilesBox key={file.name}>
            {imagePreviews[index] && <ImagePreview src={imagePreviews[index]} alt={file.name} />}
        </FilesBox>
    ));

    const fileRejectionItems = fileRejections.map(({errors}) =>
        errors.map((e, index) => (
            <Alert severity="error" key={index}>
                {e.code === 'file-too-large' ? t('fileUploadErrorMaxSize') : t('fileUploadErrorMaxFiles')}
            </Alert>
        ))
    );

    const handleSubmit = async ({alt, src}: FileType) => {
        const fileName = alt.replace(/[^a-zA-Z0-9.]/g, '');
        const [fileBaseName, fileExt] = fileName.split('.');
        const paymentProof: Photo[] = [
            {
                fileName: fileBaseName,
                extension: fileExt,
                base64Data: src,
            },
        ];

        mutate(paymentProof, {
            onError: () => {
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t('paymentProof.message.failedUpload'),
                });
                onClose();
            },
            onSuccess: (data) => {
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'success',
                    children: t('paymentProof.message.successUpload'),
                });
                onClose();
            },
        });
    };

    return (
        <FileUploadContainer>
            <Container {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <InputBox>
                    <CloudUploadIcon fontSize="large" />
                    <Typography variant="body1">{t('fileUploadInput')}</Typography>
                    <Typography variant="body1">{t('fileUploadInputRules')}</Typography>
                </InputBox>
            </Container>
            {files && <FilesBox>{files}</FilesBox>}
            {fileRejectionItems && <Box>{fileRejectionItems[0]}</Box>}
            <AppButton
                variant="contained"
                onClick={() => handleSubmit(files[0].props.children.props)}
                disabled={acceptedFiles.length === 0}
            >
                {t('save')}
            </AppButton>
        </FileUploadContainer>
    );
};
