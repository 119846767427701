import React, {useState} from 'react';
import {Alert, AlertTitle, Stack} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useNavigate} from 'react-router-dom';
import BookingCard from '../BookingCard/BookingCard';
import {useTranslation} from 'react-i18next';
import {BookingModal} from '../BookingModal/BookingModal';

dayjs.extend(utc);

type BookingListProps = {
    bookings: any[];
    clubId: string;
    history: boolean;
    onBookingDelete: (id: string) => void;
    onIsPaidUpdate: (id: string, isPaid: boolean) => void;
};

export const BookingList = ({bookings, clubId, onBookingDelete, onIsPaidUpdate, history}: BookingListProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [bookingModalOpen, setBookingModalOpen] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<any>(null);

    const handleBookingClick = (booking: any) => {
        setSelectedBooking(booking);
        setBookingModalOpen(true);
    };

    if (bookings.length === 0)
        return (
            <Alert variant="filled" severity="info">
                <AlertTitle>{t('clubAdmin.bookings.noBookings')}</AlertTitle>
                {t('clubAdmin.bookings.noBookingsResult')}
            </Alert>
        );

    return (
        <>
            <Stack
                width="100%"
                display="grid"
                gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr',
                }}
                spacing={2}
                useFlexGap
            >
                {bookings
                    .sort((a: any, b: any) => (dayjs(a.from).isAfter(dayjs(b.from)) ? 1 : -1))
                    .map((booking: any) => (
                        <BookingCard
                            key={booking.id}
                            booking={booking}
                            onClick={() => handleBookingClick(booking)}
                            onCancel={() => navigate(`/club-admin/club/${clubId}/booking/${booking.id}/cancel`)}
                            onContact={() => (window.location.href = `mailto:${booking.email}`)}
                        />
                    ))}
            </Stack>
            {selectedBooking && (
                <BookingModal
                    booking={selectedBooking}
                    open={bookingModalOpen}
                    onClose={() => setBookingModalOpen(false)}
                    onDelete={() => onBookingDelete(selectedBooking.id)}
                    onIsPaidUpdate={(isPaid: boolean) => onIsPaidUpdate(selectedBooking.id, isPaid)}
                    historyFeatureToggle={history}
                />
            )}
        </>
    );
};
