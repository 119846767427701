import React, {FC} from 'react';
import {TextFieldProps} from '@mui/material/TextField';
import {InputLabelStyled, TextFieldStyled} from './Input.styles';
import {Box} from '@mui/material';

export type InputProps = {
    hasLabelTop?: boolean;
    labelTop?: string;
    labelTopRequired?: boolean;
} & TextFieldProps;

export const Input: FC<InputProps> = ({hasLabelTop, labelTop, labelTopRequired = false, ...props}) => {
    return (
        <Box sx={{width: '100%'}}>
            {hasLabelTop && <InputLabelStyled required={labelTopRequired}>{labelTop}</InputLabelStyled>}
            <TextFieldStyled {...props} />
        </Box>
    );
};
