import {Box} from '@mui/material';
import styled from 'styled-components';

export const AreasContainer = styled(Box)`
    margin-top: 2.5rem;
`;

export const AreasSearch = styled(Box)`
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    > div {
        width: calc(50% - 0.5rem);
        &:last-of-type {
            width: 100%;
        }
    }

    @media (min-width: 1024px) {
        > div {
            flex: 1;
            width: unset;
        }
    }
`;

export const Areas = styled(Box)`
    @media (min-width: 1024px) {
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
`;
