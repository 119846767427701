import styled from 'styled-components';
import {Box, Typography, Card, Alert, CardContent, FormControlLabel} from '@mui/material';

export const StyledCard = styled(Card)`
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: hidden;
`;

export const StyledCardLabel = styled(Typography)`
    font-size: 16px !important;
    font-weight: bold !important;
`;

export const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 1rem;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        padding: 0;
    },
`;

export const StyledContactBox = styled(Box)`
    display: grid;
    grid-template-columns: 50% auto;
    align-items: center;
    gap: 0.3rem;
`;

export const StyledContact = styled.p`
    color: var(--black-087);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
    display: flex;
    align-items: center;
`;

export const StyledStack = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.75rem;
`;

export const StyledNameTitle = styled.h2`
    color: var(--black-087);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
`;

export const StyledBookingBox = styled(Box)`
    border-right: 1px solid lightgrey;
`;

export const StyledAlert = styled(Alert)`
    border-radius: 6px !important;
`;

export const StyledStackPrice = styled.div`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 0.75rem;
    background-color: #ebf3ff;
    padding: 8px;
`;

export const StyledImageContainer = styled.div`
    margin: -1rem -1.5rem 0rem -1.5rem;
`;

export const StyledImage = styled.img`
    height: 104px;
    width: 100%;
    object-fit: cover;
    object-position: 0 50%;
`;

export const StyledCardContent = styled(CardContent)`
    display: flex !important;
    flex-direction: column;
    gap: 8px;
    padding: 8px !important;
`;

export const StyledLabel = styled.p`
    color: var(--black-058);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0;
`;

export const IconBox = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
`;

export const Category = styled.span`
    text-transform: capitalize;
`;

export const TaxesInfo = styled.p`
    color: var(--black-058);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const TermsAndConditionsLink = styled.span`
    font-size: 16px;
`;

export const TermsAndConditionsLabel = styled.p`
    color: var(--custom-secondary);
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    margin-right: 0 !important;
`;

export const PaymentMethodLabel = styled.div`
    color: var(--black-06);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    display: flex;
    width: 460px;
    justify-content: space-between;

    @media (max-width: 600px) {
        width: 280px;
    },
`;

export const CreditCardBox = styled(Box)`
    display: flex;
    gap: 8px;
`;
