import {TFunction} from 'i18next';
import * as yup from 'yup';
import {ReservationTypeEnum} from '../shared/enums/ReservationType.enum';

export const validationBookingSchema = (t: TFunction, isVisible: boolean) => {
    const baseSchema = {
        payment: yup.string().nullable(),
        reservationType: yup.string().default(ReservationTypeEnum.PRIVATE),
        terms: yup.boolean().test({
            name: 'required-terms',
            test: function (value) {
                if (!value) {
                    return this.createError({
                        path: 'terms',
                        message: t('validation.requiredTermsAndConditions'),
                    });
                }
                return true;
            },
        }),
    };

    // Add payment validation conditionally
    if (isVisible) {
        baseSchema.payment = yup.string().nullable().required(t('validation.requiredPaymentMethod'));
    } else {
        baseSchema.payment = yup.string().nullable();
    }

    return yup.object().shape(baseSchema);
};
