import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CreateBooking from '../../pages/club-admin/bookings/components/CreateBooking';
import {Club} from '../../shared/types/Club.type';
import {ClubArea} from '../../shared/types/ClubArea.type';
import {StyledAppButton} from './CalendarNewBooking.styles';

dayjs.extend(utc);

export type CalendarNewBookingModalProps = {
    club: Club;
    selectedDay?: {start: string; end: string};
    selectedArea?: string;
    selectedAreaInfo?: ClubArea;
    selectedSport?: any;
    areaSports?: any;
    open: boolean;
    onClose: () => void;
};

export const CalendarNewBookingModal = ({
    open,
    onClose,
    club,
    selectedDay,
    selectedArea,
    selectedSport,
    areaSports,
    selectedAreaInfo,
}: CalendarNewBookingModalProps) => {
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle sx={{m: 0, p: 2}}>
                {t('clubAdmin.newCalendarBookingModal.title')} [{club?.name}]
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <CreateBooking
                    club={club}
                    day={selectedDay}
                    selectedArea={selectedArea}
                    selectedSport={selectedSport}
                    areaSports={areaSports}
                    areaInfo={selectedAreaInfo}
                    onSuccessSubmit={onClose}
                />
            </DialogContent>

            <DialogActions>
                <StyledAppButton variant="outlined" onClick={onClose}>
                    {t('back')}
                </StyledAppButton>
                <StyledAppButton variant="contained" form={`form-createBooking`} type="submit">
                    {t('bookArea')}
                </StyledAppButton>
            </DialogActions>
        </Dialog>
    );
};
