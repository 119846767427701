import {useTranslation} from 'react-i18next';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Calendar from '../../../../assets/icons/calendar.svg';
import {Reservation} from '../../../../shared/types/Reservation.type';

import {ScheduleItem} from '../schedules/Schedules.styles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export const Reservations = ({reservations, variant}: {reservations: Reservation[]; variant: string}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Calendar} />
                {t(`club.headers.reservations`)}
            </Header>
            {!!reservations?.length
                ? reservations.map((reservation) => (
                      <ScheduleItem key={`reservation-${reservation.id}`}>
                          <div>{dayjs.utc(reservation.day).format('DD/MM/YYYY')}</div>
                          <div>
                              {dayjs.utc(reservation.from).format('HH:mm')} -{' '}
                              {dayjs.utc(reservation.to).format('HH:mm')}
                          </div>
                      </ScheduleItem>
                  ))
                : t('club.schedules.noInformation')}
        </StyledBox>
    );
};
