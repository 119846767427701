import React, {useEffect, useMemo, useState, useContext} from 'react';
import useClubContext from '../../context/ClubContext';
import Pin from '../../../../assets/icons/pin.svg';
import {Container, Description, Header, StyledMap, AddressDescription, ClubDescTab} from './ClubDescription.styles';
import {useTranslation} from 'react-i18next';
import {mapClubToMapInfo} from '../../../../shared/utils/mapClubToMapInfo';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Box} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import {clubSections} from '../../utils/constants';
import {renderSection} from '../../utils/renderSections';
import {ClubAreas} from '../club-areas/ClubAreas';
import {useAuth} from '../../../../hooks/useAuth';

export const ClubDescription = () => {
    const {club} = useClubContext();
    const {t} = useTranslation();
    const {role} = useAuth();
    const [tab, setTab] = useState<number>(0);
    const [searchParams] = useSearchParams();
    const mappedClub = useMemo(() => mapClubToMapInfo(club ? [club] : []), [club]);

    const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
        setTab(tabIndex);
    };

    useEffect(() => {
        const areaId = searchParams.get('area');
        if (!club || !areaId) {
            return;
        }
        if (areaId) {
            setTab(0);
            const element = document.getElementById(`area-${areaId}`);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [club, searchParams]);

    if (!club) {
        return null;
    }

    return (
        <Container>
            <Header>{t('club.headers.description')}</Header>
            <Description>{club.description}</Description>
            <Tabs value={tab} onChange={handleChange}>
                <Tab id="club-areas-tab" label={t('club.tabs.areas')} aria-controls="club-areas-tabpanel" />
                <Tab
                    id="club-description-tab"
                    label={t('club.tabs.description')}
                    aria-controls="club-description-tabpanel"
                />
            </Tabs>
            <Box role="tabpanel" hidden={tab !== 0} id="club-areas-tabpanel" aria-labelledby="club-areas-tab">
                <ClubAreas
                    isSearchEnabled={role === 'club-admin' ? club.mine : true}
                    clubId={club.id}
                    areas={club.areas}
                    clubPhoto={club.photos?.length ? club.photos : []}
                />
            </Box>
            <Box
                role="tabpanel"
                hidden={tab !== 1}
                id="club-description-tabpanel"
                aria-labelledby="club-description-tab"
            >
                <ClubDescTab>
                    {clubSections.map((section) => renderSection(section, club))}
                    <div>
                        <Header>
                            <img src={Pin} /> {t('club.headers.location')}
                        </Header>
                        <AddressDescription>
                            {`${club.address.address}, ${club.address.county}, ${club.address.district}`}
                        </AddressDescription>
                        <StyledMap
                            isActive={tab == 1}
                            center={{lat: club.address?.lat, lng: club.address?.lng}}
                            mapInfos={mappedClub ?? []}
                            withPrice={false}
                            showPopUp={false}
                        />
                    </div>
                </ClubDescTab>
            </Box>
        </Container>
    );
};
