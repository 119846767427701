import React from 'react';
import {QRCodeSVG} from 'qrcode.react';

type QRCodeProps = {
    value: string;
    options?: {
        height?: number;
        width?: number;
        bgColor?: string;
        fgColor?: string;
        level?: string;
    };
};

export const QRCode = ({value, options = {}}: QRCodeProps) => {
    return <QRCodeSVG value={value} {...options} />;
};
