import React from 'react';
import {Box, Chip, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {HistoryDataCardStyled} from './HistoryDataCard.styled';
import {SportIcon} from '../../../../../components/SportIcon/SportIcon';
import {Category} from '../../../../../shared/types/Category.type';
import {HistoryDataType} from '../../types/HistoryData.types';

dayjs.extend(utc);

export type HistoryDataCardProps = {
    history: HistoryDataType;
    onClick?: () => void;
};

const HistoryDataCard = ({history, onClick}: HistoryDataCardProps) => {
    const {t} = useTranslation();

    const handleCardClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onClick) onClick();
    };

    return (
        <HistoryDataCardStyled width="100%" bgcolor="#ffffff" p={1}>
            <Box onClick={handleCardClick}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">#{history.shareId}</Typography>
                    <SportIcon category={history.category as Category} />
                </Stack>
                <Typography variant="overline">{history?.name}</Typography>
                <Stack direction="row" alignItems="center" flexWrap="wrap" useFlexGap spacing={1}>
                    <Typography variant="caption">{dayjs(history.day).format('DD-MM-YYYY')}</Typography>
                    <Chip
                        color="tealLighten5"
                        size="small"
                        label={`${dayjs(history?.from).utc().format('HH:mm')} - ${dayjs(history?.to)
                            .utc()
                            .format('HH:mm')}`}
                    />
                </Stack>
                <Typography variant="caption">
                    {t('clubAdmin.historyData.createdBy')} <b>{history.CreatedBy?.name}</b>{' '}
                    {t('clubAdmin.historyData.at')} <b>{dayjs(history.createdAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                </Typography>
                {history.isPayed && (
                    <Box>
                        <Typography variant="caption">
                            {/* TODO: Add to history endpoint the information about payment method selected */}
                            {t('clubAdmin.historyData.paidBy')} <b>{history.PayedBy?.name}</b> por{' '}
                            {history.paymentMethod?.name} {t('clubAdmin.historyData.at')}{' '}
                            <b>{dayjs(history.payedAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                        </Typography>
                    </Box>
                )}
                {history.canceled && (
                    <Box>
                        <Typography variant="caption">
                            {t('clubAdmin.historyData.canceledBy')} <b>{history.CanceledBy?.name}</b>{' '}
                            {t('clubAdmin.historyData.at')}{' '}
                            <b>{dayjs(history.canceledAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                        </Typography>
                    </Box>
                )}
            </Box>
        </HistoryDataCardStyled>
    );
};

export default HistoryDataCard;
