import React, {FC} from 'react';
import {Box, SelectProps} from '@mui/material';
import {InputLabelStyled, SelectStyled} from './Select.styles';

export type MUISelectProps = {
    hasLabelTop?: boolean;
    labelTop?: string;
    labelTopRequired?: boolean;
} & SelectProps;

export const Select: FC<MUISelectProps> = ({hasLabelTop, labelTop, labelTopRequired = false, ...props}) => {
    return (
        <Box>
            {hasLabelTop && <InputLabelStyled required={labelTopRequired}>{labelTop}</InputLabelStyled>}
            <SelectStyled {...props} />
        </Box>
    );
};
