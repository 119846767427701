import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Logout = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    console.log('Logging out...');

    useEffect(() => {
        logout();

        sessionStorage.clear();

        const keysToRemove: string[] = ['clubAdmin.selectedClub'];
        keysToRemove.forEach(key => localStorage.removeItem(key));

        navigate('/');
    },[])

    return null;
}

export default Logout;