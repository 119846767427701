import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {FeatureToggleBaseComponent} from '../../featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from '../../../shared/enums/FeatureToggles.enum';
import {StyledToolbar, MenuLink, StyledAppButton, StyledDialogContent} from './Navbar.styles';
import {useCommon} from '../../../context/CommonContext';
import ClubsList from '../../../pages/club-admin/landing/components/ClubsList';
import {DialogActions} from '@mui/material';

export type TopMenuProps = {
    selectedClub: any;
};

export const TopMenu: FC<TopMenuProps> = ({selectedClub}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setModal} = useCommon();
    const active = window.location.pathname;

    return (
        <StyledToolbar>
            <MenuLink textAlign="center" isActive={active === '/club-admin'} onClick={() => navigate('/club-admin')}>
                {t('bookings')}
            </MenuLink>
            {selectedClub !== null && (
                <FeatureToggleBaseComponent featureToggle={FeatureToggles.RESERVATION_HISTORY}>
                    <MenuLink
                        textAlign="center"
                        isActive={active.includes('history')}
                        onClick={() => navigate(`/club-admin/club/${selectedClub?.id}/history`)}
                    >
                        {t('clubAdmin.menu.historyData')}
                    </MenuLink>
                </FeatureToggleBaseComponent>
            )}
            <MenuLink
                textAlign="center"
                onClick={() => {
                    setModal({
                        open: true,
                        title: t('clubAdmin.menu.clubManagement'),
                        children: (
                            <>
                                <StyledDialogContent dividers>
                                    <ClubsList />
                                </StyledDialogContent>
                                <DialogActions>
                                    <StyledAppButton
                                        id="cancel"
                                        variant="outlined"
                                        type="reset"
                                        onClick={() => setModal({open: false, children: <></>})}
                                    >
                                        {t('back')}
                                    </StyledAppButton>
                                </DialogActions>
                            </>
                        ),
                    });
                }}
            >
                {t('clubAdmin.menu.clubManagement')}
            </MenuLink>
            {selectedClub !== null && (
                <MenuLink
                    textAlign="center"
                    isActive={active.includes('areas')}
                    onClick={() => navigate(`/club-admin/club/${selectedClub?.id}/areas`)}
                >
                    {t('clubAdmin.menu.clubAreas')}
                </MenuLink>
            )}
        </StyledToolbar>
    );
};
