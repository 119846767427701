import {useMutation} from 'react-query';
import {AppService} from '../services/app.service';

export function useUpdateReservation(role: string) {
    const appService = new AppService();

    const {isLoading, data, mutate, isError, error} = useMutation({
        mutationKey: ['updateReservation'],
        mutationFn: async (data: any) => {
            const res = await appService.api_updateBooking(role, data);
            return res;
        },
    });

    return {data, isLoading, mutate, isError, error};
}
