import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import ParkIcon from '@mui/icons-material/Park';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {Container} from './PrivacyEnvironment.styles';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

export const PrivacyEnvironment = ({privacy, environment}: {privacy?: string; environment?: string}) => {
    const {t} = useTranslation();
    return (
        <Container>
            <Box>
                {privacy === 'PRIVATE' ? (
                    <LockIcon fontSize="small" color="disabled" />
                ) : (
                    <NoEncryptionGmailerrorredIcon fontSize="small" color="disabled" />
                )}
                <span>{t(`${privacy!.toLowerCase()}`)}</span>
            </Box>
            <Box>
                {environment === 'INDOOR' ? (
                    <WarehouseIcon fontSize="small" color="disabled" />
                ) : (
                    <ParkIcon fontSize="small" color="disabled" />
                )}
                <span>{t(`${environment!.toLowerCase()}`)}</span>
            </Box>
        </Container>
    );
};
