{
    "welcome": "Welcome!",
    "generalError": "Something unexpected happened. Please try again.",
    "and": "and",
    "or": "or",
    "error": "Error",
    "less": "Less",
    "more": "More",
    "price": "Price",
    "back": "Back",
    "iban": "IBAN",
    "bic": "BIC/SWIFT",
    "email": "E-mail",
    "continue": "Continue",
    "finish": "Finish",
    "return": "Return",
    "cancel": "Cancel",
    "canceled": "Canceled",
    "contact": "Contact",
    "query": "Query",
    "stop": "Stop",
    "stopped": "Closed",
    "duplicate": "Duplicate",
    "filter": "Filter",
    "delete": "Delete",
    "details": "Details",
    "termsAndConditions": "Terms & Conditions",
    "pageNotFound": "Oops, the page you were looking for does not exist.",
    "goToHomepage": "Go to homepage",
    "signInSignUp": "Sign In/Sign Up",
    "paymentConfirmed": "Payment confirmed",
    "paymentNotConfirmed": "Payment not confirmed",
    "bookings": "Bookings",
    "noBookings": "No bookings",
    "cancelBooking": "Cancel booking",
    "cancelBookingReservation": "Cancel booking {{booking}}",
    "cancelBookingMessage": "An e-mail will be sent to {{clientEmail}} informing about the cancellation of the reservation.",
    "bookingIsPaid": "Booking {{id}} marked as paid.",
    "bookingIsNotPaid": "Booking {{id}} marked as not paid.",
    "newClub": "New sport facility",
    "selectClub": "Select a sport facility:",
    "area": "Area",
    "areasOfClub": "Areas of sports facility",
    "noAreas": "No areas",
    "newArea": "New area",
    "areaIsClosed": "The area is currently closed. Closing period: {{schedule}}",
    "areaDelete": "Delete area",
    "areaDeleteDescription": "If you delete this area, you will automatically cancel all reservations for this area. This action is permanent and cannot be reversed.",
    "scanThisQRCode": "This QR Code allows you to access the booking details.",
    "missingInfo": "Missing information",
    "areasCount": "Number of areas",
    "privacy": "Privacy",
    "environment": "Environment",
    "not_specified": "Any",
    "private": "Private",
    "public": "Public",
    "indoor": "Indoor",
    "outdoor": "Outdoor",
    "clubCreated": "Sports facility successfully created!",
    "createAreaInfo": "Now create an area for each zone of the sports facility with different conditions/prices.",
    "createArea": "Create Area",
    "noClubInfo": "There is no sports facility created. Please create a new sports facility using the + NEW SPORTS FACILITY button.",
    "addContact": "Add contact",
    "bookingDetails": "Detalhes da Reserva",
    "sport": "Sport",
    "day": "Day",
    "schedule": "Schedule",
    "noContactsTitle": "No Contacts",
    "noContactsInfo": "No other contacts created",
    "save": "Save",
    "close": "Close",
    "from": "From",
    "to": "to",
    "password": "Password",
    "alreadyBooked": "Already booked at this time",
    "clubImageDefaultTitle": "Standard photo of the sports facility",
    "availableSlotsMessage": "Get ready for an unforgettable game! 🏀🏓⚽️ Book your timetable and join in the sporting fun.",
    "availableSlotsDate": "📅 Time slots available for: {{date}}",
    "shareAvailableSlotsMessage": "Get ready for an unforgettable game! 🏀🏓⚽️\nBook your timetable and join in the sporting fun.\n\n📅 Time slots available for:\n{{date}}\n\n📍 Sports facility:\n{{clubName}}\n\n ⛳️ Area(s):\n{{area}}\n🔗 Visit our website and book now:\n{{link}} ",
    "shareAvailableSlotsNoSchedulesError": "This area has no time slot available for tomorrow.",
    "fileUploadInput": "Drag 'n' drop some files here, or click to select files",
    "fileUploadInputRules": "You can only upload a .jpeg or .png image of up to 2MB",
    "fileUploadErrorMaxFiles": "Image limit exceeded. Only one image can be uploaded.",
    "fileUploadErrorMaxSize": "Image too large. Only one image up to 2MB may be uploaded.",
    "cancelBookingAlertMessage": {
        "200": "Reservation canceled successfully.",
        "403": "You don't have permissions to cancel the reservation.",
        "404": "There is no reservation with the indicated number.",
        "500": "An error occurred while canceling your reservation, please try again.",
        "noData": "An unexpected error occurred, please try again."
    },
    "newBooking": "New booking",
    "checkout": {
        "message": "Welcome back! Please log in to proceed with your booking"
    },
    "validation": {
        "requiredPassword": "Password required.",
        "requiredName": "The name field is mandatory.",
        "requiredClubName": "The sport facility name field is mandatory.",
        "requiredValidEmail": "Please enter a valid e-mail.",
        "requiredEmail": "The e-mail field is mandatory.",
        "requiredPhone": "The phone field is mandatory.",
        "requiredValidPhone": "Please enter a valid phone number.",
        "oldPassword": "Old password is required.",
        "newPassword": "New password is required.",
        "confirmPassword": "Password must match",
        "toShort": "Field must have at least {{char}} characters.",
        "toLong": "Field must have a maximum of {{char}} characters.",
        "fiscalNumberOnlyNumber": "The tax number field should only contain digits.",
        "fiscalNumberMinNumber": "The tax number field must have at least 9 digits.",
        "fiscalNumberMaxNumber": "The tax number field must have a maximum of 9 digits.",
        "phoneOnlyNumber": "The mobile phone number field should only contain digits.",
        "invalidPrivacy": "The selected property is invalid.",
        "invalidEnvironment": "AThe selected environment is invalid.",
        "invalidTimeUnit": "The selected time unit is invalid.",
        "requiredField": "This field is required.",
        "requiredPaymentMethod": "You must select the payment method.",
        "requiredTermsAndConditions": "Before proceeding, you must accept the terms and conditions.",
        "requiredMinimumField": "You must select at least one option.",
        "dateTo": "Closing time should be longer than opening time.",
        "minEndDate": "The end date must be greater than the start date.",
        "invalidPassword": "Password is not valid.",
        "requiredValidIban": "Invalid IBAN. Remove spaces and type PT followed by 23 digits.",
        "requiredValidPostalCode": "Invalid postcode. Enter a postcode in the format 0000-000.",
        "invalidSSO": "Verify if the e-mail is being used in another account."
    },
    "login": {
        "forgotPassword": "Forgot password?",
        "login": "Login",
        "loginWithGoogle": "Login with Google",
        "loginWithFacebook": "Login with Facebook",
        "register": "Not registered yet? Create an account",
        "clubAdminLogin": "Club Admin Login",
        "adminLogin": "Admin Login",
        "okButton": "OK",
        "error": {
            "wrongEmailOrPassword": "Incorrect e-mail or password, please try again."
        }
    },
    "verify": {
        "verificationCode": "VERIFICATION CODE",
        "errorMessage": "Unverified e-mail! Try again!",
        "successMessage": "Your account has been verified!",
        "okButton": "OK",
        "error": {
            "notVerified": "Unverified e-mail! Try again!"
        }
    },
    "paymentProof": {
        "message": {
            "failedUpload": "The payment receipt has failed to load. Please try again.",
            "successUpload": "Payment proof uploaded successfully."
        }
    },
    "clubAdmin": {
        "requestAccount": {
            "title": "Account creation request",
            "adminName": "Name of the sports facility manager",
            "clubName": "Name of the sports facility",
            "email": "E-mail",
            "phone": "Phone number",
            "observations": "Comments",
            "success": "Submission made successfully.",
            "submitButton": "Submit",
            "error": {
                "onSubmit": "Error submitting form! Try again, please!"
            }
        },
        "recoverPassword": {
            "title": "Change password",
            "verificationCode": "Verification code",
            "verificationCodeLabel": "Enter the verification code",
            "newPassword": "New password",
            "newPasswordLabel": "Enter the new password",
            "confirmNewPasswordLabel": "Confirm the new password",
            "success": "Password changed successfully.",
            "emailSent": "An e-mail with the verification code for password reset has been sent.",
            "submitButton": "Change password",
            "error": {
                "passwordNotValid": "Your password must have at least 8 characters, including at least one uppercase and one lowercase letter, a number and a special character.",
                "passwordNotMatching": "The password provided in the two fields is different! Please try again!",
                "onSubmit": "Error submitting form! Please try again!",
                "emailNotSent": "Could not send password reset e-mail. Try again."
            }
        },
        "menu": {
            "home": "Home",
            "noClubs": "No sports facilities",
            "createClub": "Create sports facility",
            "selectedClub": "Selected sports facility",
            "clubManagement": "Sports facilities management",
            "clubAreas": "Areas",
            "reviews": "Rates",
            "temporaryStop": "Stop temporarily",
            "editAccount": "Edit Account",
            "help": "Help",
            "logout": "Logout",
            "deleteClub": "Delete sports facility",
            "historyData": "History Data",
            "editClub": "Edit sports facility"
        },
        "bookings": {
            "allClubs": "Select sports facility",
            "bookings": "BOOKINGS",
            "noBookings": "Without bookings",
            "noBookingsResult": "There are no bookings made with the search criteria selected.",
            "newBooking": "New booking",
            "allAreas": "All areas",
            "calendarButton": "Calendar",
            "listButton": "List",
            "selectCategory": "Select sports",
            "selectDate": "Select date",
            "selectStartHour": "Select starting hour",
            "timeUnit": "Select time unit",
            "TRINTA_MINUTOS": "30 Minutes",
            "UMA_HORA": "1 Hour",
            "UMA_HORA_TRINTA_MINUTOS": "1 Hour and 30 Minutes",
            "DUAS_HORA": "2 Hours",
            "selectArea": "Select area",
            "selectDay": "Day",
            "selectTime": "Time",
            "showFilterButton": "Filters",
            "selectReservationType": "Select booking type",
            "allReservationType": "All categories",
            "reservationTypes": {
                "selectReservationType": "Select the booking type",
                "private": "Private",
                "classes": "Classes",
                "classes_pack": "Classes pack",
                "team_building": "Team building",
                "tournament": "Tournament"
            },
            "payment": "Proof of payment",
            "inputLabel": {
                "search": "Booking ID, name or phone contact",
                "clientName": "Client name",
                "clientEmail": "Client e-mail",
                "selectedDay": "Day",
                "selectedTime": "Time",
                "selectedSport": "Sport",
                "reservationType": "Type"
            },
            "inputPlaceholder": {
                "clientName": "Client name",
                "clientEmail": "Client e-mail"
            },
            "success": {
                "title": "{{area}} booked",
                "201": "{{area}} booked. The {{area}} was reserved on the name of {{clientName}}, in {{day}}, at {{from}}-{{to}}. The confirmation was sent by e-mail to {{email}}."
            },
            "error": {
                "noSelectedClubTitle": "Select sports facility",
                "noSelectedClub": "You must select a sports facility in order to proceed with the booking.",
                "scheduleError": "It is not possible to make bookings outside the hours defined in the selected field.",
                "pastDate": "It is not possible to make bookings outside the hours defined in the selected field.",
                "notAreaSelected": "To create a new booking, please select an area in the filters.",
                "invalidInterval": "Time not allowed. Please select intervals of {{time}}.",
                "overlappingEvents": "A valid booking already exists in the selected interval.",
                "noData": "Error. An error occurred while filtering areas, please try again.",
                "400": "We were unable to process your reservation. Schedules are not defined for this area.",
                "403": "You don't have permissions to complete the order.",
                "409": "There is already a booking for the selected time period.",
                "500": "Error. An error occurred while booking, please try again."
            },
            "modal": {
                "title": "Booking details",
                "clientName": "Client name",
                "clientEmail": "Customer e-mail",
                "mobilePhone": "Customer telephone contact",
                "day": "Day",
                "time": "Time",
                "cancelButton": "Cancel booking",
                "reservationTypeUpdateSuccess": "Booking type successfully updated.",
                "reservationTypeUpdateError": "We were unable to update the booking type, please try again."
            }
        },
        "newCalendarBookingModal": {
            "title": "New booking",
            "description": "Create a new reservation from {{start}} to {{end}}?",
            "bookButton": "Book"
        },
        "clubAreas": {
            "error": {
                "serverError": "This operation could not be completed. Please try again later.",
                "failedUpload": "Photo upload failed. Please try again.",
                "failedDelete": "The selected photo could not be deleted. Please try again."
            },
            "success": {
                "defaultClubImage": "The standard image of the sports facility has been successfully defined."
            }
        },
        "historyData": {
            "selectUsers": "Select Users",
            "selectEndDate": "Select end date",
            "selectStartDate": "Select start date",
            "createdBy": "Created by",
            "at": "at",
            "canceledBy": "Canceled by",
            "paidBy": "Paid by",
            "noHistory": "No reservations for this filter.",
            "selectFilterToShowResults": "Apply the desired filters and click on the FILTER button to see the results.",
            "accordionTitle": "History of actions"
        }
    },
    "editAccount": {
        "username": "Username",
        "phoneNumber": "Mobile phone",
        "fiscalNumber": "Tax number",
        "address": "Address",
        "success": {
            "changePassword": "Password successfully updated.",
            "userUpdate": "Information successfully updated.",
            "delete": "Account canceled successfully."
        },
        "error": {
            "forbiden": "You don't have permissions to change the password.",
            "serverError": "Unable to complete this operation. Please try again later.",
            "userUpdate": "Unable to update your account information. Please try again later."
        },
        "emailNote": {
            "noLink": "To change your e-mail, please ",
            "link": "contact the support team."
        },
        "button": {
            "changePassword": "Change password",
            "deleteAccount": "Delete account",
            "cancel": "Cancel"
        },
        "modal": {
            "delete": {
                "title": "Delete account",
                "description": "If you delete your Freecourts account, you understand that you will lose access to all future and past bookings and all data entered. This action is permanent and cannot be undone.",
                "helperText": "To confirm account deletion",
                "error": {
                    "wrongPassword": "Incorrect password! Try again!"
                }
            },
            "change": {
                "title": "Change password",
                "label": {
                    "oldPassword": "Enter the old password.",
                    "newPassword": "Enter a new password.",
                    "confirmPassword": "Confirm the new password."
                },
                "inputLabel": {
                    "newPassword": "New password",
                    "oldPassword": "Old password",
                    "confirmPassword": "New password"
                }
            }
        }
    },
    "searchArea": "Search Area",
    "searchAvailableArea": "Search Available Area",
    "noDataFound": "Nenhum dado encontrado",
    "requiredFields": "Enter all required fields",
    "book": "Book",
    "booking": {
        "label": {
            "clubInfo": "Sports facility information",
            "bookingInfo": "Booking information",
            "interested": "Interested",
            "name": "Name",
            "payment": "Bank transfer",
            "acceptance": "I accept the",
            "termsAndConditions": "Terms & Conditions",
            "sportsFacility": "of this sports facility.",
            "priceSummary": "Price summary",
            "details": "Enter your details",
            "total": "Total",
            "includedTaxes": "Includes taxes and fees",
            "publicAreaInfo": "This field is public, it may be busy.",
            "transferObservation": "Mention in the bank transfer notes the ",
            "paymentNote": "IMPORTANT: The deadline for payment and sending the receipt by e-mail is 24 hours, after this period the reservation will be canceled. Confirmation can take up to 2 days.",
            "importantNote": "IMPORTANT: This payment method requires payment and sending the receipt of the bank transfer by e-mail within 24 hours of scheduling the reservation.",
            "reservationNote": "If the reservation has not yet been confirmed by the sports facility, it may be necessary to present receipt of transfer on site.",
            "bookingSuccess": "Reservation process started successfully.",
            "paymentConfirmed": "Reservation payment confirmed.",
            "paymentMethods": "Payment Methods",
            "creditCardNote": "Credit or debit card payments: for greater security, the 3D-Secure authentication system has been reinforced. Confirm your purchase on your mobile phone, via your bank's app, or enter the code sent via SMS on the authentication page. For more information, please contact your bank."
        },
        "placeholder": {
            "name": "Insert name",
            "email": "Insert e-mail"
        },
        "lockerRoomType": {
            "both": "Male and female locker rooms",
            "male": "Male locker rooms",
            "female": "Female locker rooms",
            "mixed": "Mixed locker rooms",
            "noLockerRoom": "No locker rooms"
        },
        "button": {
            "book": "Book",
            "shareBooking": "Share booking",
            "next": "Book",
            "share": "Share"
        },
        "details": "Booking details",
        "payment": "Payment",
        "paymentDetails": "Payment details",
        "userInfo": "Booker information",
        "nameLabel": "Name",
        "bookings": "See bookings",
        "shareAvailableSlots": "Share available slots"
    },
    "timeUnit": "Reserve Unit",
    "priceUnit": "Price per unit",
    "bookArea": "Book area",
    "clubDetails": {
        "contacts": {
            "noContactsTitle": "No contacts",
            "noContactsInfo": "No other contacts created",
            "contactList": "Contacts list",
            "message": {
                "success": "Contact created successfully",
                "deleteSuccess": "Contact deleted successfully",
                "updateSuccess": "Contact updated successfully",
                "error": "Error! Contact could not be created. Please try again later."
            }
        },
        "step": {
            "label": {
                "clubData": "Sports facility data",
                "clubPhotos": "Sports facility photos",
                "paymentMethods": "Payment Methods",
                "infrastructures": "Sports facilities",
                "schedules": "Schedules",
                "clubContacts": "Other contacts",
                "clubAddress": "Address of the sports facility"
            },
            "inputLabel": {
                "clubName": "Sports facility name",
                "offersAndNews": "I want to receive exclusive offers and news from the FreeCourts community.",
                "clubPhone": "Telephone",
                "clubMobile": "Mobile",
                "clubAddress": "Address",
                "clubDescription": "Sports facility description",
                "clubPhotos": "Sports facility photos",
                "paymentMethods": "Select all supported payment methods",
                "organizations": "Organizations",
                "contactName": "Name",
                "address": "Address",
                "postalCode": "Zip Code",
                "district": "District",
                "county": "County",
                "lat": "Latitude",
                "lng": "Longitude"
            },
            "inputPlaceholder": {
                "clubName": "Sports facility name",
                "clubEmail": "Sports facility e-mail",
                "clubPhone": "Telephone contact",
                "clubMobile": "Alternative telephone contact",
                "clubAddress": "Address",
                "clubDescription": "Available equipment and indications if they are paid or free.",
                "clubPhotos": "Upload photos",
                "contactName": "Name",
                "address": "Street, avenue, place or road",
                "postalCode": "Zip Code",
                "lat": "Latitude",
                "lng": "Longitude"
            }
        },
        "message": {
            "getOrganizationsError": "An error occurred when loading the list of organizations.",
            "createOrganizationSuccess": "List of organizations successfully created.",
            "createOrganizationError": "An error occurred when trying to update the list of organizations.",
            "updateOrganizationSuccess": "List of organizations successfully updated.",
            "defaultImage": "After uploading the photos of the sports facility, you can choose the default image by clicking on the image you want."
        }
    },
    "submitSuccess": "Successful submission",
    "search": {
        "labels": {
            "sport": "Select the sport",
            "date": "Select the date",
            "startDate": "Start date",
            "endDate": "End date",
            "time": "Select the start time",
            "timeUnit": "Select duration",
            "district": "Select location",
            "clubName": "Search by sports facility name",
            "selectInfrastructure": "Select sports facilities",
            "noInfrastructures": "No options"
        },
        "fields": {
            "sport": "Sport",
            "date": "Date",
            "time": "Start time",
            "endTime": "End time",
            "timeUnit": "Duration",
            "district": "Location",
            "clubName": "Sports facility name"
        },
        "sports": {
            "any": "Any",
            "padel": "Padel",
            "tenis": "Tennis",
            "basquetebol": "Basketball",
            "andebol": "Handball",
            "futebol": "Football",
            "fut7": "Fut7",
            "futsal": "Futsal",
            "squash": "Squash",
            "hoquei_patins": "Roller Hockey",
            "fut5": "Fut5",
            "voleibol": "Volley"
        },
        "cta": "Search",
        "errors": {
            "isRequired": "The field {{field}} is required"
        }
    },
    "clubList": {
        "book": "Proceed",
        "emptyList": "No sports facilities matching the current search criteria were found",
        "club": {
            "club_unavailable": "Sports facility unavailable",
            "schedule_error": "Selected date is outside the sports facility regular schedule",
            "closed_schedule_error": "Sports facility will be sporadically closed from {from} to {to}"
        },
        "area": {
            "club_unavailable": "Field unavailable",
            "schedule_error": "Selected date is outside the fields's regular schedule",
            "closed_schedule_error": "Field will be sporadically closed from {{from}} to {{to}}"
        }
    },
    "club": {
        "tabs": {
            "description": "Description",
            "areas": "Areas"
        },
        "headers": {
            "description": "Description",
            "paymentMethods": "Payment methods",
            "parking": "Parking",
            "lockerooms": "Locker Rooms",
            "schedules": "Schedules",
            "location": "Location",
            "closed": "Closing Dates",
            "sports": "Sports",
            "reservations": "Reservations",
            "priceByTimeUnit": "Price / Time interval",
            "selectAllSportsAvailable": "Select all sports available at the sports facility",
            "whiciSportsOffer": "What sports do you offer?",
            "clubGallery": "Photo gallery"
        },
        "paymentMethods": {
            "emptyList": "There are no payment methods available",
            "cash": "Cash",
            "mbway": "MB Way",
            "credit_card": "Credit Card",
            "bank_transfer": "Bank transfer"
        },
        "lockerooms": {
            "noInformation": "There is no information regarding locker rooms",
            "no_locker_room": "There are no locker rooms",
            "women_locker_room": "There are only women's locker rooms",
            "men_locker_room": "There are only mes's locker rooms",
            "mixed_locker_room": "Mixed locker rooms",
            "both_locker_room": "Male and female locker rooms"
        },
        "parking": {
            "noInformation": "There is no parking information",
            "none": "There are no parking lots",
            "free": "Free parking",
            "paid": "Paid parking"
        },
        "schedules": {
            "noInformation": "There is no schedule information",
            "weekdays": {
                "monday": "Monday",
                "tuesday": "Tuesday",
                "wednesday": "Wednesday",
                "thursday": "Thursday",
                "friday": "Friday",
                "saturday": "Saturday",
                "sunday": "Sunday"
            }
        },
        "gallery": {
            "emptyList": "There are no images of the sports facility to show"
        },
        "closed": {
            "noInformation": "There is no closing information."
        },
        "reservations": {
            "noInformation": "No reservations have been made",
            "success": "Booking successful..",
            "error": "An error occurred while trying to make a booking. Please check that you have timetables created in this area and that the desired booking is within the defined timetable."
        },
        "areas": {
            "noAreas": "There are no available areas for this sports facility.",
            "noCategories": "There are no sports available in this area"
        },
        "message": {
            "deleteClubTitle": "Delete {{club}}",
            "deleteClubDescription": "If you delete this club, you will automatically cancel all reservations for this club. This action is permanent and cannot be reversed."
        }
    },
    "clubAreas": {
        "step": {
            "label": {
                "areaData": "Area Data",
                "areaSports": "Sports offer",
                "areaSportsOffer": "What sports do you offer?",
                "areaSportsSelect": "Select all the sports available in this area",
                "areaPhotos": "Area Photos",
                "areaSchedules": "Schedules",
                "repeatSchedule": "Repeat schedules",
                "activityPeriod": "Activity Period",
                "closedPeriod": "Closing Period"
            },
            "inputLabel": {
                "areaName": "Area name",
                "areaDescription": "Area description",
                "areaPhotos": "Area Photos",
                "areaSchedules": "Set opening and closing times",
                "areaClosedSchedules": "Set the start and end date of the closing period"
            },
            "inputPlaceholder": {
                "areaName": "Area name",
                "areaDescription": "Equipment available, type of flooring, etc...",
                "areaPhotos": "Upload pictures"
            }
        },
        "message": {
            "noSportInfoTitle": "No sport",
            "noSportInfoDescription": "Selected sport does not exist in this field.",
            "areaScheduleSuccess": "Operation successfully completed.",
            "areaScheduleError": "An error has occurred. Please try again.",
            "schedules": {
                "409": "Já existe um horário definido para a hora de abertura e fecho selecionado."
            },
            "closedSchedules": {
                "409": "Já existe um período de encerramento definido para as datas de início e de fim selecionadas."
            }
        },
        "allWeekdays": "Todos os dias",
        "timeUnit": "Time unit",
        "newSchedule": "New Schedule",
        "newClosedSchedule": "New Closed Schedule",
        "repeat": "Repeat",
        "startDate": "Start date",
        "endDate": "End date",
        "open": "Opening",
        "close": "Closing",
        "schedules": {
            "noSchedulesTitle": "No opening schedules",
            "noSchedulesInfo": "There are no timetables created for this area.",
            "noClosedSchedulesTitle": "No closing schedules",
            "noClosedSchedulesInfo": "There are no closure periods created for this area."
        },
        "address": {
            "selectCoordinatesTitle": "Select coordinates",
            "selectCoordinatesInfo": "Select the location of the sports facility on the map to collect the coordinates."
        }
    },
    "register": {
        "title": "CREATE YOUR ACCOUNT",
        "name": {
            "label": "Name",
            "required": "Name is required."
        },
        "email": {
            "label": "E-mail",
            "invalid": "The e-mail is invalid.",
            "required": "E-mail is required."
        },
        "password": {
            "invalid": "Your password must have at least 8 characters, including at least one uppercase and one lowercase letter, a number and a special character.",
            "required": "Password is required."
        },
        "phone": {
            "label": "Phone",
            "invalid": "Phone is invalid."
        },
        "nif": {
            "label": "NIF",
            "invalid": "NIF is invalid."
        },
        "address": {
            "label": "Address",
            "invalid": "Address is invalid."
        },
        "submitButton": "Create account",
        "success": "Account created successfully.",
        "error": {
            "onSubmit": "Account creation failed. Please, try again.",
            "409": "The user {{email}}"
        }
    },
    "recoverPassword": {
        "title": "Change password",
        "verificationCode": "Verification code",
        "newPassword": "New password",
        "success": "Password changed successfully.",
        "emailSent": "An e-mail with the verification code for password reset has been sent.",
        "submitButton": "Change password",
        "labels": {
            "verificationCode": "Enter the verification code",
            "newPassword": "Enter the new password",
            "confirmNewPassword": "Confirm the new password"
        },
        "error": {
            "passwordNotValid": "Your password must have at least 8 characters, including at least one uppercase and one lowercase letter, a number and a special character.",
            "passwordNotMatching": "The password provided in the two fields is different! Please try again!",
            "onSubmit": "Error submitting form! Please, try again.",
            "emailNotSent": "Could not send password reset e-mail. Please, try again."
        }
    },
    "playerMenu": {
        "home": "Home",
        "editAccount": "Edit Account",
        "help": "Help",
        "logout": "Logout"
    },
    "shareBooking": {
        "bookingOf": "Booking of {{name}}",
        "shareLink": "Share booking link",
        "imInterested": "I'm interested",
        "imNotInterested": "I'm not interested",
        "interestedString": {
            "0": "Be the first to show interest.",
            "1a": "{{name}} are interested.",
            "1b": "{{name}} is interested.",
            "2": "{{name1}} and {{name2}} are interested.",
            "3": "{{name1}}, {{name2}} and {{number}} more are interested."
        },
        "shareTitle": "Share the booking",
        "copyLink": "Copy link",
        "copied": "Copied",
        "message": "⚽️ I just made a booking on FreeCourts!\n\n📅 At {{date}} we'll play {{category}} from {{startTime}} to {{endTime}}.\n\n📍 Place:\n- {{clubName}}, {{areaName}}\n- {{clubAddress}}, {{clubPostalCode}} {{clubCounty}}\n\n👩‍👨‍👦‍👧 Who already confirmed: {{names}}\n\n🔗 Link for confirmation and see the details:\n{{url}}",
        "shareMessage": "Share message",
        "shareOnWhatsapp": "Share on Whatsapp",
        "shareOnTwitter": "Share on Twitter",
        "shareOnFacebook": "Share on Facebook"
    },
    "firstLogin": {
        "title": "Change password",
        "description": "For security reasons, you need to change your password.",
        "newPassword": "New password",
        "newPasswordLabel": "Insert new password",
        "confirmNewPasswordLabel": "Confirm the new password",
        "success": "Password changed successfully.",
        "submitButton": "Change password",
        "error": {
            "passwordNotValid": "Your password must have at least 8 characters, including at least one uppercase and one lowercase letter, a number and a special character.",
            "passwordNotMatching": "The password provided in the two fields is different! Please try again!",
            "onSubmit": "Error submitting form! Please, try again."
        }
    },
    "genericErrors": {
        "fileSizeExceed": "Selected file size exceed 10mb",
        "maxNumberExceed": "You have exceeded the limit of {{number}} images allowed for upload."
    },
    "homepage": {
        "title": "Search, book and have fun",
        "steps": {
            "title": "As simple as 1,2,3",
            "search": "Find a place",
            "book": "Book a court",
            "share": "Invite your friends"
        },
        "statistics": {
            "title": "Locations",
            "text": "The municipality of Aveiro has sports pavilions, publicly accessible spaces where you can play sports such as basketball, football, futsal, volleyball and handball, among others. Find a space that suits you and come and play sport.",
            "locations": "Available locations",
            "sports": "Different sports",
            "fun": "Endless fun"
        },
        "book": {
            "title": "Ready to start a game?",
            "bookNow": "Book now"
        },
        "advantages": {
            "title": "Advantages",
            "first_text": "Physical activity is good for the heart, body and mind. When done regularly, it helps reduce the incidence of heart disease and helps with symptoms of depression and anxiety.",
            "first_subtext": "WHO guidelines via fundacaodesporto.pt",
            "second_text": "Alternating between moderate and high-intensity sports helps to maximise the benefits of sport. There is a need to practise different types of sport.",
            "second_subtext": "WHO guidelines via fundacaodesporto.pt",
            "third_text": "A citizen's level of personal and social responsibility increases when there is a positive attitude towards competitive group sports.",
            "third_subtext": "Ul-FMH PhD dissertation"
        },
        "footer": {
            "courts": "Courts",
            "sports": "Sports",
            "tennis": "Tennis",
            "basketball": "Basketball",
            "soccer": "Soccer",
            "volleyball": "Volleyball",
            "padel": "Padel",
            "futsal": "Futsal",
            "help": "Help"
        }
    }
}
