import React, {FC, useState, ReactNode, createContext, useContext} from 'react';
import {SnackbarAlertProps} from '../components/Common/SnackbarAlert';
import {DialogMuiProps as ModalComponentProps} from '../components/Common/Modal/Modal';

export type ModalProps = ModalComponentProps | null;
export type SnackbarProps = SnackbarAlertProps | null;
export type CommonContextType = {
    setModal: (opts: ModalProps) => void;
    modal: ModalProps;
    setSnackbar: (opts: SnackbarProps) => void;
    snackbar: SnackbarProps;
    isShareButtonsVisible: boolean;
    setIsShareButtonsVisible: (isShareButtonsVisible: boolean) => void;
};

export const CommonContext = createContext<CommonContextType>({
    modal: null,
    setModal: () => {},
    snackbar: null,
    setSnackbar: () => {},
    isShareButtonsVisible: true,
    setIsShareButtonsVisible: () => {},
});

export type CommonContextProviderProps = {
    children?: ReactNode;
};

export const CommonContextProvider: FC<CommonContextProviderProps> = (props) => {
    const [modal, setModal] = useState<ModalProps>(null);
    const [snackbar, setSnackbar] = useState<SnackbarProps>(null);
    const [isShareButtonsVisible, setIsShareButtonsVisible] = useState<boolean>(true);

    const values = {
        setModal,
        modal,
        setSnackbar,
        snackbar,
        isShareButtonsVisible,
        setIsShareButtonsVisible,
    };

    return <CommonContext.Provider value={values}>{props.children}</CommonContext.Provider>;
};

export const useCommon = () => useContext(CommonContext);
