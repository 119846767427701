import React, {PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {SearchContextType} from '../types/SearchContext.type';
import {Category} from '../../../shared/types/Category.type';
import {SearchObjectType} from '../types/SearchObject.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {SearchResult, TimeInterval} from '../types/SearchResult.type';
import {useSearch} from '../hooks/useSearch';
import {useAuth} from '../../../hooks/useAuth';

dayjs.extend(utc);

const defaultSearchObject: SearchObjectType = {
    sport: [],
    district: 'Aveiro',
    date: dayjs.utc(),
    time: '',
    timeUnit: '',
    privacy: 'NOT_SPECIFIED',
    environment: 'NOT_SPECIFIED',
    clubName: [],
    domain: window.location.hostname,
};

export const SearchContext = createContext<SearchContextType>({
    isLoading: false,
    setIsLoading: () => undefined,
    isLoadingFilters: true,
    setIsLoadingFilters: () => undefined,
    categories: undefined,
    setCategories: () => undefined,
    clubNames: [],
    setClubNames: () => undefined,
    searchObj: defaultSearchObject,
    setSearchObj: () => undefined,
    results: undefined,
    setResults: () => undefined,
    isFirstSearchPerformed: false,
    setIsFirstSearchPerformed: () => undefined,
    timeInterval: undefined,
    setTimeInterval: () => undefined,
    pin: undefined,
    setPin: () => undefined,
});

export const SearchContextProvider: React.FC<PropsWithChildren> = (props) => {
    const dataFetchedRef = useRef(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
    const [categories, setCategories] = useState<Category[] | undefined>(undefined);
    const [clubNames, setClubNames] = useState<string[]>([]);
    const [isFirstSearchPerformed, setIsFirstSearchPerformed] = useState<boolean>(false);
    const [timeInterval, setTimeInterval] = useState<TimeInterval | undefined>(undefined);
    const [pin, setPin] = useState<string | undefined>();
    const {role} = useAuth();

    const [searchObj, setSearchObj] = useState<SearchObjectType>(defaultSearchObject);
    const [results, setResults] = useState<SearchResult[] | undefined>(undefined);

    const {
        actions: {fetchEverything},
    } = useSearch();

    const values = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            isLoadingFilters,
            setIsLoadingFilters,
            categories,
            setCategories,
            clubNames,
            setClubNames,
            searchObj,
            setSearchObj,
            results,
            setResults,
            isFirstSearchPerformed,
            setIsFirstSearchPerformed,
            timeInterval,
            setTimeInterval,
            pin,
            setPin,
        }),
        [
            isLoading,
            setIsLoading,
            isLoadingFilters,
            setIsLoadingFilters,
            categories,
            setCategories,
            clubNames,
            setClubNames,
            searchObj,
            setSearchObj,
            results,
            setResults,
            isFirstSearchPerformed,
            setIsFirstSearchPerformed,
            timeInterval,
            setTimeInterval,
            pin,
            setPin,
        ]
    );

    useEffect(() => {
        if (dataFetchedRef.current === role) {
            return;
        }
        if (!isLoadingFilters) {
            setIsLoadingFilters(true);
        }
        dataFetchedRef.current = role;
        fetchEverything(role === 'club-admin' ? 'private' : undefined).then(([categories, clubNames]) => {
            setCategories(categories as Category[]);
            setClubNames(clubNames as string[]);
            setIsLoadingFilters(false);
        });
    }, [role]);

    return <SearchContext.Provider value={values} {...props} />;
};

const useSearchContext = () => useContext(SearchContext);
export default useSearchContext;
