import React, {useContext} from 'react';
import {Alert, IconButton, Box, DialogActions, DialogContent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import noImage from '../../../../assets/images/noImage.svg';
import {useNavigate} from 'react-router-dom';
import {Spinner} from '../../../../components/Common/Spinner/Spinner';
import {
    CardContentStyled,
    ClubListCard,
    ClubListContainer,
    AreaCount,
    Image,
    ClubName,
    StyledBox,
    StyledAppButton,
    StyledGoBack,
    StyledRemoveRedEyeIcon,
    ImageBox,
} from './ClubsList.styles';
import {useClubs} from '../../../../hooks/useClubs';
import {AppContext} from '../../../../context/AppContext';
import {useCommon} from '../../../../context/CommonContext';
import {Club} from '../../../../shared/types/Club.type';
import {Delete, Edit} from '@mui/icons-material';
import {DeleteClubForm} from '../../../club/components/delete-club/DeleteClubForm';
import {ClubImageDefaultForm} from '../../club-details/components/ClubImageDefaultForm';

const ClubsList = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setModal} = useCommon();
    const {data: clubs, isLoading} = useClubs('private');
    //AppContext
    const appContext = useContext(AppContext);
    const appClub = appContext.club;

    const handleImageDefault = (clubId: string) => {
        setModal({
            open: true,
            children: (
                <>
                    <DialogContent dividers>
                        <ClubImageDefaultForm clubId={clubId} />
                    </DialogContent>
                    <DialogActions>
                        <StyledGoBack
                            id="cancel"
                            variant="outlined"
                            type="reset"
                            onClick={() => setModal({open: false, children: <></>})}
                        >
                            {t('back')}
                        </StyledGoBack>
                    </DialogActions>
                </>
            ),
            title: t('clubImageDefaultTitle'),
        });
    };

    return (
        <ClubListContainer>
            {clubs?.length! > 0 ? (
                isLoading ? (
                    <Spinner />
                ) : (
                    clubs?.map((club: Club) => {
                        return (
                            <ClubListCard isActive={club.id === appClub?.id} key={club.id}>
                                {club.photos && club.photos.length > 0 ? (
                                    <>
                                        {club.photos.map((photo: any, index: number) => {
                                            if (photo.isDefault) {
                                                return (
                                                    <ImageBox style={{position: 'relative'}}>
                                                        <Image
                                                            key={index}
                                                            src={photo.photoUrl}
                                                            alt=""
                                                            onClick={() => handleImageDefault(photo.clubId)}
                                                        />
                                                        <StyledRemoveRedEyeIcon fontSize="small" />
                                                    </ImageBox>
                                                );
                                            } else if (index === 0 && !club.photos.some((p: any) => p.isDefault)) {
                                                return <Image key={index} src={photo.photoUrl} alt="" />;
                                            }
                                            return null;
                                        })}
                                    </>
                                ) : (
                                    <Image src={noImage} alt="" />
                                )}

                                <CardContentStyled>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <ClubName>{club.name}</ClubName>
                                        <Box>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    setModal({
                                                        open: true,
                                                        children: <DeleteClubForm clubId={club.id} />,
                                                        title: t('club.message.deleteClubTitle', {
                                                            club: club.name,
                                                        }),
                                                    });
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                            <IconButton
                                                aria-label="edit"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    setModal({
                                                        open: false,
                                                        children: <></>,
                                                    });
                                                    appContext.setClub(club);
                                                    localStorage.setItem(
                                                        'clubAdmin.selectedClub',
                                                        JSON.stringify(club)
                                                    );
                                                    navigate(`/club-admin/club-details/${club.id}`);
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <AreaCount>
                                        {t('areasCount')}: {club.areas?.length}
                                    </AreaCount>
                                    <StyledBox>
                                        <StyledAppButton variant="outlined">{t('stop')}</StyledAppButton>
                                        <StyledAppButton variant="contained">{t('duplicate')}</StyledAppButton>
                                    </StyledBox>
                                </CardContentStyled>
                            </ClubListCard>
                        );
                    })
                )
            ) : (
                <Alert severity="info">{t('noClubInfo')}</Alert>
            )}
        </ClubListContainer>
    );
};

export default ClubsList;
