import React, {useEffect, useRef} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Divider, Stack} from '@mui/material';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
import GoogleIcon from '@mui/icons-material/Google';
import {Layout} from '../../../components/Common/Layout/Layout';
import {getURLWithReturn} from '../../../utils/returnUrl';
import {useTranslation} from 'react-i18next';
import {FormBox, ImageLogin, LoginFormBox, StyledAppButton, StyledBackgroundImage} from './Register.styles';
import {FeatureToggles} from '../../../shared/enums/FeatureToggles.enum';
import {FeatureToggleBaseComponent} from '../../../components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {LoginForm} from '../../../components/LoginForm/LoginForm';
import {useFeatureToggles} from '../../../hooks/useFeatureToggles';

const Login = () => {
    const {
        actions: {fetchDomainFeatureToggles},
    } = useFeatureToggles();
    const clientId = process.env.PLAYER_COGNITO_CLIENT_ID;
    const redirectUrL = process.env.REDIRECT_URL;
    const cognitoDomain = process.env.PLAYER_COGNITO_DOMAIN;
    const googleProvider = `${cognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUrL}&response_type=CODE&client_id=${clientId}&scope=aws.cognito.signin.user.admin email openid phone profile`;

    const navigate = useNavigate();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const return_url = searchParams.get('return_url');
    const isFeatureToggleReady = useRef(false);

    const handleGoogleLogin = () => {
        if (return_url) sessionStorage.setItem('google.returnURL', return_url);
    };

    useEffect(() => {
        if (isFeatureToggleReady.current) return;
        isFeatureToggleReady.current = true;
        fetchDomainFeatureToggles();
    }, []);

    return (
        <>
            <Layout maxWidth="sm">
                <FormBox>
                    <ImageLogin src="/logo_aveiro_tech_city_white.png" alt="FreeCourts: Courts for everyone" />

                    <LoginFormBox>
                        <FeatureToggleBaseComponent featureToggle={FeatureToggles.GOOGLE_SSO}>
                            <Link to={googleProvider} onClick={handleGoogleLogin}>
                                <StyledAppButton variant="outlined" color="white" startIcon={<GoogleIcon />}>
                                    {t('login.loginWithGoogle')}
                                </StyledAppButton>
                            </Link>
                            <Divider light>{t('or')}</Divider>
                        </FeatureToggleBaseComponent>

                        <LoginForm userType="player" redirect={return_url ? return_url : '/'} />

                        <AppButton
                            variant="outlined"
                            color="white"
                            onClick={() => navigate(getURLWithReturn('/signup', return_url))}
                        >
                            {t('login.register')}
                        </AppButton>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Link
                                to={
                                    !process.env.IS_LOCAL
                                        ? 'https://freecourts.pt/admin/login'
                                        : getURLWithReturn('/admin/login', return_url)
                                }
                            >
                                <AppButton variant="text" color="white" sx={{padding: 0}}>
                                    {t('login.clubAdminLogin')}
                                </AppButton>
                            </Link>
                            <Link to={getURLWithReturn('/login/admin', return_url)}>
                                <AppButton variant="text" color="white" sx={{padding: 0}}>
                                    {t('login.adminLogin')}
                                </AppButton>
                            </Link>
                        </Stack>
                    </LoginFormBox>
                </FormBox>
            </Layout>

            <StyledBackgroundImage src="/images/football.jpg" />
        </>
    );
};

export default Login;
