import styled from 'styled-components';
import {Map} from '../../../../components/Map/Map';
import {Loading} from '../loading/Loading';
import {Box} from '@mui/material';

export const StyledBox = styled(Box)`
    position: relative;
`;

export const StyledMap = styled(Map)`
    width: 100%;
    height: 40rem;
    margin-top: 4rem;

    @media (min-width: 1024px) {
        height: 100%;
        margin-top: unset;
    }
`;

export const LoadingContainer = styled(Box)`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const StyledLoading = styled(Loading)`
    position: absolute;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
`;
