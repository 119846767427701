import {Box, Stack} from '@mui/material';
import React, {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom';
import {PlayerMenu} from '../../PlayerMenu';
import {StyledIconButton, StyledSpan} from '../Layout/Layout.styles';

interface INavbarProps {
    hideMenu?: boolean;
}

const Navbar = (props: INavbarProps) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = () => setMenuOpen(true);

    return (
        <Box sx={{padding: '1rem'}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <StyledIconButton onClick={handleMenuOpen}>
                    <MenuIcon color="primary" style={{opacity: props.hideMenu ? 0 : 1}} />
                </StyledIconButton>
                <img
                    onClick={() => navigate('/')}
                    src="/logo_aveiro_tech_city.png"
                    alt="FreeCourts: Courts for everyone"
                    height={52}
                />
                <StyledSpan></StyledSpan>
            </Stack>
            <PlayerMenu open={menuOpen} onClose={() => setMenuOpen(false)} />
        </Box>
    );
};

export default Navbar;
