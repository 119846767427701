import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Avatar,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Stack,
    Typography,
    Skeleton,
    Drawer,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../../context/CommonContext';
import {DeleteClubForm} from '../../pages/club/components/delete-club/DeleteClubForm';
import {useFeatureToggles} from '../../hooks/useFeatureToggles';
import {FeatureToggles} from '../../shared/enums/FeatureToggles.enum';
import {FeatureToggleBaseComponent} from '../featureToggleBaseComponent/FeatureToggleBaseComponent';
import {useClubs} from '../../hooks/useClubs';
import {StyledIconButton, StyledSpan, StyledListItemText} from '../Common/Layout/Layout.styles';
import {AppContext} from '../../context/AppContext';
import useClubForm from '../../pages/club-admin/club-details/hooks/useClubForm';
import {Club} from '../../shared/types/Club.type';

export type ClubAdminMenuProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedClub: any;
    setSelectedClub: React.Dispatch<React.SetStateAction<any>>;
};

export const ClubAdminMenu = ({open, setOpen, selectedClub, setSelectedClub}: ClubAdminMenuProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setModal} = useCommon();
    const appContext = useContext(AppContext);
    const {
        actions: {fetchClubFeatureToggles},
    } = useFeatureToggles();
    const userRole = localStorage.getItem('role');
    const {setActiveStep} = useClubForm();
    const {data: clubs, isLoading} = useClubs('private');

    const clubMenuItems =
        isLoading || clubs.length > 0
            ? [
                  {
                      to: `/club-admin/club/${selectedClub?.id}/areas`,
                      str: t('clubAdmin.menu.clubAreas'),
                  },
                  {
                      to: `/club-admin/club-details`,
                      str: t('clubAdmin.menu.editClub'),
                  },
              ]
            : [{to: '/club-admin/club-details', str: t('clubAdmin.menu.createClub')}];

    useEffect(() => {
        if (selectedClub?.id !== undefined || selectedClub?.id !== '') {
            fetchClubFeatureToggles(selectedClub?.id);
        }

        return () => appContext.setHasClubChanged(false);
    }, [selectedClub, setSelectedClub]);

    const navigateTo = (to: string) => {
        navigate(to);
        setOpen(false);
    };

    const handleClose = () => setOpen(false);

    const handleChangeClub = (club: Club) => {
        if (selectedClub?.id === club?.id) return;
        setSelectedClub(club);
        localStorage.setItem('clubAdmin.selectedClub', JSON.stringify(club));
        appContext.setHasClubChanged(true);
        setActiveStep(0);
    };

    const handleNewClub = () => {
        localStorage.setItem('clubAdmin.selectedClub', JSON.stringify(null));
        setSelectedClub(null);
        navigate('/club-admin/club-details');
    };

    return (
        <Drawer anchor="left" variant="temporary" open={open} onClose={handleClose}>
            <Stack
                sx={{
                    padding: '1rem 0',
                }}
            >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <StyledIconButton onClick={handleClose}>
                        <ArrowBackIcon color="primary" />
                    </StyledIconButton>

                    <img
                        onClick={() => navigate('/')}
                        src="/logo_aveiro_tech_city.png"
                        alt="FreeCourts: Courts for everyone"
                        height={52}
                    />
                    <StyledSpan></StyledSpan>
                </Stack>

                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" mt="2rem" p="0 1rem">
                    {isLoading ? (
                        <>
                            <Skeleton width="100%" height={32} />
                            <Skeleton width="100%" height={24} />
                        </>
                    ) : (
                        <>
                            <Typography variant="h6">{t('clubAdmin.menu.selectedClub')}</Typography>
                            <Typography variant="body1">
                                {clubs.length > 0 ? selectedClub?.name : t('clubAdmin.menu.noClubs')}
                            </Typography>
                        </>
                    )}

                    <Stack
                        direction="row"
                        spacing={1}
                        mt={1}
                        pb={1}
                        width={280}
                        useFlexGap
                        sx={{
                            overflowX: 'auto',
                        }}
                    >
                        {isLoading ? (
                            [1, 2, 3, 4].map((value) => (
                                <Skeleton key={value} variant="circular" width={48} height={48} />
                            ))
                        ) : (
                            <>
                                <Avatar
                                    onClick={handleNewClub}
                                    sx={{
                                        width: 48,
                                        height: 48,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Typography variant="body1">+</Typography>
                                </Avatar>
                                {clubs.map((club: any) => {
                                    const name = club?.name.split(' ');
                                    const str = name.length > 1 ? name[0][0] + name[1][0] : name[0][0];
                                    const isActive = selectedClub?.id === club?.id;
                                    return (
                                        <Avatar
                                            key={club.id}
                                            onClick={() => handleChangeClub(club)}
                                            sx={{
                                                width: 48,
                                                height: 48,
                                                cursor: 'pointer',
                                                bgcolor: isActive ? '#1976d2' : 'auto',
                                                order: isActive ? 0 : 1,
                                            }}
                                        >
                                            <Typography variant="body1">{str}</Typography>
                                        </Avatar>
                                    );
                                })}
                            </>
                        )}
                    </Stack>
                </Stack>

                <MenuList sx={{marginTop: '1rem'}}>
                    <MenuItem onClick={() => navigateTo('/club-admin')}>
                        <ListItemText>{t('bookings')}</ListItemText>
                    </MenuItem>
                    {selectedClub !== null &&
                        clubMenuItems.map((item) => (
                            <MenuItem key={item.str} onClick={() => navigateTo(item.to)}>
                                <ListItemText>{item.str}</ListItemText>
                            </MenuItem>
                        ))}

                    <FeatureToggleBaseComponent featureToggle={FeatureToggles.RESERVATION_HISTORY}>
                        {selectedClub !== null && (
                            <MenuItem onClick={() => navigateTo(`/club-admin/club/${selectedClub?.id}/history`)}>
                                <ListItemText>{t('clubAdmin.menu.historyData')}</ListItemText>
                            </MenuItem>
                        )}
                    </FeatureToggleBaseComponent>

                    {selectedClub && (
                        <MenuItem
                            onClick={() => {
                                setModal({
                                    open: true,
                                    children: <DeleteClubForm clubId={selectedClub.id} />,
                                    title: t('club.message.deleteClubTitle', {club: selectedClub.name}),
                                });
                                setOpen(false);
                            }}
                        >
                            {' '}
                            <StyledListItemText>{t('clubAdmin.menu.deleteClub')}</StyledListItemText>
                        </MenuItem>
                    )}
                    {userRole === 'player' && (
                        <>
                            <Divider />
                            <MenuItem onClick={() => navigateTo('/club-admin/edit-account')}>
                                <ListItemText>{t('clubAdmin.menu.editAccount')}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/terms-and-conditions')}>
                                <ListItemText>{t('termsAndConditions')}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => navigateTo('/logout')} color="primary">
                                <ListItemIcon>
                                    <LogoutIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography color="primary">{t('clubAdmin.menu.logout')}</Typography>
                                </ListItemText>
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Stack>
        </Drawer>
    );
};
