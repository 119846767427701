import styled, {css} from 'styled-components';
import {Map} from '../../../../components/Map/Map';
import {Box} from '@mui/material';

export const Container = styled(Box)`
    max-width: 540px;
    margin: 2rem auto 2rem;
    width: calc(100% - 48px);

    @media (min-width: 1024px) {
        max-width: 1400px;
    }
`;

export const Header = styled.h3`
    margin-top: 3rem;
    display: flex;
    align-items: center;

    > img {
        margin-right: 0.5rem;
        transform: translateY(0.2rem);
    }
`;

export const StyledBox = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'variant',
})<{variant: string}>`
    ${({variant}) =>
        variant === 'small' &&
        css`
            font-size: 0.85rem;

            img {
                margin-right: 0.25rem;
                transform: translateY(0.2rem) scale(0.8);
            }
            ${Header} {
                font-size: 0.85rem;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
            }
        `}
`;

export const Description = styled.p`
    line-height: 1.5;
`;

export const FlexContainer = styled.div`
    gap: 1rem;
    display: grid;
    grid-template-columns: auto 30%;
`;

export const StyledMap = styled(Map)`
    width: 100%;
    height: 40rem;
`;

export const AddressDescription = styled.p`
    line-height: 1.5;
`;

export const ClubDescTab = styled(Box)`
    @media (min-width: 1024px) {
        display: flex;
        flex-wrap: wrap;

        > div {
            width: 50%;

            &:last-of-type,
            &:first-of-type {
                width: 100%;
            }
        }
    }
`;
