import {Accordion} from '@mui/material';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
    background-color: #fafafa !important;
    box-shadow: none !important;

    &::before {
        background-color: #fff !important;
    }
`;
