import React, {useState} from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {DialogActions, Stack, Typography} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Input} from '../Common/Input/TextField/Input';
import {AppService} from '../../services/app.service';
import {useAuth} from '../../hooks/useAuth';
import {AppButton} from '../Common/Buttons/AppButton';
import {ClubAdminFirstLogin} from '../ClubAdminFirstLogin';
import {useCommon} from '../../context/CommonContext';
import {validationLoginSchema} from '../../models/validationLoginSchema.model';
import {StyledAppButton} from './LoginForm.styles';
import {StyledDialogContent} from '../Common/Navbar/Navbar.styles';
import {RecoverPassword} from '../RecoverPassword/RecoverPassword';

type LoginFormProps = {
    userType: 'player' | 'club-admin' | 'admin';
    redirect?: string;
};

export const LoginForm = ({userType, redirect = '/'}: LoginFormProps) => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const {setSnackbar, setModal} = useCommon();

    const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(
        searchParams.get('action') === 'recover-password'
    );
    const [firstLoginModalOpen, setFirstLoginModalOpen] = useState(false);

    const handleSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        new AppService()
            .api_login(userType, values)
            .then((res) => {
                if (res.status !== 200) throw res;

                if (userType === 'club-admin' && res.data === 'changePasswordRequired') {
                    setFirstLoginModalOpen(true);
                    return;
                }

                login(res.data, userType);

                navigate(redirect);
            })
            .catch((error) => {
                console.log(error);
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t('login.error.wrongEmailOrPassword'),
                });
            })
            .finally(() => formikHelpers.setSubmitting(false));
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationLoginSchema(t),
        onSubmit: handleSubmit,
    });

    const handleForgotPasswordClick = () => {
        if (!!formik.errors.email || formik.values.email === formik.initialValues.email) {
            formik.setFieldTouched('email');
            formik.setFieldError('email', t('login.error.invalidEmail'));
            return;
        }

        new AppService()
            .api_requestCodeForResetPassword(userType, {email: formik.values.email})
            .then((res) => {
                if (res.status !== 200) throw new Error(res);

                setRecoverPasswordModalOpen(true);
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'success',
                    children: t('recoverPassword.emailSent'),
                });
            })
            .catch((error) => {
                console.log(error);
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t('recoverPassword.error.emailNotSent'),
                });
            });
    };

    const handleFirstLoginClose = () => {
        formik.setFieldTouched('password', false);
        formik.setFieldValue('password', '');
        setFirstLoginModalOpen(false);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                    <Input
                        name="email"
                        label={t('email')}
                        type="text"
                        variant="filled"
                        required
                        autoComplete="off"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && !!formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <Typography color="common.white" variant="body2" sx={{transform: 'translateY(-8px)'}}>
                            {formik.errors.email}
                        </Typography>
                    )}
                    <Input
                        name="password"
                        label={t('password')}
                        type="password"
                        variant="filled"
                        required
                        autoComplete="off"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && !!formik.errors.password}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <Typography color="common.white" variant="body2" sx={{transform: 'translateY(-8px)'}}>
                            {formik.errors.password}
                        </Typography>
                    )}
                    <StyledAppButton
                        variant="text"
                        onClick={() => {
                            handleForgotPasswordClick();
                            setModal({
                                open: true,
                                title: t('recoverPassword.title'),
                                children: (
                                    <>
                                        <StyledDialogContent dividers>
                                            <RecoverPassword email={formik.values.email} role={userType} />
                                        </StyledDialogContent>
                                        <DialogActions>
                                            <AppButton
                                                id="cancel"
                                                variant="outlined"
                                                type="reset"
                                                sx={{width: '100%'}}
                                                onClick={() => setModal({open: false, children: <></>})}
                                            >
                                                {t('cancel')}
                                            </AppButton>
                                            <AppButton
                                                variant="contained"
                                                type="submit"
                                                form="form-resetPassword"
                                                sx={{width: '100%'}}
                                            >
                                                {t('recoverPassword.submitButton')}
                                            </AppButton>
                                        </DialogActions>
                                    </>
                                ),
                            });
                        }}
                        isColorBlack={window.location.pathname.includes('checkout')}
                    >
                        {t('login.forgotPassword')}
                    </StyledAppButton>
                    <AppButton variant="contained" type="submit" disabled={formik.isSubmitting}>
                        {t('login.login')}
                    </AppButton>
                </Stack>
            </form>

            {userType === 'club-admin' && (
                <ClubAdminFirstLogin
                    open={firstLoginModalOpen}
                    onClose={handleFirstLoginClose}
                    email={formik.values.email}
                    password={formik.values.password}
                />
            )}
        </>
    );
};
