import {Accordion, Box, Typography} from '@mui/material';
import styled from 'styled-components';

export const DialogTitle = styled(Typography)`
    font-size: 20px !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;
    line-height: 32px !important;
`;

export const StyledAccordion = styled(Accordion)`
    background-color: #fafafa !important;
    box-shadow: none !important;

    &::before {
        background-color: #fff !important;
    }
`;

export const StyledDialogContainer = styled(Box)`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
