import React from 'react';
import {Layout} from '../../components/Common/Layout/Layout';
import {AvailableSlots} from './components/AvailableSlots';

export const AvailableSlotsPage = () => {
    return (
        <Layout headerType="menu" maxWidth="sm">
            <AvailableSlots />
        </Layout>
    );
};
