import React, {useContext, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {ClubAdminMenu} from '../../ClubAdminMenu';
import {AppContext} from '../../../context/AppContext';
import {StyledIconButton, MUIMenuIcon, Image} from '../Layout/Layout.styles';
import {Theme, useMediaQuery, useTheme} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {UserMenu} from './UserMenu';
import {TopMenu} from './TopMenu';
import {StyledMenuBox, StyledName} from './Navbar.styles';
import {useNavigate} from 'react-router-dom';

interface INavbarProps {
    hideMenu?: boolean;
    hideClubIcon?: boolean;
}
const AdminNavbar = ({hideMenu = false, hideClubIcon = false}: INavbarProps) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const appClub = appContext.club;
    const showTopMenu = window.location.pathname;
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const clubName: string[] | undefined = appClub?.name?.split(' ');
    const clubInitials =
        clubName && clubName?.length > 1 ? `${clubName[0][0]}${clubName[1][0]}` : appClub?.name ? appClub?.name[0] : '';

    const handleMenuOpen = () => setMenuOpen(true);

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" sx={{boxShadow: 'var(--default-boxShadow)'}}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    {hideMenu ? (
                        <StyledIconButton>
                            <MUIMenuIcon />
                        </StyledIconButton>
                    ) : (
                        <StyledMenuBox>
                            <StyledIconButton onClick={handleMenuOpen}>
                                <MUIMenuIcon />
                            </StyledIconButton>
                            <StyledName>{isLargeScreen ? appClub?.name : clubInitials}</StyledName>
                        </StyledMenuBox>
                    )}
                    <Image src="/logo_aveiro_tech_city_white.png" alt="FreeCourts: Courts for everyone" />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {!showTopMenu.includes('/search') && <SearchIcon onClick={() => navigate('/search')} />}
                        <UserMenu />
                    </Box>
                </Toolbar>
                <TopMenu selectedClub={appClub} />
            </AppBar>
            <ClubAdminMenu
                open={menuOpen}
                setOpen={setMenuOpen}
                selectedClub={appClub}
                setSelectedClub={appContext.setClub}
            />
        </Box>
    );
};

export default AdminNavbar;
