import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useReservation} from '../../hooks/useReservation';
import {Alert, Stack} from '@mui/material';
import {BookingDetails} from '../bookingDetails/BookingDetails';
import {StyledAlert} from '../createBooking/Booking.styles';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {BookingCancelModal} from '../../../../components/BookingCancelModal/BookingCancelModal';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {
    StyledBox,
    Title,
    PaymentDetails,
    ImportantPaymentDetail,
    StyledCard,
    StyledCardLabel,
    ContentLabel,
    LabelDescription,
    ActionContainer,
    StyledAppButton,
} from './BookingConfirmation.styles';
import {QRCode} from '../../../../components/qrCode/QrCode';

export const BookingConfirmation = () => {
    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {
        isLoading,
        booking,
        actions: {fetch_booking, setAsDeleted},
    } = useReservation();

    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    useEffect(() => {
        if (!id) {
            navigate('/');
            return;
        }

        fetch_booking(id);
    }, []);

    if (isLoading || !booking) {
        return null;
    }

    const formattedDate = dayjs(booking.day).locale(i18n.language).utc().format('ddd, MMM D, YYYY');
    const from = dayjs(booking.from).utc().format('HH:mm');
    const to = dayjs(booking.to).utc().format('HH:mm');

    return (
        <Layout headerType="menu" maxWidth="sm">
            <StyledBox sx={{marginBottom: '2rem'}}>
                <Title>
                    {booking.area.privacy === 'PUBLIC' ||
                    booking.isPayed ||
                    booking.paymentMethod?.name !== 'BANK_TRANSFER'
                        ? t('booking.details', {bookingId: booking.shareId})
                        : t('booking.payment')}
                </Title>
                <Stack>
                    {booking.isPayed && <Alert severity="success">{t('booking.label.paymentConfirmed')}</Alert>}
                    {booking.paymentMethod?.name === 'BANK_TRANSFER' && !booking.isPayed && (
                        <StyledAlert severity="warning">
                            <PaymentDetails>
                                {t('booking.label.transferObservation')}
                                <strong>#{booking.shareId}</strong>
                            </PaymentDetails>
                            <br />
                            <PaymentDetails>
                                {t('iban')} {booking.club.iban ? booking.club.iban : t('missingInfo')}
                                <br />
                                {t('bic')} {booking.club.swift ? booking.club.swift : t('missingInfo')}
                                <br />
                                {t('email')}{' '}
                                {booking.club.email ? (
                                    <Link
                                        to={`mailto:${booking.club.email}?subject=Comprovativo de pagamento da reserva #${booking.shareId}`}
                                    >
                                        {booking.club.email}
                                        <CallMadeIcon fontSize="small" className="p-2" />
                                    </Link>
                                ) : (
                                    t('missingInfo')
                                )}
                            </PaymentDetails>
                            <br />
                            <ImportantPaymentDetail>
                                <strong>{t('booking.label.paymentNote')}</strong>
                            </ImportantPaymentDetail>{' '}
                            <br />
                            <ImportantPaymentDetail>
                                <strong>{t('booking.label.reservationNote')}</strong>
                            </ImportantPaymentDetail>
                        </StyledAlert>
                    )}
                </Stack>
                <BookingDetails
                    club={{
                        name: booking.club.name,
                        mobilePhone: booking.club.mobilePhone,
                        email: booking.club.email,
                        carParkType: booking.club.carParkType,
                        lockerRoomType: booking.club.lockerRoomType,
                        address: {
                            address: booking.club.address?.address,
                            postalCode: booking.club.address?.postalCode,
                            district: booking.club.address?.district,
                            lat: booking.club.address?.lat,
                            lng: booking.club.address?.lng,
                        },
                        photos: booking.club.photos.map((photo: any) => ({
                            photoUrl: photo.photoUrl,
                        })),
                    }}
                    area={{
                        name: booking.area.name,
                        description: booking.area.description,
                        price: booking.totalPrice || booking.price,
                        privacy: booking.area.privacy,
                        environment: booking.area.environment,
                        photos: booking.area.photos.map((photo: any) => ({
                            photoUrl: photo.photoUrl,
                        })),
                    }}
                    category={booking.category}
                    date={formattedDate!}
                    from={from!}
                    to={to!}
                />
                {booking.paymentMethod && (
                    <StyledCard>
                        <StyledCardLabel>{t('booking.label.paymentMethods')}</StyledCardLabel>
                        <LabelDescription>
                            {t(`club.paymentMethods.${booking.paymentMethod?.name.toLowerCase()}`)}
                        </LabelDescription>
                    </StyledCard>
                )}
                <StyledCard>
                    <StyledCardLabel>{t('booking.userInfo')}</StyledCardLabel>
                    <ContentLabel>{t('booking.label.name')}</ContentLabel>
                    <LabelDescription>{booking.name}</LabelDescription>
                    <ContentLabel>{t('email')}</ContentLabel>
                    <LabelDescription>{booking.email}</LabelDescription>
                </StyledCard>
                {!booking?.canceled ? (
                    <ActionContainer>
                        <QRCode
                            value={`https://${window.location.host}/booking/share/${booking.shareId}`}
                            options={{
                                height: 135,
                                width: 138,
                            }}
                        />
                        <StyledAppButton
                            variant="contained"
                            onClick={() => navigate(`/booking/share/${booking.shareId}?action=share`)}
                        >
                            {t('booking.button.shareBooking')}
                        </StyledAppButton>
                        <StyledAppButton variant="outlined" onClick={() => navigate('/player/bookings')}>
                            {t('booking.bookings')}{' '}
                        </StyledAppButton>
                        <StyledAppButton variant="contained" color="error" onClick={() => setCancelModalOpen(true)}>
                            {t('cancelBooking')}
                        </StyledAppButton>
                        <BookingCancelModal
                            booking={booking}
                            open={cancelModalOpen}
                            onClose={() => setCancelModalOpen(false)}
                            onDelete={setAsDeleted}
                        />
                    </ActionContainer>
                ) : (
                    <StyledAppButton variant="contained" disabled>
                        {t('canceled')}
                    </StyledAppButton>
                )}
            </StyledBox>
        </Layout>
    );
};
