import styled from 'styled-components';
import {Box, IconButton, ListItemText} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const StyledBox = styled(Box)`
    display: flex;
    @media (max-width: 600px) {
        flex-direction: column;
    },
`;

export const StyledStack = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledSpan = styled.span`
    width: 40px;
    height: 40px;
`;

export const StyledIconButton = styled(IconButton)`
    width: 50px !important;
    height: 50px !important;
`;

export const MUIMenuIcon = styled(MenuIcon)`
    color: #fff;
`;

export const StyledListItemText = styled(ListItemText)`
    color: #ff5252;
`;

export const Image = styled.img`
    height: 45px;
    @media (max-width: 600px) {
        height: 25px;
    },
`;
