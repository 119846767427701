import {Box} from '@mui/material';
import styled, {css} from 'styled-components';

export const ClubLocation = styled(Box)`
    padding: 0 1rem 0rem 1rem;
    margin-top: 1.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    overflow: hidden;

    &:first-of-type {
        margin-top: 0.5rem;
    }

    @media (min-width: 1024px) {
        &:first-of-type {
            margin-top: unset;
        }
        margin-top: unset;
        box-shadow: unset;
        border-radius: unset;
        &:not(:last-of-type) {
            border-bottom: 1px solid;
        }
    }
`;

export const ClubInfo = styled(Box)`
    padding: 0.5rem 0.5rem 0;
`;

export const ClubImageContainer = styled.div`
    margin: -0.5rem -1.5rem 1rem -1.5rem;
`;

export const ClubImage = styled.img`
    height: 8rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const Name = styled.h5`
    margin: 0;
    cursor: pointer;
    font-size: 0.9rem;

    &:hover {
        text-decoration: underline;
    }
`;

export const Description = styled.p`
    color: grey;
    margin: 0.5rem 0 1rem;
    font-size: 0.8em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    img {
        transform: translateY(0.2rem);
        margin-right: 0.5rem;
        filter: contrast(0.1);
    }
`;

export const Area = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'disabled',
})<{disabled: boolean}>`
    display: flex;
    padding: 1rem 0;
    border-bottom: solid 1px #ccc;

    &:last-of-type {
        border-bottom: unset;
    }

    ${({disabled}) =>
        disabled &&
        css`
            position: relative;

            ${Name} {
                z-index: 1;
                opacity: 0.7;
                position: relative;
            }

            &:after {
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                opacity: 0.5;
                display: block;
                position: absolute;
                background-color: white;
            }
        `}
`;

export const AreaImageContainer = styled(Box)`
    width: 30%;
    height: inherit;

    @media (max-width: 425px) {
        display: none;
    }
`;

export const AreaImage = styled.img`
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
`;

export const AreaInfo = styled(Box)`
    flex: 1;
    display: flex;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    flex-direction: column;

    @media (max-width: 425px) {
        padding-left: 0;
    }
`;

export const EmptyListMessage = styled.span`
    display: block;
    margin: 0 1rem;
    font-weight: 600;
`;
