import {Box, Card} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';

export const Title = styled.h1`
    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--custom-secondary);
    text-transform: uppercase;
`;

export const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 1rem;
    margin-bottom: 20px;

     @media (max-width: 600px) {
        padding: 0;
    },
`;

export const PaymentDetails = styled.p`
    font-size: 14px;
    margin: 0;
`;

export const ImportantPaymentDetail = styled.p`
    font-size: 14px;
    margin: 0;
    color: #e65100;
`;

export const StyledCard = styled(Card)`
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledCardLabel = styled.h2`
    color: var(--black-087);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
`;

export const ContentLabel = styled.h3`
    color: var(--black-087);
    font-size: 16px;
    font-style: medium;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const LabelDescription = styled.p`
    color: var(--black-058);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    margin-top: 32px;
`;

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;
