import {useRef, useEffect, useCallback, useState} from 'react';
import {useUserContext} from '../contexts/UserContext';
import {AppService} from '../../../services/app.service';
import {Photo} from '../../../models/clubDetails.model';
import {useCommon} from '../../../context/CommonContext';
import {useTranslation} from 'react-i18next';
import {UserUpdate} from '../../../models/user.model';

export const useUser = () => {
    const appService = new AppService();
    const {setSnackbar} = useCommon();
    const {t} = useTranslation();
    const [error, setError] = useState<boolean>(false);
    const {user, setUser, setIsLoading, isLoading} = useUserContext();
    const isReady = useRef(false);

    const getUser = useCallback(async () => {
        await appService
            .api_getUser()
            .then((res) => {
                if (res.status !== 200) throw new Error();
                if (res.data) {
                    setUser(res.data);
                }
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, [setUser, appService, setIsLoading]);

    const updateUser = useCallback(
        async (user: UserUpdate) => {
            const newUser = user;
            if (newUser.photoUrl !== 'undefined') {
                delete newUser.photoUrl;
            }

            await appService
                .api_updateUser(user)
                .then((res) => {
                    if (res.status !== 200) throw new Error();
                    if (res.status === 200) {
                        setError(false);
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('editAccount.success.userUpdate'),
                        });
                        getUser();
                    } else {
                        setError(true);
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('editAccount.error.userUpdate'),
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
        },
        [getUser, appService, setIsLoading, setSnackbar, setError, t]
    );

    useEffect(() => {
        if (isReady.current) return;
        isReady.current = true;
        getUser();
        setIsLoading(false);
    }, [user, getUser, setIsLoading]);

    return {user, isLoading, setUser, updateUser};
};

export default useUser;
