import React, {useRef} from 'react';
import {AccordionDetails, AccordionSummary, Box, Dialog, Divider, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Input} from '../../../../../components/Common/Input/TextField/Input';
import {AppButton} from '../../../../../components/Common/Buttons/AppButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {StyledDialogContainer, DialogTitle, StyledAccordion} from './HistoryDataModal.styles';
import {HistoryDataType} from '../../types/HistoryData.types';

dayjs.extend(utc);

export type HistoryDataModalProps = {
    open: boolean;
    historyData: HistoryDataType;
    onClose: () => void;
};

export const HistoryDataModal = ({open, historyData, onClose}: HistoryDataModalProps) => {
    const {t} = useTranslation();

    const submitting = useRef(false);
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <StyledDialogContainer>
                <DialogTitle>{`${t('clubAdmin.bookings.modal.title')} ${historyData.shareId}`}</DialogTitle>
                <Input name="area" type="text" disabled label={historyData.area?.name} />
                <Input name="category" type="text" disabled label={historyData?.category} />
                <Input
                    name="name"
                    type="text"
                    disabled
                    hasLabelTop={true}
                    value={historyData.name}
                    labelTop={t('clubAdmin.bookings.modal.clientName')}
                />
                <Input
                    name="email"
                    type="text"
                    disabled
                    value={historyData.email}
                    hasLabelTop={true}
                    labelTop={t('clubAdmin.bookings.modal.clientEmail')}
                />
                <Input
                    name="mobilePhone"
                    type="text"
                    disabled
                    value={historyData.mobilePhone}
                    hasLabelTop={true}
                    labelTop={t('clubAdmin.bookings.modal.mobilePhone')}
                />
                <Input
                    name="day"
                    type="text"
                    disabled
                    hasLabelTop={true}
                    labelTop={t('clubAdmin.bookings.modal.day')}
                    value={dayjs(historyData.day).format('DD-MM-YYYY')}
                />
                <Input
                    name="day"
                    type="text"
                    disabled
                    hasLabelTop={true}
                    labelTop={t('clubAdmin.bookings.modal.time')}
                    value={`${dayjs(historyData.from).utc().format('HH:mm')} - ${dayjs(historyData.to)
                        .utc()
                        .format('HH:mm')}`}
                />

                <Divider light />
                <StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>{t('clubAdmin.historyData.accordionTitle')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Typography variant="caption">
                                {t('clubAdmin.historyData.createdBy')} <b>{historyData.CreatedBy?.name}</b>{' '}
                                {t('clubAdmin.historyData.at')}{' '}
                                <b>{dayjs(historyData.createdAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                            </Typography>
                            {historyData.isPayed && (
                                <Box>
                                    <Typography variant="caption">
                                        {/* TODO: Add to history endpoint the information about payment method selected */}
                                        {t('clubAdmin.historyData.paidBy')} <b>{historyData?.name}</b> por MBWay{' '}
                                        {t('clubAdmin.historyData.at')}{' '}
                                        <b>{dayjs(historyData.payedAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                                    </Typography>
                                </Box>
                            )}
                            {historyData.canceled && (
                                <Box>
                                    <Typography variant="caption">
                                        {t('clubAdmin.historyData.canceledBy')} <b>{historyData?.CanceledBy.name}</b>{' '}
                                        {t('clubAdmin.historyData.at')}{' '}
                                        <b>{dayjs(historyData.canceledAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </StyledAccordion>

                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <AppButton variant="outlined" disabled={submitting.current} onClick={onClose}>
                        {t('back')}
                    </AppButton>
                </Box>
            </StyledDialogContainer>
        </Dialog>
    );
};
