import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import useHomePageContext from '../../context/HomePageContect';
import useSearchContext from '../../../search-page/contexts/SearchContext';

import {mountQueryString} from '../../utils/mountQueryString';

import {Main} from '../main/Main';
import {Steps} from '../steps/Steps';
import {Locations} from '../locations/Locations';

import {
    StyledLayout,
    BookNowBox,
    BookNowTitle,
    BookNowButtons,
    Footer,
    InnerFooter,
    FooterColumn,
} from './HomePage.styles';
import {Advantages} from '../advantages/Advantages';

const HomePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {clubs} = useHomePageContext();
    const {categories, clubNames} = useSearchContext();

    const navigateWithQueryString = (inputParams: Record<string, string> = {}) => {
        const queryString = mountQueryString(inputParams);
        navigate(`/search${queryString}`);
    };

    return (
        <StyledLayout headerType="menu" maxWidth={false}>
            <Main />
            <Steps />
            <Locations
                numberofClubs={clubNames?.length}
                numberOfSports={categories?.length ?? 0}
                highlightedClubs={clubs}
            />
            <BookNowBox>
                <div>
                    <BookNowTitle>{t(`homepage.book.title`)}</BookNowTitle>
                    <BookNowButtons>
                        <button onClick={() => navigateWithQueryString({})}>{t(`homepage.book.bookNow`)}</button>
                    </BookNowButtons>
                </div>
            </BookNowBox>
            <Advantages />
            <Footer>
                <InnerFooter>
                    <FooterColumn>
                        <p>{t(`homepage.footer.courts`)}</p>
                        <a onClick={() => navigateWithQueryString({sport: 'tenis'})}>{t(`homepage.footer.tennis`)}</a>
                        <a onClick={() => navigateWithQueryString({sport: 'basquetebol'})}>
                            {t(`homepage.footer.basketball`)}
                        </a>
                        <a onClick={() => navigateWithQueryString({sport: 'futebol'})}>{t(`homepage.footer.soccer`)}</a>
                    </FooterColumn>
                    <FooterColumn>
                        <p>{t(`homepage.footer.sports`)}</p>
                        <a onClick={() => navigateWithQueryString({sport: 'voleibol'})}>
                            {t(`homepage.footer.volleyball`)}
                        </a>
                        <a onClick={() => navigateWithQueryString({sport: 'padel'})}>{t(`homepage.footer.padel`)}</a>
                        <a onClick={() => navigateWithQueryString({sport: 'futsal'})}>{t(`homepage.footer.futsal`)}</a>
                    </FooterColumn>
                    <FooterColumn>
                        <p>{t(`homepage.footer.help`)}</p>
                        <a onClick={() => (window.location.href = `mailto:desporto@cm-aveiro.pt?subject=Contacto`)}>
                            {t(`contact`)}
                        </a>
                    </FooterColumn>
                </InnerFooter>
            </Footer>
        </StyledLayout>
    );
};

export default HomePage;
