import {Chip, Paper, Box} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
export const ShareAvailableSlotsDescription = styled.p`
    color: var(--custom-secondary);
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    padding: 0 16px;
`;
export const ShareAvailableSlotsClub = styled.p`
    color: var(--color-primary);
    background: rgba(25, 118, 210, 0.12);
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 6px;
}`;
export const ShareAvailableSlotsAreaName = styled.p`
    color: var(--custom-secondary);
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    padding: 6px;
`;
export const ShareAvailableSlotsChip = styled(Chip)`
    font-size: 12px !important;
`;
export const ShareAvailableSlotsContainer = styled(Paper)`
    margin: 16px;
    margin-bottom: 0;
    border-radius: 0;
`;
export const ShareAvailableSlotsChipBox = styled(Box)`
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    padding: 6px;
`;
export const ShareAvailableSlotsAreaRow = styled(Box)`
    display: grid;
    grid-template-columns: 30% auto;
    align-items: center;
`;
export const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const NoSchedulesErrorMessage = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
`;
export const StyledAppButton = styled(AppButton)`
    margin: 16px !important;
`;
