import {useRef, useEffect, useCallback, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useClubs} from '../contexts/ClubsContext';
import {useRole} from '../../../../hooks/useRole';
import {AppService} from '../../../../services/app.service';
import {useCommon} from '../../../../context/CommonContext';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../../../context/AppContext';

export const useClubForm = () => {
    const role = useRole();
    const navigate = useNavigate();
    const {activeStep, setActiveStep, setIsLoading, isLoading} = useClubs();
    const {setSnackbar} = useCommon();
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const {t} = useTranslation();
    const isReady = useRef(false);

    //AppContext
    const appContext = useContext(AppContext);
    const clubContext = appContext.club;

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const getClubById = useCallback(async () => {
        const appService = new AppService();
        if (clubContext) {
            await appService
                .api_getClubById(role, clubContext?.id!)
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem('clubAdmin.selectedClub', JSON.stringify(res.data));
                        appContext.setClub(res.data);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    navigate('/club-admin/club-details');
                });
        }
    }, [role, setIsLoading, navigate, appContext, clubContext]);

    const createClubContact = useCallback(
        async (formData: any) => {
            const appService = new AppService();
            await appService
                .api_createClubContact(clubContext?.id!, formData)
                .then((res) => {
                    if (res.status !== 201) throw new Error(res);
                    if (res.status === 201) {
                        getClubById();
                        setIsFormVisible(false);
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('clubDetails.contacts.message.success'),
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsFormVisible(false);
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t('clubDetails.contacts.message.error'),
                    });
                });
        },
        [clubContext, setIsLoading, getClubById, setIsFormVisible, setSnackbar, t]
    );

    const deleteClubContact = useCallback(
        async (contactId: string) => {
            const appService = new AppService();
            await appService
                .api_deleteClubContact(contactId)
                .then((res) => {
                    if (res.status !== 200) throw new Error(res);
                    if (res.status === 200) {
                        getClubById();
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('clubDetails.contacts.message.deleteSuccess'),
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t('clubDetails.contacts.message.error'),
                    });
                });
        },
        [setIsLoading, getClubById, setSnackbar, t]
    );

    const updateClubContact = useCallback(
        async (contactId: string, formData: any) => {
            const appService = new AppService();
            await appService
                .api_updateClubContact(contactId, formData)
                .then((res) => {
                    if (res.status !== 200) throw new Error(res);
                    if (res.status === 200) {
                        setIsFormVisible(false);
                        getClubById();
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('clubDetails.contacts.message.updateSuccess'),
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t('clubDetails.contacts.message.error'),
                    });
                });
        },
        [setIsLoading, getClubById, setSnackbar, t]
    );

    useEffect(() => {
        if (isReady.current) return;
        isReady.current = true;
        getClubById();

        setIsLoading(false);
    }, [clubContext, getClubById, setIsLoading]);

    return {
        isLoading,
        activeStep,
        handleNext,
        handleBack,
        setActiveStep,
        createClubContact,
        isFormVisible,
        setIsFormVisible,
        deleteClubContact,
        updateClubContact,
        getClubById,
    };
};

export default useClubForm;
