import {Dayjs} from 'dayjs';

export function getCurrentWeekDates(today: Date) {
    const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the start and end dates of the current week
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - currentDay + 1); // Set to Monday of the current week

    const endDate = new Date(today);
    endDate.setDate(today.getDate() + (6 - currentDay) + 1); // Set to Sunday of the current week

    return {startDate, endDate};
}

export const timeUnitToTimeString = (key: string) => {
    const times = {
        TRINTA_MINUTOS: '00:30',
        UMA_HORA: '01:00',
        UMA_HORA_TRINTA_MINUTOS: '01:30',
        DUAS_HORA: '02:00',
    };
    return times[key as keyof typeof times];
};

export const timeStringToMinutes = (key: string) => {
    const times = {
        '00:30': 30,
        '01:00': 60,
        '01:30': 90,
        '02:00': 120,
    };
    return times[key as keyof typeof times];
};

export const formatDate = (date: Dayjs): string => {
    return `${date.format('YYYY-MM-DD HH:mm:ss').split(' ').join('T')}Z`;
};

export const weekDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
export const timeUnit = ['TRINTA_MINUTOS', 'UMA_HORA', 'UMA_HORA_TRINTA_MINUTOS', 'DUAS_HORA'];
