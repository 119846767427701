import {Box} from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;

    div {
        gap: 0.25rem;
        display: flex;
        align-items: center;
        span {
            font-size: 0.9rem;
            text-transform: capitalize;
        }
    }
`;
