import {useTranslation} from 'react-i18next';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, TextField, Typography} from '@mui/material';

import {useSearch} from '../../hooks/useSearch';
import useSearchContext from '../../contexts/SearchContext';

import {privacies, environments} from '../../utils/constants';
import {checkIfFieldsAreValidAndReturnError} from '../../utils/checkValid';

import {HourAndDateForm} from '../hour-and-date-form/HourAndDateForm';

import {
    DesktopLabel,
    MobileLabel,
    StyledAppButton,
    StyledAutocomplete,
    StyledBox,
    StyledForm,
    StyledSelect,
} from './SearchEngine.styles';
import SearchIcon from '../../../../assets/icons/search.svg';
import {PropTypes} from './SearchEngine.types';
import {SportIcon} from '../../../../components/SportIcon/SportIcon';
import {Category} from '../../../../shared/types/Category.type';

export const SearchEngine: FC<PropTypes> = ({onSearch, ...remainingProps}) => {
    const checkQueryStringRef = useRef(false);
    const {searchObj, categories, clubNames, isLoadingFilters} = useSearchContext();
    const [error, setError] = useState<Record<string, string> | undefined>(undefined);

    const {t} = useTranslation();
    const {
        actions: {search, checkQueryString, handleInputChange},
    } = useSearch();

    useEffect(() => {
        if (isLoadingFilters || checkQueryStringRef.current) return;
        checkQueryStringRef.current = true;
        checkQueryString();
    }, [isLoadingFilters]);

    const handleSubmit = useCallback(() => {
        setError(undefined);
        const error = checkIfFieldsAreValidAndReturnError(searchObj);
        if (error) {
            error.message = t('search.errors.isRequired', {field: t(`search.fields.${error.field}`)});
            setError(error);
            return;
        }
        onSearch?.();
        search(searchObj);
    }, [searchObj]);

    if (isLoadingFilters) {
        return null;
    }

    return (
        <StyledBox {...remainingProps}>
            <StyledForm>
                <FormControl error={error?.field === 'sport'} required fullWidth style={{minWidth: 300}}>
                    <StyledAutocomplete
                        multiple
                        id="search-sport"
                        noOptionsText={t('search.labels.noSport')}
                        options={categories?.map((category) => category) as string[]}
                        filterSelectedOptions
                        value={searchObj.sport}
                        getOptionLabel={(option: any) => t(`search.sports.${option.toLowerCase()}`)}
                        renderOption={(props, option: any) => {
                            const category =
                                categories &&
                                categories.find((category) => category.toLocaleLowerCase() === option.toLowerCase());
                            return (
                                <Box
                                    component="li"
                                    flexDirection={'row'}
                                    flex={1}
                                    display={'flex'}
                                    key={option}
                                    {...props}
                                >
                                    <SportIcon category={category as Category} />
                                    <Typography style={{marginLeft: 16}}>
                                        {t(`search.sports.${option.toLowerCase()}`)}
                                    </Typography>
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('search.labels.sport')}
                                placeholder={t('search.labels.sport')}
                            />
                        )}
                        onChange={(e, values) => {
                            handleInputChange('sport', values as string[]);
                        }}
                    />
                    {error?.field === 'sport' && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
                <HourAndDateForm error={error} />
                <FormControl>
                    <InputLabel id="search-privacy">{t('privacy')}</InputLabel>
                    <StyledSelect
                        variant="outlined"
                        value={searchObj.privacy}
                        labelId="search-privacy"
                        name="privacy"
                        label={t('privacy')}
                        onChange={({target: {name, value}}) => handleInputChange(name, value as string)}
                    >
                        {privacies.map((privacy) => (
                            <MenuItem key={`privacy-${privacy}`} value={privacy}>
                                {t(`${privacy.toLowerCase()}`)}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
                <FormControl>
                    <InputLabel id="search-environment">{t('environment')}</InputLabel>
                    <StyledSelect
                        variant="outlined"
                        value={searchObj.environment}
                        labelId="search-environment"
                        name="environment"
                        label={t('environment')}
                        onChange={({target: {name, value}}) => handleInputChange(name, value as string)}
                    >
                        {environments.map((environment) => (
                            <MenuItem key={`environment-${environment}`} value={environment}>
                                {t(`${environment.toLowerCase()}`)}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
                <FormControl fullWidth>
                    <StyledAutocomplete
                        multiple
                        id="tags-outlined"
                        noOptionsText={t('search.labels.noInfrastructures')}
                        options={clubNames}
                        filterSelectedOptions
                        value={searchObj.clubName}
                        getOptionLabel={(clubName: any) => clubName as string}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('search.labels.selectInfrastructure')}
                                placeholder={t('search.labels.selectInfrastructure')}
                            />
                        )}
                        onChange={(e, values) => {
                            handleInputChange('clubName', values as string[]);
                        }}
                    />
                </FormControl>
            </StyledForm>
            <StyledAppButton id="edit-account" variant="contained" onClick={handleSubmit}>
                <MobileLabel>{t('search.cta')}</MobileLabel>
                <DesktopLabel src={SearchIcon}></DesktopLabel>
            </StyledAppButton>
        </StyledBox>
    );
};

{
    /* 
                <FormControl error={error?.field === 'district'} required fullWidth>
                    <InputLabel id="search-district">{t('search.labels.district')}</InputLabel>
                    <StyledSelect
                        required
                        name="district"
                        variant="outlined"
                        value={searchObj.district}
                        labelId="search-district"
                        label={t('search.labels.district')}
                        onChange={({target: {name, value}}) => handleInputChange(name, value as string)}
                    >
                        {districts?.map((district) => (
                            <MenuItem key={district} value={district}>
                                {district}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                    {error?.field === 'district' && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl> 
                */
}
