import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ErrorMessage, Form, Formik} from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {AppService} from '../../../../services/app.service';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {Category} from '../../../../shared/types/Category.type';
import {ClubArea} from '../../../../shared/types/ClubArea.type';
import {findAreaNameById, getAreaCategories, getSelectedAreaInfo} from '../../../../utils/filters';
import {
    MobileFilter,
    DesktopFilter,
    FilterButton,
    SelectGrid,
    StyledError,
    ButtonView,
    SelectClub,
    SelectClubBox,
    StyledHeader,
    StyledDialogContent,
} from './Bookings.styles';
import {
    Stack,
    Chip,
    IconButton,
    Box,
    InputLabel,
    MenuItem,
    Checkbox,
    ListItemText,
    Fab,
    useTheme,
    Theme,
    useMediaQuery,
    FormControl,
} from '@mui/material';
import {
    CalendarToday,
    Add as AddIcon,
    List as ListIcon,
    FilterAlt as FilterAltIcon,
    FilterAltOff as FilterAltOffIcon,
} from '@mui/icons-material';
import MUIDatePicker from '../../../../components/DatePicker/DatePicker';
import {BookingCalendar} from '../../../../components/BookingCalendar';
import {Select} from '../../../../components/Common/Select/Select';
import {Input} from '../../../../components/Common/Input/TextField/Input';
import {BookingList} from '../../../../components/BookingList';
import {useClubs} from '../../../../hooks/useClubs';
import {Club} from '../../../../shared/types/Club.type';
import {AppContext} from '../../../../context/AppContext';
import {useSearchParams} from 'react-router-dom';
import {CalendarNewBookingModal} from '../../../../components/CalendarNewBookingModal';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {useCommon} from '../../../../context/CommonContext';
import {AvailableSlots} from '../../../available-slots-page/components/AvailableSlots';
import ShareIcon from '@mui/icons-material/Share';
import {FeatureTogglesContext} from '../../../../context/FeatureTogglesContext';
import {FeatureToggleBaseComponent} from '../../../../components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from '../../../../shared/enums/FeatureToggles.enum';

dayjs.extend(utc);

const BookingsSearch = () => {
    const {t} = useTranslation();
    const appService = new AppService();
    const theme: Theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {data: clubs} = useClubs('private');
    //AppContext
    const appContext = useContext(AppContext);
    const club = appContext.club;
    const featureToggles = useContext(FeatureTogglesContext);
    const clubFeatureToggle = featureToggles.featureToggles;
    const clubNewBookings = appContext.newBookings;
    const bookingUpdate = appContext.bookingUpdate;

    const initialValues: any = {
        selectedArea: 'all',
        selectedSports: [],
        startDate: null,
        endDate: null,
        search: '',
        areaOptions: {
            slotInterval: '00:30',
        },
        newBookingDate: {
            start: new Date(),
            end: new Date(),
        },
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const currDate = useMemo(() => {
        return searchParams.get('date')
            ? dayjs(searchParams.get('date')).format('YYYY-MM-DD')
            : dayjs().startOf('month').format('YYYY-MM-DD');
    }, [searchParams]);

    const [bookings, setBookings] = useState<any[]>([]);
    const [isListView, setIsListView] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [newBookingModalOpen, setNewBookingModalOpen] = useState(false);
    const [newBookingDate, setNewBookingDate] = useState(initialValues.newBookingDate);
    const [selectedClubId, setSelectedClubId] = useState('all');
    const [areas, setAreas] = useState<ClubArea[]>(club?.areas ? club.areas : []);
    const [sports, setSports] = useState<Category[]>(club?.categories ? club.categories : []);
    const {setModal} = useCommon();

    const fetchData = async (apiEndpoint: keyof AppService) => {
        const firstDayOfMonth = dayjs(currDate).startOf('month').toISOString();
        const lastDayOfMonth = dayjs(currDate).endOf('month').toISOString();

        if (selectedClubId === 'all') {
            localStorage.setItem('clubAdmin.selectedClub', JSON.stringify(null));
            appContext.setClub(null);
            setBookings([]);
            setAreas([]);
            setSports([]);
            return;
        }
        try {
            const [clubRes, bookingsRes] = await Promise.all([
                appService.api_getClub('private', {clubId: selectedClubId}),
                appService[apiEndpoint]('private', selectedClubId as string, {
                    startDate: firstDayOfMonth,
                    endDate: lastDayOfMonth,
                    areaId: '',
                    sports: [],
                    search: '',
                }),
            ]);

            if (clubRes.status !== 200) throw new Error();

            // Set club details
            setSports(clubRes.data.categories ?? []);
            setAreas(clubRes.data.areas);
            localStorage.setItem('clubAdmin.selectedClub', JSON.stringify(clubRes.data));
            appContext.setClub(clubRes.data);

            // Set bookings
            setBookings(bookingsRes.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchReservation = async () => {
        await fetchData('api_getClubBookings');
    };

    const fetchHistory = async () => {
        await fetchData('api_getReservationHistory');
    };

    useEffect(() => {
        if (club) setSelectedClubId(club.id);
    }, [club]);

    useEffect(() => {
        if (selectedClubId !== 'all' && clubFeatureToggle[selectedClubId]?.length >= 1) {
            fetchHistory();
        } else {
            fetchReservation();
        }
    }, [selectedClubId, currDate, clubFeatureToggle]);

    useEffect(() => {
        if (clubNewBookings || bookingUpdate) {
            if (clubFeatureToggle[selectedClubId]?.length >= 1) {
                fetchHistory();
            } else {
                fetchReservation();
            }
        }

        return () => {
            appContext.setNewBookings(false);
            appContext.setBookingUpdate(false);
        };
    }, [clubNewBookings, bookingUpdate, clubFeatureToggle]);

    const handleChangeCloseDate = (date: any) => {
        return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    };

    const handleBookingListDelete = (id: string) => {
        setBookings((prev: any) =>
            prev.map((a: any) => {
                return a.id === id ? {...a, canceled: true} : a;
            })
        );
    };

    const handleIsPaidChange = (id: string, isPaid: boolean) => {
        setBookings((prev: any) =>
            prev.map((a: any) => {
                return a.id === id ? {...a, isPayed: isPaid} : a;
            })
        );
    };

    const handleCalendarChangeDate = (newDate: string | undefined | null) => {
        if (!newDate) return;

        setSearchParams({date: newDate});
    };

    const handleNewBooking = (start: Date, end: Date) => {
        setNewBookingDate({
            start,
            end,
        });
        setNewBookingModalOpen(true);
    };

    const handleClubChange = (event: any) => {
        setSelectedClubId(event.target.value);
    };

    return (
        <Layout headerType="menu" hideClubIcon={true} maxWidth={false}>
            <Stack alignItems="center" pb={1} spacing={2} mb={3}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={''}
                    onSubmit={async (values: any) => {
                        try {
                            if (clubFeatureToggle[selectedClubId].length === 0) {
                                const res = await appService.api_getClubBookings('private', selectedClubId as string, {
                                    startDate: values.startDate,
                                    endDate: values.endDate,
                                    areaId: values.selectedArea !== 'all' ? values.selectedArea : '',
                                    sports: values.selectedSports,
                                    search: values.search,
                                });
                                if (res.status !== 200) throw new Error();
                                setBookings(res.data);
                            } else {
                                const res = await appService.api_getReservationHistory(
                                    'private',
                                    selectedClubId as string,
                                    {
                                        startDate: values.startDate,
                                        endDate: values.endDate,
                                        areaId: values.selectedArea !== 'all' ? values.selectedArea : '',
                                        sports: values.selectedSports,
                                        search: values.search,
                                    }
                                );
                                if (res.status !== 200) throw new Error();
                                setBookings(res.data);
                            }
                        } catch (error) {
                            console.log(error);
                            setBookings([]);
                        }
                    }}
                >
                    {(props) => (
                        <>
                            <StyledHeader>
                                <SelectClubBox>
                                    <SelectClub
                                        name="selectedClub"
                                        labelId="selectedClub"
                                        value={selectedClubId}
                                        onChange={handleClubChange}
                                    >
                                        <MenuItem value="all">{t('clubAdmin.bookings.allClubs')}</MenuItem>
                                        {clubs?.map((club: Club) => (
                                            <MenuItem key={club.id} value={club.id}>
                                                {club?.name}
                                            </MenuItem>
                                        ))}
                                    </SelectClub>
                                    <FeatureToggleBaseComponent featureToggle={FeatureToggles.SHARE_AVAILABLE_SLOTS}>
                                        <AppButton
                                            variant={!isSmallScreen ? 'contained' : 'text'}
                                            startIcon={!isSmallScreen ? <ShareIcon /> : null}
                                            disabled={selectedClubId === 'all' ? true : false}
                                            onClick={() => {
                                                setModal({
                                                    open: true,
                                                    children: (
                                                        <StyledDialogContent dividers>
                                                            <AvailableSlots />
                                                        </StyledDialogContent>
                                                    ),
                                                    title: t('booking.shareAvailableSlots'),
                                                });
                                            }}
                                        >
                                            {!isSmallScreen ? t('booking.button.share') : <ShareIcon />}
                                        </AppButton>
                                    </FeatureToggleBaseComponent>
                                </SelectClubBox>
                            </StyledHeader>
                            <Form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                                <MobileFilter>
                                    {props.values.selectedArea !== '' && (
                                        <Chip
                                            label={
                                                props.values.selectedArea === 'all'
                                                    ? t('clubAdmin.bookings.allAreas')
                                                    : findAreaNameById(props.values.selectedArea, areas)
                                            }
                                        />
                                    )}
                                    {props.values.selectedSports?.length > 0 && (
                                        <Chip
                                            label={`${t('club.headers.sports')}: ${props.values.selectedSports.join(
                                                ', '
                                            )}`}
                                        />
                                    )}
                                    {props.values.startDate !== null && (
                                        <Chip
                                            label={`${t('from')}: ${dayjs(props.values.startDate).format(
                                                'DD-MM-YYYY'
                                            )}`}
                                        />
                                    )}
                                    {props.values.endDate !== null && (
                                        <Chip
                                            label={`${t('to')}: ${dayjs(props.values.endDate).format('DD-MM-YYYY')}`}
                                        />
                                    )}
                                    {props.values.search !== '' && (
                                        <Chip label={`${t('query')}: ${props.values.search}`} />
                                    )}
                                    <IconButton
                                        aria-label="filter toggle"
                                        onClick={() => setIsFilterOpen((prev) => !prev)}
                                        sx={{marginLeft: 'auto'}}
                                    >
                                        {isFilterOpen ? <FilterAltOffIcon /> : <FilterAltIcon />}
                                    </IconButton>
                                </MobileFilter>

                                <DesktopFilter isFilterOpen={isFilterOpen}>
                                    <SelectGrid>
                                        <Box>
                                            <FormControl
                                                variant="outlined"
                                                error={props.touched.selectedArea && Boolean(props.errors.selectedArea)}
                                                sx={{minWidth: '278px'}}
                                            >
                                                <InputLabel>{t('clubAdmin.bookings.selectArea')}</InputLabel>
                                                <Select
                                                    name="selectedArea"
                                                    labelId="selectedArea"
                                                    value={props.values.selectedArea}
                                                    onChange={props.handleChange}
                                                >
                                                    <MenuItem value="all">{t('clubAdmin.bookings.allAreas')}</MenuItem>
                                                    {areas?.map((area: ClubArea) => (
                                                        <MenuItem key={area.id} value={area.id}>
                                                            {area?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <ErrorMessage name="selectedArea">
                                                {(msg: string) => <StyledError>{msg}</StyledError>}
                                            </ErrorMessage>
                                        </Box>

                                        <Box>
                                            <FormControl
                                                variant="outlined"
                                                error={
                                                    props.touched.selectedSports && Boolean(props.errors.selectedSports)
                                                }
                                                sx={{minWidth: '278px'}}
                                            >
                                                <InputLabel id="sports">
                                                    {t('clubAdmin.bookings.selectCategory')}
                                                </InputLabel>
                                                <Select
                                                    name="selectedSports"
                                                    labelId="selectedSports"
                                                    value={props.values.selectedSports}
                                                    multiple
                                                    onChange={props.handleChange}
                                                    renderValue={(selected: any) => selected.join(', ')}
                                                >
                                                    {sports.map((sport: Category) => (
                                                        <MenuItem key={sport} value={sport}>
                                                            <Checkbox
                                                                checked={
                                                                    props.values.selectedSports.indexOf(sport) > -1
                                                                }
                                                            />
                                                            <ListItemText primary={sport} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <ErrorMessage name="selectedSports">
                                                {(msg: string) => <StyledError>{msg}</StyledError>}
                                            </ErrorMessage>
                                        </Box>

                                        {isListView && (
                                            <>
                                                <FormControl>
                                                    <MUIDatePicker
                                                        name="startDate"
                                                        error={Boolean(props.errors.startDate)}
                                                        label={t('clubAdmin.historyData.selectStartDate')}
                                                        disablePast={false}
                                                        disableFuture={false}
                                                        onChange={(newValue) =>
                                                            props.setFieldValue(
                                                                'startDate',
                                                                handleChangeCloseDate(newValue),
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="startDate">
                                                        {(msg: string) => <StyledError>{msg}</StyledError>}
                                                    </ErrorMessage>
                                                </FormControl>
                                                <FormControl>
                                                    <MUIDatePicker
                                                        name="endDate"
                                                        error={Boolean(props.touched.endDate && props.errors.endDate)}
                                                        label={
                                                            props.errors.endDate
                                                                ? ''
                                                                : t('clubAdmin.historyData.selectEndDate')
                                                        }
                                                        disablePast={false}
                                                        disableFuture={false}
                                                        onChange={(newValue) =>
                                                            props.setFieldValue(
                                                                'endDate',
                                                                handleChangeCloseDate(newValue),
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <ErrorMessage name="endDate">
                                                        {(msg: string) => <StyledError>{msg}</StyledError>}
                                                    </ErrorMessage>
                                                </FormControl>
                                            </>
                                        )}

                                        <Box>
                                            <Input
                                                name="search"
                                                placeholder={t('clubAdmin.bookings.inputLabel.search')}
                                                label={t('clubAdmin.bookings.inputLabel.search')}
                                                type="text"
                                                value={props.values.search}
                                                onChange={props.handleChange}
                                                error={props.touched.search && Boolean(props.errors.search)}
                                                sx={{width: '100%', minWidth: '343px'}}
                                            />
                                            <ErrorMessage name="search">
                                                {(msg: string) => <StyledError>{msg}</StyledError>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box>
                                            {isListView ? (
                                                <ButtonView
                                                    variant="outlined"
                                                    startIcon={<CalendarToday />}
                                                    onClick={() => setIsListView(false)}
                                                    sx={{minWidth: '278px'}}
                                                >
                                                    {t('clubAdmin.bookings.calendarButton')}
                                                </ButtonView>
                                            ) : (
                                                <ButtonView
                                                    variant="outlined"
                                                    startIcon={<ListIcon />}
                                                    onClick={() => setIsListView(true)}
                                                    sx={{minWidth: '278px'}}
                                                >
                                                    {t('clubAdmin.bookings.listButton')}
                                                </ButtonView>
                                            )}
                                        </Box>
                                        <FilterButton
                                            variant="contained"
                                            type="submit"
                                            disabled={club === null ? true : false}
                                            sx={{minWidth: isListView ? '278px' : '243px'}}
                                        >
                                            {t('filter')}
                                        </FilterButton>
                                    </SelectGrid>
                                </DesktopFilter>
                                {isListView ? (
                                    <BookingList
                                        bookings={bookings}
                                        clubId={props.values.selectedClub!}
                                        onBookingDelete={handleBookingListDelete}
                                        onIsPaidUpdate={handleIsPaidChange}
                                        history={clubFeatureToggle[selectedClubId]?.length >= 1}
                                    />
                                ) : (
                                    <BookingCalendar
                                        bookings={bookings}
                                        currDate={currDate}
                                        options={initialValues.areaOptions}
                                        onNewBooking={handleNewBooking}
                                        onBookingDelete={handleBookingListDelete}
                                        onDateChange={handleCalendarChangeDate}
                                        selectedAreaInfo={getSelectedAreaInfo(club?.areas, props.values.selectedArea)}
                                    />
                                )}
                            </Form>
                            <CalendarNewBookingModal
                                open={newBookingModalOpen}
                                onClose={() => setNewBookingModalOpen(false)}
                                club={club!}
                                selectedDay={newBookingDate}
                                selectedArea={props.values.selectedArea}
                                selectedSport={props.values.selectedSports}
                                areaSports={getAreaCategories(club?.areas, props.values.selectedArea)}
                                selectedAreaInfo={getSelectedAreaInfo(club?.areas, props.values.selectedArea)}
                            />
                        </>
                    )}
                </Formik>
            </Stack>
            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{
                    display: isListView ? 'flex' : 'none',
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                onClick={() => setIsListView(false)}
            >
                <AddIcon sx={{mr: 1}} />
                {t('clubAdmin.bookings.newBooking')}
            </Fab>
        </Layout>
    );
};

export default BookingsSearch;
