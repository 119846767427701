import {Box, Stack} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{padding: '1rem'}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <img
                    onClick={() => navigate('/')}
                    src="/logo_aveiro_tech_city.png"
                    alt="FreeCourts: Courts for everyone"
                    height={82}
                />
            </Stack>
        </Box>
    );
};

export default Header;
