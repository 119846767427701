import {useMutation, useQueryClient} from 'react-query';
import {AppService} from '../services/app.service';
import {useContext} from 'react';
import {AppContext} from '../context/AppContext';
import {useNavigate} from 'react-router-dom';

export function useDeleteClub(clubId: string) {
    const appService = new AppService();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const refetchClubQuery = () => {
        queryClient.invalidateQueries('clubs');
        navigate('/club-admin');
        appContext.setClub(null);
    };

    const {isLoading, data, mutate} = useMutation({
        mutationKey: ['deleteClub'],
        mutationFn: async () => {
            const res = await appService.api_deleteClub(clubId);
            return res;
        },
        onSuccess: refetchClubQuery,
    });

    return {data, isLoading, mutate};
}
