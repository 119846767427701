import {TFunction} from 'i18next';
import * as yup from 'yup';
import {emailRegex} from '../utils/validation';

export const validationLoginSchema = (t: TFunction) => {
    return yup.object({
        email: yup
            .string()
            .matches(emailRegex, t('validation.requiredValidEmail'))
            .required(t('validation.requiredEmail')),
        password: yup.string().required(t('validation.invalidPassword')),
    });
};
