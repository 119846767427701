import React from 'react';
import {useTranslation} from 'react-i18next';
import {LockerRoom} from '../../../../shared/enums/LockerRoom.enum';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Vault from '../../../../assets/icons/vault.svg';

export const Lockerooms = ({lockerRoomType, variant}: {lockerRoomType: LockerRoom; variant: string}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Vault} />
                {t(`club.headers.lockerooms`)}
            </Header>
            {!!lockerRoomType
                ? t(`club.lockerooms.${lockerRoomType.toLowerCase()}`)
                : t(`club.lockerooms.noInformation`)}
        </StyledBox>
    );
};
