import Dialog, {DialogProps} from '@mui/material/Dialog';
import {IconButton} from '@mui/material';
import React, {FC, PropsWithChildren} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {useCommon} from '../../../context/CommonContext';
import {StyledDialogTitle} from './Modal.styles';

export type DialogMuiProps = DialogProps;

export const ModalStyled: FC<PropsWithChildren<DialogMuiProps>> = ({open, title, onClose, children}) => {
    const {setModal} = useCommon();
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setModal({open: false, children: null})}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            {children}
        </Dialog>
    );
};
