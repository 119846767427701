import React, {FC, useState, ReactNode, createContext, useContext} from 'react';

type FeatureToggle = {
    createdAt: string;
    id: string;
    name: string;
};
export type FeatureTogglesContextType = {
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
    featureToggles: Record<string, FeatureToggle[]>;
    setFeatureToggles: (state: Record<string, FeatureToggle[]>) => void;
    domainFeatureToggles: Record<string, FeatureToggle[]>;
    setDomainFeatureToggles: (state: Record<string, FeatureToggle[]>) => void;
};

export const FeatureTogglesContext = createContext<FeatureTogglesContextType>({
    isLoading: true,
    setIsLoading: () => {},
    featureToggles: {},
    setFeatureToggles: () => {},
    domainFeatureToggles: {},
    setDomainFeatureToggles: () => {},
});

export type FeatureTogglesContextProviderProps = {
    children?: ReactNode;
};

export const FeatureTogglesContextProvider: FC<FeatureTogglesContextProviderProps> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [featureToggles, setFeatureToggles] = useState<Record<string, FeatureToggle[]>>({});
    const [domainFeatureToggles, setDomainFeatureToggles] = useState<Record<string, FeatureToggle[]>>(() => {
        const storedDomainFeatureToggles = localStorage.getItem('domainFeatureToggles');
        return storedDomainFeatureToggles ? JSON.parse(storedDomainFeatureToggles) : {};
    });
    const values = {
        isLoading,
        setIsLoading,
        featureToggles,
        setFeatureToggles,
        domainFeatureToggles,
        setDomainFeatureToggles,
    };

    return <FeatureTogglesContext.Provider value={values}>{props.children}</FeatureTogglesContext.Provider>;
};

export const useFeatureTogglesContext = () => useContext(FeatureTogglesContext);
