import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {styled} from '@mui/material/styles';

export const TextFieldStyled = styled(TextField)(({theme, error}) => ({
    fontSize: '1rem',
    width: '100%',
    borderRadius: 4,
    background: error ? 'transparent' : 'white',
    '::before': {
        borderBottomStyle: 'none',
    },
    '& .MuiInputBase-root': {
        background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root': {
        borderColor: 'transparent',
        background: error ? '#ffebee' : 'white',
    },
    '& .Mui-disabled': {
        backgroundColor: 'var(--grey-lighten-3)',
        '& .Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: error ? 'red' : 'transparent !important',
        boxShadow: 'var(--default-boxShadow)',
    },
    '& .MuiFilledInput-root': {
        borderRadius: 4,
        '::after': {
            borderBottomStyle: 'none',
        },
    },
    '& .MuiFilledInput-input': {
        borderRadius: 4,
        border: error ? '1px solid red' : 'none',
        background: error ? '#ffebee' : 'white',
    },
    '& .MuiError': {
        background: theme.palette.background.paper,
        borderRadius: 4,
    },
    '& .MuiFormHelperText-root': {
        color: error ? 'red' : '#424242',
    },
    '& .Mui-error': {
        '::before': {
            borderBottomStyle: 'none',
        },
    },
}));

export const InputLabelStyled = styled(InputLabel)`
    line-height: 2rem;
    color: var(--custom-secondary);
    font-size: 1rem;

    .MuiInputLabel-asterisk {
        color: red;
    }
`;
