import {Accordion, Box, Typography} from '@mui/material';
import styled, {css} from 'styled-components';
import {AreaSearchResultActions} from '../area-search-result/AreaSearchResultActions';

export const Area = styled(Box)`
    margin-bottom: 1rem;
    background: white;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -3px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

    @media (min-width: 1024px) {
        width: calc(33% - 11px);
    }
`;

export const AreaHeader = styled(Box)`
    gap: 1rem;
    display: flex;
    flex-direction: column;
`;

export const AreaImageContainer = styled(Box)`
    width: 100%;
`;

export const AreaImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-height: 200px;
`;

export const AreaInfoContainer = styled(Box)`
    flex: 1;
    & > div:first-of-type {
        padding-inline: 1rem;
    }
`;

export const AreaTitle = styled.h4`
    margin: 0;
`;

export const AreaPrice = styled(Box)`
    h3 {
        margin: 0;
        text-align: end;
    }
`;

export const AreaDescription = styled.p`
    width: 100%;
    max-height: 4.5em;
    line-height: 1.5em;
    padding: 0 1rem;
    overflow: auto;
`;

export const StyledAccordion = styled(Accordion)`
    margin-top: 1rem;
`;

export const StyledTypography = styled(Typography).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'expanded',
})<{expanded: boolean}>`
    position: relative;
    padding-left: 2rem;

    &:after {
        width: 8px;
        height: 8px;
        content: '';
        display: block;
        position: absolute;
        border-top: 1px solid black;
        border-left: 1px solid black;
        transition: top .3s ease, transform: .3s ease;

        ${({expanded}) =>
            !expanded
                ? css`
                      top: 5px;
                      left: 2px;
                      transform: rotate(-135deg);
                  `
                : css`
                      top: 9px;
                      left: 2px;
                      transform: rotate(45deg);
                  `}
    }
`;

export const AreaSearchContainer = styled(Box)`
    padding: 1rem;
`;

export const StyledAreaSearchResultActions = styled(AreaSearchResultActions)`
    margin-top: 1rem;
`;
