export const getLabelForTimeUnit = (timeUnit: string) => {
    switch (timeUnit) {
        case 'TRINTA_MINUTOS':
            return '00:30';
        case 'UMA_HORA':
            return '01:00';
        case 'UMA_HORA_TRINTA_MINUTOS':
            return '01:30';
        case 'DUAS_HORA':
            return '02:00';
        default:
            return '';
    }
};
