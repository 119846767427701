import styled from 'styled-components';
import {AppButton} from '../Common/Buttons/AppButton';

export const BookingCardStyled = styled.div`
    background-color: #fff;
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
`;

export const TertiaryButton = styled.button`
    display: block;
    cursor: pointer;
    text-align: left;
    justify-content: left;
    padding: 0;
    position: relative;
    z-index: 2;
    margin-top: 10px;
    text-transform: none;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: var(--color-primary);

    &:hover {
        text-decoration: underline;
        background: none;
    }
`;

export const StyledAppButton = styled(AppButton)`
    padding: 0 16px !important;
`;
