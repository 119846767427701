import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {GoBackButton} from '../../../components/Common/GoBackButton';
import {Layout} from '../../../components/Common/Layout/Layout';
import {useTranslation} from 'react-i18next';
import {ErrorMessage, Formik, FormikHelpers} from 'formik';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
import {Input} from '../../../components/Common/Input/TextField/Input';
import {AppService} from '../../../services/app.service';
import {getURLWithReturn} from '../../../utils/returnUrl';
import {validationRegisterSchema} from '../../../models/validationRegisterSchema.model';
import {useCommon} from '../../../context/CommonContext';
import {Form, FormBox, GoBackBox, Image, StyledBackgroundImage} from './Register.styles';

const Register = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {setSnackbar} = useCommon();

    const return_url = searchParams.get('return_url');

    const handleFormSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
        new AppService()
            .api_registerUser('player', values)
            .then((res) => {
                if (res.status !== 201) throw new Error();

                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'success',
                    children: t('register.success'),
                });
                setTimeout(() => {
                    navigate(`/verify?email=${values.email}${return_url ? `&return_url=${return_url}` : ''}`);
                }, 2000);
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t(`register.error.${error.response.status}`, {email: error.response.data.message}),
                });
                formikHelpers.setSubmitting(false);
            });
    };

    const initialValues: any = {
        name: '',
        email: '',
        password: '',
        phone: '',
        nif: '',
        address: '',
    };

    return (
        <>
            <Layout maxWidth="sm">
                <FormBox>
                    <GoBackBox>
                        <GoBackButton to={getURLWithReturn('/login', return_url)} />
                    </GoBackBox>
                    <Image src="/logo_aveiro_tech_city_white.png" alt="FreeCourts: Courts for everyone" />
                    <Typography color="common.white" variant="h5" textAlign="center">
                        {t('register.title')}
                    </Typography>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationRegisterSchema(t)}
                        onSubmit={handleFormSubmit}
                    >
                        {(props) => (
                            <Form onSubmit={props.handleSubmit}>
                                <Box>
                                    <Input
                                        name="name"
                                        label={t('register.name.label')}
                                        type="text"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        error={props.touched.name && !!props.errors.name}
                                    />
                                    <ErrorMessage name="name">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="email"
                                        label={t('register.email.label')}
                                        type="text"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        error={props.touched.email && !!props.errors.email}
                                    />
                                    <ErrorMessage name="email">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="password"
                                        label={t('password')}
                                        type="password"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.touched.password && !!props.errors.password}
                                    />
                                    <ErrorMessage name="password">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="phone"
                                        label={t('register.phone.label')}
                                        type="tel"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.phone}
                                        onChange={props.handleChange}
                                        error={props.touched.phone && !!props.errors.phone}
                                    />
                                    <ErrorMessage name="phone">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="nif"
                                        label={t('register.nif.label')}
                                        type="text"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.nif}
                                        onChange={props.handleChange}
                                        error={props.touched.nif && !!props.errors.nif}
                                    />
                                    <ErrorMessage name="nif">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="address"
                                        label={t('register.address.label')}
                                        type="text"
                                        variant="filled"
                                        autoComplete="off"
                                        value={props.values.address}
                                        onChange={props.handleChange}
                                        error={props.touched.address && !!props.errors.address}
                                    />
                                    <ErrorMessage name="address">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <AppButton variant="contained" type="submit" disabled={props.isSubmitting}>
                                    {t('register.submitButton')}
                                </AppButton>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Layout>
            <StyledBackgroundImage src="/images/football.jpg" />
        </>
    );
};

export default Register;
