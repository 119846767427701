import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';

import {FormControl} from '@mui/material';

import {mountQueryString} from '../../utils/mountQueryString';

import SearchIcon from '../../../../assets/icons/search.svg';

import {
    MainBox,
    StyledSlider,
    StyledImage,
    MainBoxInner,
    MainTitle,
    StyledHomePageDatePicker,
    StyledButton,
    Form,
} from './Main.styles';

const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export const Main = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [value, setValue] = useState<Dayjs | null>(dayjs());

    const search = () => {
        setError(null);
        if (!!!value) {
            setError('Preenche o dia');
            return;
        }
        const date = dayjs(value, 'DD-MM-YYYY HH:mm').hour(0).minute(0).second(0);
        const queryString = mountQueryString({date: `${date.unix()}`});

        navigate(`/search${queryString}`);
    };

    return (
        <MainBox>
            <StyledSlider {...settings}>
                <StyledImage src="/images/basket-1.jpg" />
                <StyledImage src="/images/padel-2.avif" />
                <StyledImage src="/images/football-3.avif" />
                <StyledImage src="/images/tennis-4.avif" />
            </StyledSlider>
            <MainBoxInner>
                <MainTitle>{t('homepage.title')}</MainTitle>
                <Form>
                    <FormControl error={!!error} required fullWidth>
                        <StyledHomePageDatePicker
                            disablePast={true}
                            format="DD-MM-YYYY"
                            value={value}
                            slotProps={{
                                textField: {
                                    required: true,
                                    error: !!error,
                                    helperText: !!error ? error : undefined,
                                },
                            }}
                            onChange={(date: any) => setValue(date)}
                        />
                    </FormControl>
                    <StyledButton onClick={() => search()}>
                        <img src={SearchIcon} />
                    </StyledButton>
                </Form>
            </MainBoxInner>
        </MainBox>
    );
};
