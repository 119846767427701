import Field from '../../../assets/icons/field.svg';
import Reservation from '../../../assets/icons/reservation.svg';
import Share from '../../../assets/icons/people.svg';

import AdvantageOne from '../../../assets/images/advantages-1.svg';
import AdvantageTwo from '../../../assets/images/advantages-2.svg';
import AdvantageThree from '../../../assets/images/advantages-3.svg';

export const steps_cards = [
    {
        img: Field,
        translationKey: 'search',
    },
    {
        img: Reservation,
        translationKey: 'book',
    },
    {
        img: Share,
        translationKey: 'share',
    },
];

export const statistics_cards = [
    {
        value: '∞',
        translationKey: 'fun',
    },
];

export const advantages_cards = [
    {
        image: AdvantageOne,
        text: 'first_text',
        subtext: 'first_subtext',
    },
    {
        image: AdvantageTwo,
        text: 'second_text',
        subtext: 'second_subtext',
    },
    {
        image: AdvantageThree,
        text: 'third_text',
        subtext: 'third_subtext',
    },
];
