import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Areas, AreasContainer, AreasSearch} from './ClubAreas.styles';
import {PropTypes} from './ClubAreas.types';
import {ClubArea} from '../club-area/ClubArea';
import useSearchContext from '../../../search-page/contexts/SearchContext';
import {useSearch} from '../../../search-page/hooks/useSearch';
import {HourAndDateForm} from '../../../search-page/components/hour-and-date-form/HourAndDateForm';
import {Alert} from '@mui/material';

export const ClubAreas: FC<PropTypes> = ({isSearchEnabled, clubId, areas, clubPhoto}) => {
    const {t} = useTranslation();
    const checkQueryStringRef = useRef(false);
    const [error] = useState<Record<string, string> | undefined>(undefined);
    const {isLoadingFilters} = useSearchContext();
    const {
        actions: {checkQueryString},
    } = useSearch();

    useEffect(() => {
        if (isLoadingFilters || checkQueryStringRef.current) return;
        checkQueryStringRef.current = true;
        checkQueryString({clubId});
    }, [isLoadingFilters]);

    return (
        <AreasContainer>
            {isSearchEnabled && (
                <AreasSearch>
                    <HourAndDateForm error={error} additionalSearchObj={{clubId}} />
                </AreasSearch>
            )}
            <Areas>
                {!areas.length ? (
                    <Alert severity="info">{t('club.areas.noAreas')}</Alert>
                ) : (
                    areas.map((area) => (
                        <ClubArea
                            isSearchEnabled={isSearchEnabled}
                            key={area.id}
                            area={area}
                            images={area.AreaPhoto?.length ? area.AreaPhoto : clubPhoto}
                        />
                    ))
                )}
            </Areas>
        </AreasContainer>
    );
};
