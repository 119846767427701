import styled from 'styled-components';

export const StyledBackgroundImage = styled.img`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    object-position: center;
    z-index: -1;
`;
