import React, {PropsWithChildren, createContext, useContext, useMemo, useState} from 'react';
import {ClubContextType} from '../types/ClubContext.type';
import {Club} from '../../../shared/types/Club.type';

export const ClubContext = createContext<ClubContextType>({
    isLoading: false,
    setIsLoading: () => undefined,
    club: undefined,
    setClub: () => undefined,
});

export const ClubContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [club, setClub] = useState<Club | undefined>(undefined);

    const values = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            club,
            setClub,
        }),
        [isLoading, setIsLoading, club, setClub]
    );

    return <ClubContext.Provider value={values} {...props} />;
};
const useClubContext = () => useContext(ClubContext);
export default useClubContext;
