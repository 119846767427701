import {Typography} from '@mui/material';
import styled from 'styled-components';

export const Form = styled.form`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledError = styled(Typography)`
    color: #d32f2f;
    font-size: 12px !important;
    margin: 3px 14px 0 14px !important;
`;
