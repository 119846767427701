import {Box} from '@mui/material';
import styled from 'styled-components';
import {
    commonContainerStyles,
    mediumHeaderStyles,
    largeTextStyle,
    smallHeaderStyles,
    mediumTextStyle,
    extraSmallHeaderStyles,
    smallTextStyle,
} from '../../utils/shared.styles';

export const LocationsBox = styled(Box)`
    gap: 40px;
    display: flex;
    color: #212a5c;
    padding-block: 80px;
    flex-direction: column;

    ${commonContainerStyles};
`;

export const LocationsTitle = styled.h2`
    ${mediumHeaderStyles};
`;

export const LocationsText = styled.p`
    max-width: 615px;

    ${largeTextStyle};
`;

export const StatisticsCards = styled(Box)`
    gap: 40px;
    display: flex;
    max-width: 615px;
    flex-direction: column;

    @media (min-width: 600px) {
        gap: 20px;
        flex-direction: row;
    }
`;

export const StatisticsCard = styled(Box)`
    flex: 1;

    h5 {
        ${mediumHeaderStyles};

        @media (min-width: 600px) {
            ${smallHeaderStyles};
        }
    }

    p {
        ${largeTextStyle};

        @media (min-width: 600px) {
            ${mediumTextStyle};
        }
    }
`;

export const Clubs = styled(Box)`
    margin-top: 50px;
`;

export const Club = styled(Box)`
    gap: 40px;
    display: flex;
    max-width: 400px;
    margin-inline: auto;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: 600px) {
        max-width: unset;
        flex-direction: row;
        margin-inline: unset;

        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 100px;
    }
`;

export const ClubImage = styled.div`
    flex: 1;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const ClubInfo = styled(Box)`
    flex: 1;
`;

export const ClubName = styled.a`
    ${extraSmallHeaderStyles};

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const ClubSports = styled(Box)`
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
`;

export const ClubDescription = styled.p`
    ${largeTextStyle};
`;

export const ClubAddress = styled.p`
    ${largeTextStyle};

    margin-block: 20px;
`;

export const StyledIcon = styled.img`
    margin-right: 0.2rem;
`;
