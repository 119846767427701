import styled from 'styled-components';
import {ContainerStylingProps} from '../../types/StylingProps';

export const Container = styled('div').withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'image',
})<ContainerStylingProps>`
    display: flex;
    min-height: 10rem;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-image: url(${({image}) => image});
`;

export const InfoContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const Info = styled.div`
    width: 100%;
    color: white;
    text-align: center;
`;

export const StyledIcon = styled.img`
    filter: invert(1);
    margin-right: 0.2rem;
    transform: translateY(0.2rem);
`;
