import {Button, Box} from '@mui/material';
import {styled} from '@mui/material/styles';

export const HistoryDataCardStyled = styled(Box)`
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
`;

export const TertiaryButton = styled(Button)`
    text-align: left;
    justify-content: left;
    padding: 0;
    margin-top: 10px;
    text-transform: none;

    &:hover {
        text-decoration: underline;
        background: none;
    }
`;
