:root {
    --fc-button-bg-color: #1976d2;
    --fc-button-border-color: #1976d2;
    --fc-button-hover-bg-color: #1769aa;
    --fc-button-hover-border-color: #1769aa;
    --fc-button-active-bg-color: #1769aa;
    --fc-button-active-border-color: #1769aa;
}

.eventContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
}

.fullWidth {
    width: 100%;
}
