import {useState} from 'react';
import {AppService} from '../../../services/app.service';

export const useBooking = () => {
    const appService = new AppService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [area, setArea] = useState<any>(undefined);

    const fetchArea = (id: string) => {
        setIsLoading(true);

        appService
            .api_getAreaById(id)
            .then((res) => {
                if (res.status !== 200) throw new Error();

                if (res.data) {
                    setArea(res.data);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        area,
        actions: {fetchArea},
    };
};
