import styled, {css} from 'styled-components';
import {Box, Select, Autocomplete} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';

export const disableTextFieldStyles = css`
    position: relative;
    pointer-events: none;
    background-color: white;

    .MuiInputAdornment-outlined {
        pointer-events: all;

        > button {
            position: unset;

            .MuiTouchRipple-root {
                display: none;
            }

            &::before {
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
            }
        }
    }
`;

export const StyledSelect = styled(Select)`
    background-color: white;
`;

export const FlexContainer = styled.div`
    gap: 0.7rem;
    display: flex;
    flex-wrap: wrap;

    > div {
        width: calc(50% - 0.35rem);
    }

    @media (min-width: 1024px) {
        gap: 1rem;

        > div {
            width: calc(50% - 0.5rem);
        }
    }
`;

export const StyledDatePicker = styled(DatePicker)`
    background-color: white;
    @media (pointer: fine) {
        ${disableTextFieldStyles};
    }
`;

export const StyledAutocomplete = styled(Autocomplete)`
    background-color: white;
`;

export const StyledBox = styled(Box)`
    gap: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @media (min-width: 1024px) {
        flex-direction: row;
        padding-right: 1rem;
    }
`;

export const StyledForm = styled(Box)`
    gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        &:not(:last-of-type) {
            width: calc(50% - 0.5rem);
        }
    }

    @media (min-width: 1024px) {
        flex: 1;
        flex-wrap: wrap;
        flex-direction: row;
        padding-inline-start: 1rem;

        > div {
            flex: 1;
            width: calc(20% - 0.858rem);
            min-width: calc(20% - 0.858rem);
            &:not(:last-of-type) {
                min-width: unset;
                width: calc(calc(80% / 6) - 0.858rem);
            }

            &:first-of-type,
            &:nth-of-type(3),
            &:nth-of-type(5),
            &:nth-of-type(6) {
                max-width: 175px;
            }

            &:nth-of-type(2),
            &:nth-of-type(4) {
                max-width: 150px;
            }
        }
    }
`;

export const StyledAppButton = styled(AppButton)`
    @media (min-width: 1024px) {
        width: 64px !important;
        height: 56px !important;
    }
`;

export const MobileLabel = styled.span`
    @media (min-width: 1024px) {
        display: none;
    }
`;

export const DesktopLabel = styled.img`
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
`;
