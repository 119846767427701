import {useState} from 'react';
import {AppService} from '../services/app.service';
import {useFeatureTogglesContext} from '../context/FeatureTogglesContext';

export const useFeatureToggles = () => {
    const appService = new AppService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {domainFeatureToggles, setDomainFeatureToggles, featureToggles, setFeatureToggles} =
        useFeatureTogglesContext();

    const fetchClubFeatureToggles = (clubId: string) => {
        if (clubId !== undefined) {
            if (Object.keys(featureToggles)[0] === clubId) {
                return;
            }
            setIsLoading(true);
            appService
                .api_getClubFeatureToggles('private', clubId)
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error();
                    }
                    setFeatureToggles({[clubId]: res.data});
                })
                .catch((error) => console.log(error))
                .finally(() => setIsLoading(false));
        }
    };

    const fetchDomainFeatureToggles = () => {
        setIsLoading(true);
        appService
            .api_getDomainFeatureToggles()
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error();
                }
                setDomainFeatureToggles(res.data);
                localStorage.setItem('domainFeatureToggles', JSON.stringify(res.data));
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    const hasFeatureToggle = (claim: any) => Object.values(featureToggles)[0]?.some((ft: any) => ft.name === claim);
    const hasDomainFeatureToggle = (domainClaim: any) =>
        Object.values(domainFeatureToggles)?.some((ft: any) => ft.name === domainClaim);

    return {
        isLoading,
        actions: {hasFeatureToggle, fetchClubFeatureToggles, fetchDomainFeatureToggles, hasDomainFeatureToggle},
    };
};
