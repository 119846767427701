import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { FC } from "react";

export type GoBackButtonProps = {
  to?: string;
  color?: any;
}

export const GoBackButton: FC<GoBackButtonProps> = ({
  to = "/",
  color = "white",
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(to);
  }

  return (
    <Button
      onClick={handleGoBack}
      sx={{ width: '40px', height: '40px' }}
    >
      <ArrowBackIcon color={color}/>
    </Button>
  )
}