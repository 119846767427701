import {css} from 'styled-components';

export const commonContainerStyles = css`
    margin: 0 auto;
    max-width: 1100px;
    padding-inline: 24px;

    @media (min-width: 600px) {
        padding-inline: 40px;
    }
`;

const headerFontStyles = css`
    margin-block: 0;
    font-weight: normal;
    font-family: 'Bowlby One', sans-serif;
`;

const bodyFontStyles = css`
    margin-block: 0;
    font-family: 'Golos Text', sans-serif;
`;

export const largeHeaderStyles = css`
    font-size: 44px;

    @media (min-width: 600px) {
        font-size: 55px;
    }

    @media (min-width: 891px) {
        font-size: 60px;
    }

    ${headerFontStyles};
`;

export const mediumHeaderStyles = css`
    font-size: 48px;
    ${headerFontStyles};
`;

export const smallHeaderStyles = css`
    font-size: 32px;
    ${headerFontStyles};
`;

export const extraSmallHeaderStyles = css`
    font-size: 22px;
    ${headerFontStyles};
`;

export const largeTextStyle = css`
    font-size: 20px;
    line-height: 1.5em;
    ${bodyFontStyles};
`;

export const mediumTextStyle = css`
    font-size: 16px;
    ${bodyFontStyles};
`;

export const smallTextStyle = css`
    font-size: 13px;
    ${bodyFontStyles};
`;
