import React from 'react';
import {AlertColor, Stack, Typography} from '@mui/material';
import {AppButton} from '../Common/Buttons/AppButton';
import {useTranslation} from 'react-i18next';
import {AppService} from '../../services/app.service';
import {useCommon} from '../../context/CommonContext';

interface AreaDeleteFormProps {
    area: {
        id: string;
        name: string;
    };
    onDelete?: () => void | undefined;
}

export const AreaDeleteForm = ({area, onDelete = undefined}: AreaDeleteFormProps) => {
    const {t} = useTranslation();
    const {setModal, setSnackbar} = useCommon();

    const handleCloseModal = () => {
        setModal({open: false, children: <></>});
    };

    const handleDeleteClub = () => {
        new AppService()
            .api_deleteArea(area.id)
            .then((res: any) => {
                if (res.status !== 200) throw new Error(res);

                if (typeof onDelete === 'function') onDelete();

                setModal({open: false, children: <></>});
            })
            .catch((error: any) => {
                console.log(error);
                const alert = {
                    severity: 'error' as AlertColor,
                    children: t('generalError'),
                };
                setSnackbar({
                    open: true,
                    ...alert,
                    setOpen: () => setSnackbar({open: false, ...alert, setOpen: () => {}}),
                });
            });
    };

    return (
        <Stack spacing={1}>
            <Typography variant="body1" px={1}>
                {t('areaDeleteDescription')}
            </Typography>
            <AppButton id="delete-club" variant="contained" color="error" onClick={handleDeleteClub}>
                {t('delete')}
            </AppButton>
            <AppButton id="cancel" variant="outlined" type="reset" onClick={handleCloseModal}>
                {t('cancel')}
            </AppButton>
        </Stack>
    );
};
