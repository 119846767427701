import React, {FC, PropsWithChildren} from 'react';
import {useFeatureToggles} from '../../hooks/useFeatureToggles';
import {Navigate} from 'react-router-dom';

type FeatureToggleBaseProps = {
    featureToggle: string;
    redirect?: string;
};
export const FeatureToggleBaseComponent: FC<PropsWithChildren<FeatureToggleBaseProps>> = ({
    featureToggle,
    redirect,
    children,
}) => {
    const {
        actions: {hasFeatureToggle, hasDomainFeatureToggle},
    } = useFeatureToggles();

    const hasClaim = hasFeatureToggle(featureToggle);
    const hasDomainClaim = hasDomainFeatureToggle(featureToggle);
    if (!hasClaim && redirect) {
        return <Navigate to={redirect} />;
    }

    return (
        <>
            {hasClaim ? children : null}
            {hasDomainClaim ? children : null}
        </>
    );
};
