import {SearchObjectType} from '../types/SearchObject.type';
import {fieldsOrder, notRequiredFields} from './constants';

export const checkIfFieldsAreValidAndReturnError = (searchObj: SearchObjectType) => {
    let error: Record<string, string> | undefined;
    for (const key of fieldsOrder as (keyof SearchObjectType)[]) {
        if (notRequiredFields.includes(key)) {
            continue;
        }
        if (!!!searchObj[key]) {
            error = {
                field: key as string,
            };
            break;
        }
    }
    return error;
};
