import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Divider, Typography, Grid} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import {useTranslation} from 'react-i18next';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {LoginForm} from '../../../../components/LoginForm/LoginForm';
import {getURLWithReturn} from '../../../../utils/returnUrl';
import {AppService} from '../../../../services/app.service';
import {Area} from '../../../../models/booking.model';
import {
    CheckoutBox,
    CheckoutContainer,
    Detail,
    DetailTypography,
    GridDetails,
    GridImage,
    Image,
    Title,
} from './CheckoutBooking.styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {FeatureToggleBaseComponent} from '../../../../components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from '../../../../shared/enums/FeatureToggles.enum';
import {Layout} from '../../../../components/Common/Layout/Layout';

dayjs.extend(utc);

const CheckoutBooking = () => {
    const clientId = process.env.PLAYER_COGNITO_CLIENT_ID;
    const redirectUrL = process.env.REDIRECT_URL;
    const cognitoDomain = process.env.PLAYER_COGNITO_DOMAIN;
    const googleProvider = `${cognitoDomain}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrL}&state=STATE&scope=aws.cognito.signin.user.admin&identity_provider=Google`;

    const navigate = useNavigate();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const return_url = searchParams.get('return_url');
    const appService = new AppService();
    const booking = location.state;
    const [areaInfo, setAreaInfo] = useState<Area>();

    const handleGoogleLogin = () => {
        if (return_url) sessionStorage.setItem('google.returnURL', return_url);
    };

    useEffect(() => {
        appService.api_getAreaById(booking.areaId).then((res) => {
            setAreaInfo(res.data);
        });
    }, []);

    return (
        <Layout maxWidth="md">
            <Grid container marginBottom={4}>
                <GridImage item xs={12}>
                    <Image src="/logo_aveiro_tech_city.png" alt="FreeCourts: Courts for everyone" />
                </GridImage>
                <CheckoutContainer container>
                    <CheckoutBox item xs={12} md={7}>
                        <Title>{t('checkout.message')}</Title>
                        <Divider></Divider>
                        <FeatureToggleBaseComponent featureToggle={FeatureToggles.GOOGLE_SSO}>
                            <Link to={googleProvider} onClick={handleGoogleLogin}>
                                <AppButton variant="outlined" color="primary" startIcon={<GoogleIcon />}>
                                    {t('login.loginWithGoogle')}
                                </AppButton>
                            </Link>

                            <Divider>{t('or')}</Divider>
                        </FeatureToggleBaseComponent>
                        <LoginForm userType="player" redirect={return_url ? return_url : '/'} />

                        <AppButton
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate(getURLWithReturn('/signup', return_url))}
                        >
                            {t('login.register')}
                        </AppButton>
                    </CheckoutBox>
                    <GridDetails item xs={12} md={5}>
                        <Title>{t('bookingDetails')}:</Title>
                        <Detail>
                            <Typography variant="body2">{t('area')}:</Typography>
                            <DetailTypography>
                                {areaInfo?.name} - {areaInfo?.club?.address.address}
                            </DetailTypography>
                        </Detail>
                        <Detail>
                            <Typography variant="body2">{t('sport')}:</Typography>
                            <DetailTypography>{booking?.category}</DetailTypography>
                        </Detail>
                        <Detail>
                            <Typography variant="body2">{t('day')}:</Typography>
                            <DetailTypography>{dayjs(booking?.day).format('DD/MM/YYYY')}</DetailTypography>
                        </Detail>
                        <Detail>
                            <Typography variant="body2">{t('schedule')}:</Typography>
                            <DetailTypography>
                                {dayjs.utc(booking?.from).format('HH:mm')} - {dayjs.utc(booking?.to).format('HH:mm')}
                            </DetailTypography>
                        </Detail>
                        <Detail>
                            <Typography variant="body2">{t('price')}</Typography>
                            <DetailTypography>{booking?.price}&euro;</DetailTypography>
                        </Detail>
                    </GridDetails>
                </CheckoutContainer>
            </Grid>
        </Layout>
    );
};

export default CheckoutBooking;
