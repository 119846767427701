import React, {FC, useState, ReactNode, createContext, useContext} from 'react';
import {Area} from '../../../../models/booking.model';

export enum AreasContextKeys {
    Area = 'area',
}

export type AreasContextType = {
    area: Area | null;
    setArea: (newArea: Area | null) => void;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
    activeStep: number;
    setActiveStep: (step: number) => void;
};

export const AreasContext = createContext<AreasContextType>({
    area: null,
    setArea: () => {},
    isLoading: true,
    setIsLoading: () => {},
    activeStep: 0,
    setActiveStep: () => {},
});

export type AreasContextProviderProps = {
    children?: ReactNode;
};

export const AreasContextProvider: FC<AreasContextProviderProps> = (props) => {
    const [area, setArea] = useState<Area | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const values = {
        area,
        setArea,
        isLoading,
        setIsLoading,
        activeStep,
        setActiveStep,
    };

    return <AreasContext.Provider value={values}>{props.children}</AreasContext.Provider>;
};

export const useAreas = () => useContext(AreasContext);
