import React, {FC, ReactNode, createContext, useState} from 'react';
import {Club} from '../shared/types/Club.type';

export type AppContextType = {
    club: Club | null;
    setClub: (newClub: Club | null) => void;
    hasClubChanged: boolean;
    setHasClubChanged: (hasClubChanged: boolean) => void;
    newBookings: boolean;
    setNewBookings: (newBookings: boolean) => void;
    bookingUpdate: boolean;
    setBookingUpdate: (bookingUpdate: boolean) => void;
};

export const AppContext = createContext<AppContextType>({
    club: null,
    setClub: () => {},
    hasClubChanged: false,
    setHasClubChanged: () => {},
    newBookings: false,
    setNewBookings: () => {},
    bookingUpdate: false,
    setBookingUpdate: () => {},
});

export type AppContextProviderProps = {
    children?: ReactNode;
};

export const AppContextProvider: FC<AppContextProviderProps> = (props) => {
    const [club, setClub] = useState<Club | null>(() => {
        const storedClub = localStorage.getItem('clubAdmin.selectedClub');
        return storedClub ? JSON.parse(storedClub) : null;
    });
    const [hasClubChanged, setHasClubChanged] = useState<boolean>(false);
    const [newBookings, setNewBookings] = useState<boolean>(false);
    const [bookingUpdate, setBookingUpdate] = useState<boolean>(false);

    return (
        <AppContext.Provider
            value={{
                club,
                setClub,
                newBookings,
                setNewBookings,
                bookingUpdate,
                setBookingUpdate,
                hasClubChanged,
                setHasClubChanged,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};
