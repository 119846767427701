import {TFunction} from 'i18next';
import * as yup from 'yup';

export const validationHistorySchema = (t: TFunction) => {
    return yup.object({
        selectedArea: yup.string(),
        selectedSports: yup.array().of(yup.string()),
        startDate: yup.date().nullable(),
        endDate: yup.date().nullable().min(yup.ref('startDate'), t('validation.minEndDate')),
        search: yup.string(),
    });
};
