import styled, {css} from 'styled-components';
import {Box, Button} from '@mui/material';
import {Chip} from '@mui/material';

export const AreaSportContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

export const StyledChip = styled(Chip)`
    .MuiChip-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiChip-avatarColorPrimary {
        background: transparent !important;
    }

    &.Mui-disabled {
        opacity: 0.75 !important;
    }
`;

export const ReservationItem = styled.div`
    color: red;
    font-size: 0.9rem;
    margin-bottom: 1rem;
`;

export const AreaPrice = styled.p`
    flex: 1;
    margin: 0;
    display: flex;
    flex-shrink: 0;
    text-align: end;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const StyledButton = styled(Button).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'disabledAspect',
})<{disabledAspect?: boolean}>`
    width: 100%;
    box-shadow: none !important;
    max-height: 2.3rem;

    .MuiTouchRipple-root {
        display: none !important;
    }

    ${({disabledAspect}) =>
        disabledAspect &&
        css`
            background-color: lightgrey !important;
            color: #424242 !important;
        `}
`;
