import React, {FC, useState, ReactNode, createContext, useContext} from 'react';
import {Club} from '../../../../models/club.model';

export enum ClubsContextKeys {
    Club = 'club',
}

export type ClubsContextType = {
    club: Club | null;
    setClub: (newClub: Club | null) => void;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
    activeStep: number;
    setActiveStep: (step: number) => void;
};

export const ClubsContext = createContext<ClubsContextType>({
    club: null,
    setClub: () => {},
    isLoading: true,
    setIsLoading: () => {},
    activeStep: 0,
    setActiveStep: () => {},
});

export type ClubsContextProviderProps = {
    children?: ReactNode;
};

export const ClubsContextProvider: FC<ClubsContextProviderProps> = (props) => {
    const [club, setClub] = useState<Club | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const values = {
        club,
        setClub,
        isLoading,
        setIsLoading,
        activeStep,
        setActiveStep,
    };

    return <ClubsContext.Provider value={values}>{props.children}</ClubsContext.Provider>;
};

export const useClubs = () => useContext(ClubsContext);
