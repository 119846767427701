import React from 'react';
import {StyledAlert, StyledList} from './PaymentMethods.styles';
import {useTranslation} from 'react-i18next';
import {ClubPaymentMethod} from '../../../../shared/types/ClubPaymentMethod.type';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Payment from '../../../../assets/icons/payment.svg';

export const PaymentMethods = ({paymentMethods, variant}: {paymentMethods: ClubPaymentMethod[]; variant: string}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Payment} alt="" />
                {t(`club.headers.paymentMethods`)}
            </Header>
            {!paymentMethods?.length ? (
                <StyledAlert severity="info">{t('club.paymentMethods.emptyList')}</StyledAlert>
            ) : (
                <StyledList>
                    {paymentMethods.map((paymentMethod) => (
                        <li key={paymentMethod.name}>{t(`club.paymentMethods.${paymentMethod.name.toLowerCase()}`)}</li>
                    ))}
                </StyledList>
            )}
        </StyledBox>
    );
};
