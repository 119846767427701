import HandBall from '../assets/icons/sports/andebol.svg';
import Basket from '../assets/icons/sports/basquetebol.svg';
import Fut5 from '../assets/icons/sports/fut5.svg';
import Fut7 from '../assets/icons/sports/fut7.svg';
import Soccer from '../assets/icons/sports/futebol.svg';
import Futsal from '../assets/icons/sports/futsal.svg';
import Hockey from '../assets/icons/sports/hoquei_patins.svg';
import Padel from '../assets/icons/sports/padel.svg';
import Squash from '../assets/icons/sports/squash.svg';
import Tennis from '../assets/icons/sports/tenis.svg';
import Volley from '../assets/icons/sports/voleibol.svg';

export const sportIconsMapping: Record<string, string> = {
    andebol: HandBall,
    basquetebol: Basket,
    fut5: Fut5,
    fut7: Fut7,
    futebol: Soccer,
    futsal: Futsal,
    hoquei_patins: Hockey,
    padel: Padel,
    squash: Squash,
    tenis: Tennis,
    voleibol: Volley,
};
