import {useState} from 'react';
import {AppService} from '../../../services/app.service';

export const useReservation = () => {
    const api_service = new AppService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [booking, setBooking] = useState<any>(undefined);

    const fetch_booking = (bookingId: string) => {
        api_service
            .api_getBookingById(bookingId)
            .then((res) => {
                if (res.status !== 200) throw new Error();

                setBooking(res.data);
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    const setAsDeleted = () => {
        setBooking((prev: any) => ({...prev, canceled: true}));
    };

    return {
        isLoading,
        booking,
        actions: {fetch_booking, setAsDeleted},
    };
};
