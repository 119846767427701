import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import React, {Suspense, lazy} from 'react';
import Admin from './pages/admin/components/Admin';
import ShareBooking from './pages/ShareBooking';
import VerifyAccount from './pages/verifyAccount/VerifyAccount';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import PlayerLogin from './pages/player/components/Login';
import PlayerRegister from './pages/player/components/Register';
import ClubAdminLogin from './pages/club-admin/Login';
import BookingsSearch from './pages/club-admin/bookings/components/BookingsSearch';
import PlayerBookings from './pages/player/Bookings';
import {ClubsContextProvider} from './pages/club-admin/club-details/contexts/ClubsContext';
import {BookingsContextProvider} from './pages/club-admin/bookings/contexts/BookingsContext';
import {Club} from './pages/club/components/club/Club';
import {ClubContextProvider} from './pages/club/context/ClubContext';
import {Booking} from './pages/booking/components/createBooking/Booking';
import {BookingConfirmation} from './pages/booking/components/bookingConfirmation/BookingConfirmation';
import {UserContextProvider} from './pages/edit-account/contexts/UserContext';
import TermsAndConditions from './pages/TermsAndConditions';
import ExternalProvider from './pages/player/ExternalProvider';
import {CommonContextProvider} from './context/CommonContext';
import {HomePageContextProvider} from './pages/homepage/context/HomePageContect';
import HomePage from './pages/homepage/components/homepage/HomePage';
import Search from './pages/search-page/components/search/Search';
import {SearchContextProvider} from './pages/search-page/contexts/SearchContext';
import {AreasContextProvider} from './pages/club-admin/club-areas/contexts/AreasContext';
import CheckoutBooking from './pages/booking/components/checkoutBooking/CheckoutBooking';
import HistoryData from './pages/club-admin/history/HistoryData';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import {Spinner} from './components/Common/Spinner/Spinner';
import {FeatureToggleBaseComponent} from './components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from './shared/enums/FeatureToggles.enum';
import {AppContextProvider} from './context/AppContext';
import AreaManagement from './pages/club-admin/club-areas/components/AreaManagement';
import {FeatureTogglesContextProvider} from './context/FeatureTogglesContext';
import {AvailableSlotsPage} from './pages/available-slots-page/AvailableSlotsPage';

const CancelBooking = lazy(() => import('./pages/club-admin/bookings/components/CancelBooking'));
const ClubDetails = lazy(() => import('./pages/club-admin/club-details/components/ClubDetails'));
const ClubAreas = lazy(() => import('./pages/club-admin/club-areas/components/ClubAreas'));
const EditAccount = lazy(() => import('./pages/edit-account/components/EditAccount'));
const Success = lazy(() => import('./pages/club-admin/club-details/components/Success'));
// Create a client
const queryClient = new QueryClient();

function AppRoutes() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    <FeatureTogglesContextProvider>
                        <CommonContextProvider>
                            <SearchContextProvider>
                                <UserContextProvider>
                                    <Routes>
                                        <Route
                                            path="/"
                                            index
                                            element={
                                                <HomePageContextProvider>
                                                    <HomePage />
                                                </HomePageContextProvider>
                                            }
                                        />
                                        <Route path="/search" element={<Search />} />
                                        <Route
                                            path="/booking"
                                            element={
                                                <ClubContextProvider>
                                                    <Booking />
                                                </ClubContextProvider>
                                            }
                                        />
                                        <Route path="/checkout/booking" element={<CheckoutBooking />} />
                                        <Route path="/booking-details/:id" element={<BookingConfirmation />} />
                                        <Route
                                            path="club/:clubId"
                                            element={
                                                <ClubContextProvider>
                                                    <Club />
                                                </ClubContextProvider>
                                            }
                                        />

                                        <Route path="club/:clubId/available-slots" element={<AvailableSlotsPage />} />
                                        <Route path="/login" element={<PlayerLogin />} />
                                        <Route path="/admin/login" element={<ClubAdminLogin />} />
                                        <Route path="/signup" element={<PlayerRegister />} />
                                        <Route path="/logout" element={<Logout />} />
                                        <Route path="/verify" element={<VerifyAccount />} />
                                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                                        <Route path="/external-provider" element={<ExternalProvider />} />
                                        <Route path="/booking/share/:id" element={<ShareBooking />} />
                                        <Route path="/404" element={<NotFound />} />

                                        <Route
                                            path="/admin"
                                            element={<ProtectedRoute allowedRoles={['admin']} redirect="/" />}
                                        >
                                            <Route index element={<Admin />} />
                                        </Route>

                                        <Route
                                            path="/club-admin"
                                            element={<ProtectedRoute allowedRoles={['club-admin']} redirect="/" />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <BookingsSearch />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club-details/:id?"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <ClubsContextProvider>
                                                            <ClubDetails />
                                                        </ClubsContextProvider>
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club-areas/:id?"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <AreasContextProvider>
                                                            <ClubContextProvider>
                                                                <ClubAreas />
                                                            </ClubContextProvider>
                                                        </AreasContextProvider>
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="edit-account"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <UserContextProvider>
                                                            <EditAccount />
                                                        </UserContextProvider>
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club/:clubId/bookings"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <BookingsSearch />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club/:clubId/booking/:bookingId/cancel"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <BookingsContextProvider>
                                                            <CancelBooking />
                                                        </BookingsContextProvider>
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club/:clubId/areas"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <AreaManagement />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="club/:clubId/history"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <ClubsContextProvider>
                                                            <FeatureToggleBaseComponent
                                                                featureToggle={FeatureToggles.RESERVATION_HISTORY}
                                                                redirect="/club-admin"
                                                            >
                                                                <HistoryData />
                                                            </FeatureToggleBaseComponent>
                                                        </ClubsContextProvider>
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="success"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <Success />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>

                                        <Route
                                            path="/player"
                                            element={<ProtectedRoute allowedRoles={['player']} redirect="/login" />}
                                        >
                                            <Route path="bookings" element={<PlayerBookings />} />
                                            <Route
                                                path="edit-account"
                                                element={
                                                    <Suspense fallback={<Spinner />}>
                                                        <EditAccount />
                                                    </Suspense>
                                                }
                                            />
                                        </Route>
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </UserContextProvider>
                            </SearchContextProvider>
                        </CommonContextProvider>
                    </FeatureTogglesContextProvider>
                </AppContextProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default AppRoutes;
