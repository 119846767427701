import React from 'react';
import {Box, DialogActions} from '@mui/material';
import {AppButton} from '../../components/Common/Buttons/AppButton';
import {Layout} from '../../components/Common/Layout/Layout';
import {GoBackButton} from '../../components/Common/GoBackButton';
import {useTranslation} from 'react-i18next';
import {LoginForm} from '../../components/LoginForm/LoginForm';
import {getURLWithReturn} from '../../utils/returnUrl';
import {useSearchParams} from 'react-router-dom';
import {StyledBackgroundImage} from '../verifyAccount/VerifyAccount.styles';
import {ClubAdminRegister} from '../../components/ClubAdminRegister/ClubAdminRegister';
import {useCommon} from '../../context/CommonContext';
import {StyledDialogContent} from './bookings/components/Bookings.styles';
import {StyledAppButton} from '../../components/CalendarNewBookingModal/CalendarNewBooking.styles';

const Login = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const return_url = searchParams.get('return_url');
    const {setModal} = useCommon();

    return (
        <>
            <Layout maxWidth="sm">
                <Box
                    sx={{
                        width: '100%',
                        padding: '1rem 0 5rem',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{display: 'flex'}}>
                        <GoBackButton to={getURLWithReturn('/login', return_url)} />
                    </Box>
                    <img
                        src="/logo_aveiro_tech_city_white.png"
                        alt="FreeCourts: Courts for everyone"
                        style={{
                            margin: '24px auto',
                            height: '82px',
                            width: 'fit-content',
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            marginTop: 'auto',
                        }}
                    >
                        <LoginForm userType="club-admin" redirect="/club-admin" />
                        <AppButton
                            variant="outlined"
                            color="white"
                            onClick={() => {
                                setModal({
                                    open: true,
                                    title: t('clubAdmin.requestAccount.title'),
                                    children: (
                                        <>
                                            <StyledDialogContent dividers>
                                                <ClubAdminRegister />
                                            </StyledDialogContent>
                                            <DialogActions>
                                                <StyledAppButton
                                                    id="cancel"
                                                    variant="outlined"
                                                    type="reset"
                                                    onClick={() => setModal({open: false, children: <></>})}
                                                >
                                                    {t('cancel')}
                                                </StyledAppButton>
                                                <StyledAppButton
                                                    variant="contained"
                                                    type="submit"
                                                    form="form-clubAdminRegister"
                                                >
                                                    {t('clubAdmin.requestAccount.submitButton')}
                                                </StyledAppButton>
                                            </DialogActions>
                                        </>
                                    ),
                                });
                            }}
                        >
                            {t('login.register')}
                        </AppButton>
                    </Box>
                </Box>
            </Layout>

            <StyledBackgroundImage src="/images/swimming.jpg" />
        </>
    );
};

export default Login;
