import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {Organization} from '../models/organization.model';
import {UserData} from '../shared/types/UserFilter.type';

dayjs.extend(utc);
dayjs.extend(timezone);

// Separates array values based on filter
export const partition = (array: any, callback: any) => {
    return array.reduce(
        (acc: any, e: any) => {
            acc[callback(e) ? 0 : 1].push(e);
            return acc;
        },
        [[], []]
    );
};

// Verifies if selected domais with organization object
export const findMatches = (selectedDomains: any, organizationObj: any) => {
    const objectMatch = organizationObj.filter((object: Organization) => {
        const smallCaps = object.domain.toLowerCase();
        return selectedDomains.includes(smallCaps);
    });

    const ids = objectMatch.map((obj: any) => obj.id);

    return ids;
};

// Compares the values of 2 string array
export const compareValues = (a: any, b: any) => {
    if (a.length !== b.length) return false;
    else {
        for (var i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }
};

export const findAreaNameById = (id: string, objectArray: any) => {
    const matchingObject = objectArray.find((obj: any) => obj.id === id);
    return matchingObject ? matchingObject.name : undefined;
};

export const setUsersNameFilter = (data: UserData[]) => {
    const setUsers = new Set<string>();

    data.forEach((item) => {
        if (item.name && !setUsers.has(item.name)) {
            setUsers.add(item.name);
        }
        if (item.payedBy && item.payedBy.name && !setUsers.has(item.payedBy.name)) {
            setUsers.add(item.payedBy.name);
        }
        if (item.canceledBy && item.canceledBy.name && !setUsers.has(item.canceledBy.name)) {
            setUsers.add(item.canceledBy.name);
        }
        if (item.CreatedBy && item.CreatedBy.name && !setUsers.has(item.CreatedBy.name)) {
            setUsers.add(item.CreatedBy.name);
        }
    });

    return Array.from(setUsers);
};

export const getAreaCategories = (areas: any, id: string) => {
    const foundArea = areas?.find((area: any) => area.id === id);

    // Check if the area with the given ID is found
    if (foundArea) {
        return foundArea.AreaCategory;
    } else {
        return null;
    }
};

export const getSelectedAreaInfo = (areas: any, id: string) => {
    const foundArea = areas?.find((area: any) => area.id === id);

    // Check if the area with the given ID is found
    if (foundArea) {
        return foundArea;
    } else {
        return null;
    }
};

export const getOpeningHoursForWeekday = (openingHours: any, targetWeekday: string) => {
    const targetDay = targetWeekday.toUpperCase();

    const matchingOpening = openingHours.find((hours: any) => hours.openDay === targetDay);

    if (matchingOpening) {
        const fromTime = dayjs(matchingOpening.from).utc().format('HH:mm');
        const toTime = dayjs(matchingOpening.to).utc().format('HH:mm');

        return {from: fromTime, to: toTime};
    }

    return []; // No matching opening hours for the specified weekday
};

export const generateOpeningHours = (areaSchedules: any, weekday: string) => {
    // Find the schedule for the specified weekday
    const schedule = areaSchedules?.find((schedule: any) => schedule.openDay === weekday.toUpperCase());

    // If no specific schedule is found, use default hours
    const defaultHours = {from: '1970-01-01T00:00:00.000Z', to: '1970-01-01T23:00:00.000Z'};
    const hours = schedule ? {from: schedule.from, to: schedule.to} : defaultHours;

    // Convert hours to numbers
    const fromHour = dayjs(hours.from).utc().format('HH');
    const toHour = dayjs(hours.to).utc().format('HH');

    return {
        from: fromHour,
        to: toHour,
    };
};
