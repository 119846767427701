import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Avatar,
    Drawer,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Stack,
    Typography,
    Skeleton,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from 'react-i18next';
import useUser from '../../pages/edit-account/hooks/useUser';
import {StyledIconButton} from '../Common/Layout/Layout.styles';

export type PlayerMenuProps = {
    open: boolean;
    onClose: () => void;
};

export const PlayerMenu = ({open, onClose}: PlayerMenuProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user, isLoading} = useUser();

    const navigateTo = (to: string) => {
        navigate(to);
        onClose();
    };

    return (
        <Drawer anchor="left" variant="temporary" open={open} onClose={onClose}>
            <Stack
                sx={{
                    padding: '1rem 0',
                }}
            >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <StyledIconButton onClick={onClose}>
                        <ArrowBackIcon color="primary" />
                    </StyledIconButton>
                    <img
                        onClick={() => navigate('/')}
                        src="/logo_aveiro_tech_city.png"
                        alt="FreeCourts: Courts for everyone"
                        height={52}
                    />
                    <span style={{width: '40px'}}></span>
                </Stack>

                <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" mt="2rem" p="0 1rem">
                    {isLoading ? (
                        <>
                            <Skeleton variant="circular" width={48} height={48} />
                            <Skeleton width="100%" height={32} />
                            <Skeleton width="100%" height={24} />
                        </>
                    ) : (
                        <>
                            <Avatar
                                src={user?.photoUrl}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    bgcolor: '#1976d2',
                                }}
                            />
                            <Typography variant="h6" sx={{marginTop: 1}}>
                                {user?.name}
                            </Typography>
                            <Typography variant="body1">{user?.email}</Typography>
                        </>
                    )}
                </Stack>

                <MenuList sx={{marginTop: '1rem'}}>
                    <MenuItem onClick={() => navigateTo('/')}>
                        <ListItemText>{t('playerMenu.home')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/player/bookings')}>
                        <ListItemText>{t('bookings')}</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => navigateTo('/player/edit-account')}>
                        <ListItemText>{t('playerMenu.editAccount')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/terms-and-conditions')}>
                        <ListItemText>{t('termsAndConditions')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => navigateTo('/logout')} color="primary">
                        <ListItemIcon>
                            <LogoutIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography color="primary">{t('playerMenu.logout')}</Typography>
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Stack>
        </Drawer>
    );
};
