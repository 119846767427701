import {Alert} from '@mui/material';
import styled from 'styled-components';

export const Image = styled.img`
    width: 268px;
    height: 268px;
    object-fit: cover;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledAlert = styled(Alert)`
    max-width: 420px !important;
`;
