import React, {FC} from 'react';
import spinner from '../../../assets/images/spinner.svg';
import {Container} from '@mui/material';

export type SpinnerProps = {
    fullPage?: boolean;
};

export const Spinner: FC<SpinnerProps> = ({fullPage}) => {
    return (
        <Container className={fullPage ? 'fullPage' : 'container'}>
            <img src={spinner} alt="spinner" />
        </Container>
    );
};
