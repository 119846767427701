import {ContentCopy, WhatsApp, Twitter, Facebook} from '@mui/icons-material';
import {Theme, useMediaQuery, useTheme} from '@mui/material';
import {t} from 'i18next';
import React from 'react';
import {AppShareIcon} from '../Common/Buttons/AppShareIcon';
import {useCommon} from '../../context/CommonContext';
import ShareIcon from '@mui/icons-material/Share';
export type ShareButtonsProps = {
    currUrl: string;
    message: string;
};
export const ShareButtons = ({currUrl, message}: ShareButtonsProps) => {
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const {setSnackbar} = useCommon();
    const handleShareMessage = () => {
        if (!navigator.share) {
            setSnackbar({
                open: true,
                severity: 'error',
                children: t('shareBooking.cannotShare'),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
            });
        }
        navigator.share({
            url: currUrl,
            title: t('shareBooking.share'),
            text: message,
        });
    };
    const handleCopyLink = () => {
        navigator.clipboard.writeText(currUrl);
        setSnackbar({
            open: true,
            severity: 'info',
            children: t('shareBooking.copied'),
            setOpen: () => {
                setSnackbar({open: false, children: null, setOpen: () => {}});
            },
        });
    };
    const handleExternalLink = (link: string) => window.open(link, '_blank');
    return (
        <>
            <AppShareIcon
                icon={<ContentCopy fontSize="inherit" />}
                text={t('shareBooking.copyLink')}
                onClick={handleCopyLink}
            />
            {!isLargeScreen && (
                <AppShareIcon
                    icon={<ShareIcon fontSize="inherit" />}
                    text={t('shareBooking.shareMessage')}
                    onClick={handleShareMessage}
                />
            )}
            <AppShareIcon
                icon={<WhatsApp fontSize="inherit" />}
                text={t('shareBooking.shareOnWhatsapp')}
                onClick={() => handleExternalLink(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`)}
            />
            <AppShareIcon
                icon={<Twitter fontSize="inherit" />}
                text={t('shareBooking.shareOnTwitter')}
                onClick={() =>
                    handleExternalLink(`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`)
                }
            />
            <AppShareIcon
                icon={<Facebook fontSize="inherit" />}
                text={t('shareBooking.shareOnFacebook')}
                onClick={() =>
                    handleExternalLink(
                        `https://facebook.com/sharer/share?u=${encodeURIComponent(currUrl)}&t=${encodeURIComponent(
                            message
                        )}`
                    )
                }
            />
        </>
    );
};
