import {TFunction} from 'i18next';
import * as yup from 'yup';
import 'yup-phone-lite';
import {emailRegex, passwordRegex} from '../utils/validation';

export const validationRegisterSchema = (t: TFunction) => {
    return yup.object({
        name: yup
            .string()
            .required(t('register.name.required'))
            .min(3, t('validation.toShort', {char: '3'}))
            .max(50, t('validation.toLong', {char: '50'})),
        email: yup
            .string()
            .matches(emailRegex, t('validation.requiredValidEmail'))
            .required(t('validation.requiredEmail')),
        password: yup
            .string()
            .matches(passwordRegex, t('register.password.invalid'))
            .required(t('register.password.required')),
        phone: yup
            .string()
            .matches(/^[0-9]+$/, t('validation.phoneOnlyNumber'))
            .phone('PT', t('validation.requiredValidPhone')),
        nif: yup
            .string()
            .matches(/^[0-9]+$/, t('validation.fiscalNumberOnlyNumber'))
            .min(9, t('validation.fiscalNumberMinNumber'))
            .max(9, t('validation.fiscalNumberMaxNumber')),
        address: yup
            .string()
            .min(3, t('validation.toShort', {char: '3'}))
            .max(200, t('validation.toLong', {char: '200'})),
    });
};
