import React from 'react';
import {Box, Stack, Theme, useMediaQuery, useTheme} from '@mui/material';
import {AppButton} from '../Buttons/AppButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const NotAuthenticatedNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const theme: Theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{padding: '1rem'}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <img
                    onClick={() => navigate('/')}
                    src="/logo_aveiro_tech_city.png"
                    alt="FreeCourts: Courts for everyone"
                    height={52}
                />
                {isSmallScreen && (
                    <AccountCircleIcon
                        sx={{fontSize: 40}}
                        color="primary"
                        onClick={() =>
                            navigate(
                                `/login?return_url=${encodeURIComponent(`${location.pathname}${location.search}`)}`
                            )
                        }
                    />
                )}
                {!isSmallScreen && (
                    <AppButton
                        variant="contained"
                        sx={{width: '180px'}}
                        onClick={() =>
                            navigate(
                                `/login?return_url=${encodeURIComponent(`${location.pathname}${location.search}`)}`
                            )
                        }
                    >
                        {t('signInSignUp')}
                    </AppButton>
                )}
            </Stack>
        </Box>
    );
};

export default NotAuthenticatedNavbar;
