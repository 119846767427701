import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import WcIcon from '@mui/icons-material/Wc';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import {LockerRoomType} from '../../../../models/clubDetails.model';
import {IconBox, StyledLabel} from '../createBooking/Booking.styles';

interface LockerRoomDetailsProps {
    lockerRoomType: LockerRoomType;
}

const LockerRoomDetails: FC<LockerRoomDetailsProps> = ({lockerRoomType}) => {
    const {t} = useTranslation();

    switch (lockerRoomType) {
        case 'BOTH_LOCKER_ROOM':
            return (
                <IconBox>
                    <WcIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                    <StyledLabel>{t('booking.lockerRoomType.both')}</StyledLabel>
                </IconBox>
            );
        case 'WOMEN_LOCKER_ROOM':
            return (
                <IconBox>
                    <WomanIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                    <StyledLabel>{t('booking.lockerRoomType.female')}</StyledLabel>
                </IconBox>
            );
        case 'MIXED_LOCKER_ROOM':
            return (
                <IconBox>
                    <WcIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                    <StyledLabel>{t('booking.lockerRoomType.mixed')}</StyledLabel>
                </IconBox>
            );
        case 'MEN_LOCKER_ROOM':
            return (
                <IconBox>
                    <ManIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                    <StyledLabel>{t('booking.lockerRoomType.male')}</StyledLabel>
                </IconBox>
            );
        default:
            return (
                <IconBox>
                    <DoNotDisturbAltIcon sx={{width: '16px', height: '16px'}} color="disabled" />
                    <StyledLabel>{t('booking.lockerRoomType.noLockerRoom')}</StyledLabel>
                </IconBox>
            );
    }
};

export default LockerRoomDetails;
