import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import {useTranslation} from 'react-i18next';
import ImageIcon from '@mui/icons-material/Image';
import {Image, StyledAlert} from './ImageList.styles';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Slider from 'react-slick';

export const MUImageList = ({photos, variant}: {photos: any; variant: string}) => {
    const {t} = useTranslation();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <StyledBox variant={variant}>
            <Header>
                <ImageIcon fontSize="small" className="mr-1" />
                {t('club.headers.clubGallery')}
            </Header>
            {!photos?.length ? (
                <StyledAlert severity="info" className="alert">
                    {t('club.gallery.emptyList')}
                </StyledAlert>
            ) : (
                <Slider {...settings}>
                    {photos.map((item: any) => (
                        <ImageListItem key={item.createdAt} sx={{padding: '2px'}}>
                            <Image src={item.photoUrl} alt="" loading="lazy" />
                        </ImageListItem>
                    ))}
                </Slider>
            )}
        </StyledBox>
    );
};
