import {Button, ListItemIcon, ListItemText, MenuItem, Typography} from '@mui/material';
import React, {FC, MouseEvent, useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useNavigate} from 'react-router-dom';
import {t} from 'i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import {MUIMenu, MUIPersonIcon} from './Navbar.styles';

export const UserMenu: FC = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button variant="text" onClick={handleMenu} color="white" endIcon={<ExpandMoreIcon />}>
                <MUIPersonIcon />
            </Button>
            <MUIMenu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => navigate('/club-admin/edit-account')}>
                    <ListItemText>{t('clubAdmin.menu.editAccount')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/terms-and-conditions')}>
                    <ListItemText>{t('termsAndConditions')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate('/logout')} color="primary">
                    <ListItemIcon>
                        <LogoutIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography color="primary">{t('clubAdmin.menu.logout')}</Typography>
                    </ListItemText>
                </MenuItem>
            </MUIMenu>
        </>
    );
};
