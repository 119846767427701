export const passwordExpression = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$';
export const passwordRegex = new RegExp(passwordExpression);
export const isPasswordValid = (password: string) => {
    return passwordRegex.test(password);
};

export const emailRegex = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const isEmailValid = (email: string) => emailRegex.test(email);

export const postalCodeRegex = /^\d{4}(?:[-\s]\d{3})?$/g;
export const isPostalCodelValid = (postalCode: string) => postalCodeRegex.test(postalCode);

export const swiftRegex = /^[A-Z]{4}PT[A-Z]{2}$/g;
export const isSwiftValid = (swift: string) => swiftRegex.test(swift);

export const ibanRegex = /^(?:(?:[A-Z]{2}\d{2})(\d{4})(\d{4})(\d{11})(\w{0,16})|(\d{4})(\d{4})(\d{11})(\w{0,16}))$/g;
export const isIbanValid = (iban: string) => ibanRegex.test(iban);
