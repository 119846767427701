import {Alert} from '@mui/material';
import styled from 'styled-components';

export const StyledList = styled.ul`
    margin: 0;
    padding-inline-start: 2rem;
`;

export const StyledAlert = styled(Alert)`
    max-width: 420px !important;
`;
