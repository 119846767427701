import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CardContent, Skeleton, Stack, Typography} from '@mui/material';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {AppService} from '../services/app.service';
import {BookingDetails} from './booking/components/bookingDetails/BookingDetails';
import dayjs from 'dayjs';
import {AppButton} from '../components/Common/Buttons/AppButton';
import {StyledCard, StyledCardLabel} from './booking/components/createBooking/Booking.styles';
import {ShareLinkModal} from '../components/ShareLinkModal';
import {useAuth} from '../hooks/useAuth';
import {TFunction} from 'i18next';
import {Layout} from '../components/Common/Layout/Layout';

const buildString = (t: TFunction, names: string[]) => {
    if (!names) return '';
    if (names.length === 0) return t('shareBooking.interestedString.0');
    if (names.length === 1) return t('shareBooking.interestedString.1b').replace('{{name}}', names[0]);
    if (names.length === 2)
        return t('shareBooking.interestedString.2').replace('{{name1}}', names[0]).replace('{{name2}}', names[1]);
    return t('shareBooking.interestedString.3')
        .replace('{{name1}}', names[0])
        .replace('{{name2}}', names[1])
        .replace('{{number}}', (names.length - 2).toString());
};

const separateIdAndName = (arr: any[]) => {
    if (!arr) return {ids: [], names: []};
    return arr.reduce(
        (result: any, item: any) => {
            result.ids.push(item.id);
            result.names.push(item.name);
            return result;
        },
        {ids: [], names: []}
    );
};

const ShareBooking = () => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const {id: shareId} = useParams();
    const {t, i18n} = useTranslation();
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [booking, setBooking] = useState<any>(null);
    const [currUser, setCurrUser] = useState<any>(null);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const appService = new AppService();
        appService
            .api_getBookingByShareId({shareId})
            .then((res) => {
                if (res.status !== 200) throw res;

                setBooking(res.data);
                if (searchParams.get('action') === 'share') setShareModalOpen(true);
            })
            .catch((error) => {
                console.log(error);
                navigate('/');
            });
        if (!isAuthenticated) return;
        appService
            .api_getUser()
            .then((res) => {
                if (res.status !== 200) throw res;

                setCurrUser(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [shareId]);

    const handleChangeInInterest = (isInterested: boolean) => {
        setLoading(true);
        new AppService()
            .api_setInterestInBooking({id: booking.id, isInterested})
            .then((res) => {
                if (![200, 201, 204].includes(res.status)) throw res;

                if (isInterested) {
                    setBooking((prev: any) => ({...prev, interested: [...prev.interested, currUser]}));
                } else {
                    const userList = booking.interested.filter((a: any) => a.id !== currUser.id);
                    setBooking((prev: any) => ({...prev, interested: userList}));
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    const handleInterestClick = (isInterested: boolean) => {
        if (!isAuthenticated) {
            navigate(`/login?return_url=${encodeURIComponent(`/booking/share/${shareId}`)}`);
            return;
        }
        handleChangeInInterest(isInterested);
    };

    const interested = separateIdAndName(booking?.interested);
    const isInterested = interested.ids.includes(currUser?.id);
    const interestedPeopleString = buildString(t, [booking?.name, ...interested.names]);

    return (
        <Layout headerType="menu" maxWidth="sm">
            <Stack spacing={2} mt={2} mb={5}>
                {booking ? (
                    <>
                        <Typography variant="h5" sx={{textAlign: 'center'}}>
                            {t('shareBooking.bookingOf', {name: booking.name}).toUpperCase()}
                        </Typography>
                        {booking.canceled && (
                            <Typography variant="h5" color="error" sx={{textAlign: 'center'}}>
                                {t('canceled').toUpperCase()}
                            </Typography>
                        )}
                        <BookingDetails
                            club={{
                                name: booking.club.name,
                                mobilePhone: booking.club.mobilePhone,
                                email: booking.club.email,
                                carParkType: booking.club.carParkType,
                                lockerRoomType: booking.club.lockerRoomType,
                                address: {
                                    address: booking.club.address?.address,
                                    postalCode: booking.club.address?.postalCode,
                                    district: booking.club.address?.district,
                                    lat: booking.club.address?.lat,
                                    lng: booking.club.address?.lng,
                                },
                                photos: booking.club.photos.map((photo: any) => ({
                                    photoUrl: photo.photoUrl,
                                })),
                            }}
                            area={{
                                name: booking.area.name,
                                description: booking.area.description,
                                price: booking.price,
                                privacy: booking.area.privacy,
                                environment: booking.area.environment,
                                photos: booking.area.photos.map((photo: any) => ({
                                    photoUrl: photo.photoUrl,
                                })),
                            }}
                            category={booking.category}
                            date={dayjs(booking.day).locale(i18n.language).utc().format('ddd, DD MMMM YYYY')}
                            from={dayjs(booking.from).utc().format('HH:mm')}
                            to={dayjs(booking.to).utc().format('HH:mm')}
                        />
                        {currUser?.id === booking.userId ? (
                            <StyledCard sx={{marginBottom: '240px'}}>
                                <CardContent>
                                    <Stack spacing={1}>
                                        <StyledCardLabel>{t('booking.label.interested')}</StyledCardLabel>
                                        <Typography variant="body1">
                                            {[booking.name, ...interested.names].join(', ')}
                                        </Typography>
                                    </Stack>
                                </CardContent>
                            </StyledCard>
                        ) : (
                            <>
                                {!booking.canceled && (
                                    <StyledCard sx={{marginBottom: '240px'}}>
                                        <CardContent>
                                            <Stack
                                                spacing={{xs: 1, sm: 3}}
                                                alignItems={'center'}
                                                direction={{xs: 'column', sm: 'row'}}
                                                useFlexGap
                                            >
                                                {!isInterested ? (
                                                    <AppButton
                                                        variant="outlined"
                                                        sx={{width: 'auto'}}
                                                        onClick={() => handleInterestClick(true)}
                                                        disabled={loading}
                                                    >
                                                        {t('shareBooking.imInterested')}
                                                    </AppButton>
                                                ) : (
                                                    <AppButton
                                                        variant="contained"
                                                        sx={{width: 'auto'}}
                                                        onClick={() => handleInterestClick(false)}
                                                        disabled={loading}
                                                    >
                                                        {t('shareBooking.imNotInterested')}
                                                    </AppButton>
                                                )}
                                                <Typography sx={{flex: 2}}>{interestedPeopleString}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </StyledCard>
                                )}
                            </>
                        )}
                        {!booking.canceled && (
                            <>
                                <AppButton variant="contained" onClick={() => setShareModalOpen(true)}>
                                    {t('shareBooking.shareLink')}
                                </AppButton>
                                <ShareLinkModal
                                    booking={booking}
                                    open={shareModalOpen}
                                    onClose={() => setShareModalOpen(false)}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Skeleton variant="rounded" width="100%" height={32} />
                        <Skeleton variant="rounded" width="100%" height={264} />
                        <Skeleton variant="rounded" width="100%" height={332} />
                        <Skeleton variant="rounded" width="100%" height={84} />
                        <Skeleton variant="rounded" width="100%" height={44} />
                    </>
                )}
            </Stack>
        </Layout>
    );
};

export default ShareBooking;
