import dayjs from 'dayjs';

export const mountQueryString = (inputParams: Record<string, string> = {}) => {
    const params = {...inputParams};
    if (!('date' in params)) {
        params.date = `${dayjs().hour(0).minute(0).second(0).unix()}`;
    }

    const timeStamp = getNextHalfAnHour();
    params.time = `${timeStamp}`;

    const defaultTimeUnit = `3600`;
    params.timeUnit = defaultTimeUnit;

    return `?${Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')}`;
};

export const getNextHalfAnHour = () => {
    let time = dayjs().add(2, 'minutes');
    const minutes = time.minute();
    const roundedMinutes = Math.ceil(minutes / 30) * 30;
    if (roundedMinutes === 60) {
        time = time.add(1, 'hour').minute(0);
    } else {
        time = time.minute(roundedMinutes);
    }

    return dayjs.utc('1970-01-01').hour(time.hour()).minute(time.minute()).diff(dayjs.utc('1970-01-01'), 'second');
};
