import {Box} from '@mui/material';
import styled from 'styled-components';

export const FileUploadContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: center;
`;

export const InputBox = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
`;

export const FilesBox = styled(Box)`
    text-align: center;
`;

export const ImagePreview = styled.img`
    max-width: 100%;
`;

export const ImageSizeLabel = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: var(--color-primary);
    margin: 0;
`;
