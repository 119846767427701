import {Alert, Box, DialogActions, DialogContent, FormControl, MenuItem, Typography} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {Select} from '../../../../components/Common/Select/Select';
import {Input} from '../../../../components/Common/Input/TextField/Input';

export const MobileFilter = styled(Box)`
    display: none;
    flex-direction: row;
    gap: 0.75rem;
    flex-wrap: wrap;
    align-item: center;
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        display: flex;
    }
`;

export const DesktopFilter = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isFilterOpen',
})<{isFilterOpen?: boolean}>`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 1rem;

    @media (max-width: 600px) {
        display: ${({isFilterOpen}) => (isFilterOpen ? 'flex' : 'none')};
`;

export const FilterButton = styled(AppButton)`
    width: 114px;
    max-height: 58px;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
        max-height: 42px;
    }
`;

export const ButtonView = styled(AppButton)`
    width: 117px;
    max-height: 58px;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
        max-height: 42px;
    }
`;

export const ButtonCalendarView = styled(AppButton)`
    width: fit-content;
    max-height: 58px !important;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const SelectClub = styled(Select)`
    background-color: transparent;
    & .MuiOutlinedInput-notchedOutline {
        box-shadow: none;
    }
`;

export const SelectGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-bottom: 32px;
    margin-top: 15px;
`;

export const SelectClubBox = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const StyledAlert = styled(Alert)`
    width: 100%;
`;

export const StyledContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledError = styled(Typography)`
    color: #d32f2f;
    font-size: 12px !important;
    margin: 3px 14px 0 14px !important;
`;

export const TimeBox = styled(Box)`
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    @media (max-width: 600px) {
        display: flex;
        text-align: center;
        flex-direction: column-reverse;
    }
`;

export const StyledSearchBox = styled(Box)`
    width: 343px !important;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledSelectBox = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const StyledDialogContent = styled(DialogContent)`
    padding: 0 !important;
`;
export const StyledDialogActions = styled(DialogActions)`
    justify-content: space-between !important;
`;
