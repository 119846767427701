import {TFunction} from 'i18next';
import * as yup from 'yup';
import 'yup-phone-lite';
import {passwordRegex} from '../utils/validation';

export const validationResetPasswordSchema = (t: TFunction) => {
    return yup.object({
        newPassword: yup
            .string()
            .required(t('validation.newPassword'))
            .matches(passwordRegex, t('register.password.invalid')),
        confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], t('validation.confirmPassword')),
        verificationCode: yup.string().required(t('validation.requiredValidationCode')),
    });
};
