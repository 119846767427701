import {Club} from '../types/Club.type';
import {MapInfo} from '../types/MapPoint';
import {SearchResult} from '../../pages/search-page/types/SearchResult.type';

export const mapClubToMapInfo = (objects: Club[] | SearchResult[]): MapInfo[] =>
    objects.map((object) => {
        const center = {lat: object.address?.lat, lng: object.address?.lng};

        const address = `${object?.address.address}, ${object?.address.county}, ${object?.address.district}`;

        let clubPhoto = (object as SearchResult).photoUrl;

        return {
            clubId: object.id,
            clubName: object.name,
            clubPhoto,
            address,
            center,
            areas: object.areas,
        } as MapInfo;
    });
