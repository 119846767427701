import React, {FC, useState} from 'react';
import useSearchContext from '../../contexts/SearchContext';
import {removeEmptyeKeys} from '../../../../utils/removeEmptyKeysFromObject';
import {FiltersAndToggle, FiltersContainer, MobileSearchEngine, StyledButton, StyledChip} from './Filters.styles';
import {threatValuesToDisplay} from '../../utils/threatValuesToDisplay';

import Filter from '../../../../assets/icons/filter.svg';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

export const Filters: FC = ({...remainingProps}) => {
    const {searchObj} = useSearchContext();
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const finalObj = removeEmptyeKeys({...searchObj}, ['NOT_SPECIFIED']);
    const renderFilterChip = (key: string, index: number, label: string | undefined) => (
        <StyledChip color="default" key={`filter-${label}`} label={label} />
    );
    return (
        <Box>
            <FiltersAndToggle>
                <FiltersContainer {...remainingProps}>
                    {Object.keys(finalObj).map((key, index) => {
                        if (key === 'privacy') {
                            return renderFilterChip(key, index, t(`search.privacy.${finalObj.privacy.toLowerCase()}`));
                        }
                        if (key === 'environment') {
                            return renderFilterChip(
                                key,
                                index,
                                t(`search.environment.${finalObj.environment.toLowerCase()}`)
                            );
                        }
                        if (key === 'sport') {
                            return finalObj.sport.map((s: string) =>
                                renderFilterChip(key, index, t(`search.sports.${s.toLocaleLowerCase()}`))
                            );
                        }
                        if (key === 'domain') {
                            return null;
                        }
                        if (key !== 'clubName') {
                            return renderFilterChip(key, index, threatValuesToDisplay(key, finalObj[key]));
                        }
                        if (finalObj.clubName.length) {
                            return finalObj.clubName.map((club: string) =>
                                renderFilterChip(`filter-name-${index}`, index, club)
                            );
                        }
                        return null;
                    })}
                </FiltersContainer>
                <StyledButton selected={open} onClick={() => setOpen((previous) => !previous)}>
                    <img src={Filter} alt="" />
                </StyledButton>
            </FiltersAndToggle>
            {open ? <MobileSearchEngine onSearch={() => setOpen(false)} /> : null}
        </Box>
    );
};
