import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Fab, Stack, Typography} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import BookingCard from '../../components/BookingCard/BookingCard';
import {AppService} from '../../services/app.service';
import {BookingCancelModal} from '../../components/BookingCancelModal/BookingCancelModal';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dateFnsPT from 'date-fns/locale/pt';
import dateFnsUS from 'date-fns/locale/en-US';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {Layout} from '../../components/Common/Layout/Layout';

dateFnsPT.options = {
    weekStartsOn: 0,
};

dateFnsUS.options = {
    weekStartsOn: 0,
};

const Bookings = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<any>(null);
    const [bookings, setBookings] = useState<any[]>([]);

    const startDate = useMemo(() => {
        return searchParams.get('startDate')
            ? dayjs(searchParams.get('startDate')).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD');
    }, [searchParams]);

    const endDate = useMemo(() => {
        const date = searchParams.get('endDate');

        if (date)
            return dayjs(date).isAfter(startDate) || dayjs(date).isSame(startDate)
                ? dayjs(date).format('YYYY-MM-DD')
                : null;

        return null;
    }, [searchParams]);

    useEffect(() => {
        setLoading(true);
        new AppService()
            .api_getBookingsByUser({
                startDate: `${startDate}T00:00:00.000Z`,
                endDate: endDate ? `${endDate}T23:59:00.000Z` : undefined,
            })
            .then((res) => {
                if (res.status !== 200) throw res;

                setBookings(res.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    }, [startDate, endDate, setBookings]);

    const handleChangeDate = (key: string, value: string | undefined) => {
        if (!value || loading) return;
        setSearchParams((searchParams) => {
            searchParams.set(key, value);
            return searchParams;
        });
    };

    const handleCardCancel = (booking: any) => {
        setSelectedBooking(booking);
        setCancelModalOpen(true);
    };

    const handleCardDelete = () => {
        setBookings((prev: any) =>
            prev.map((a: any) => {
                return a.id === selectedBooking.id ? {...a, canceled: true} : a;
            })
        );
    };

    return (
        <>
            <Layout headerType="menu" maxWidth="sm">
                <Stack spacing={2} p={1}>
                    <Typography variant="h5" textAlign={'center'}>
                        {t('bookings').toLocaleUpperCase()}
                    </Typography>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={i18n.language === 'pt' ? dateFnsPT : dateFnsUS}
                    >
                        <DatePicker
                            sx={{width: '100%', backgroundColor: '#fff'}}
                            label={t('search.labels.startDate')}
                            value={new Date(startDate)}
                            onChange={(dateSelected) =>
                                handleChangeDate('startDate', dayjs(dateSelected)?.format('YYYY-MM-DD'))
                            }
                            disabled={loading}
                        />
                        <DatePicker
                            sx={{width: '100%', backgroundColor: '#fff'}}
                            label={t('search.labels.endDate')}
                            value={endDate == null ? null : new Date(endDate)}
                            onChange={(dateSelected: Date | null) =>
                                handleChangeDate('endDate', dayjs(dateSelected)?.format('YYYY-MM-DD'))
                            }
                            disabled={loading}
                        />
                    </LocalizationProvider>
                    {bookings.length === 0 ? (
                        <Alert variant="filled" severity="info">
                            {t('noBookings')}
                        </Alert>
                    ) : (
                        bookings
                            .sort((a: any, b: any) => (dayjs(a.day).isAfter(dayjs(b.day)) ? 1 : -1))
                            .map((booking: any) => (
                                <BookingCard
                                    key={booking.id}
                                    booking={{...booking, name: booking.area.name}}
                                    onClick={() => navigate(`/booking-details/${booking.id}`)}
                                    onCancel={() => handleCardCancel(booking)}
                                    onContact={() => navigate(`/club/${booking.club.id}#contacts`)}
                                />
                            ))
                    )}
                </Stack>
            </Layout>
            {selectedBooking && (
                <BookingCancelModal
                    booking={selectedBooking}
                    open={cancelModalOpen}
                    onClose={() => setCancelModalOpen(false)}
                    onDelete={handleCardDelete}
                />
            )}
            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{position: 'fixed', bottom: 16, right: 16}}
                onClick={() => navigate('/search')}
            >
                <AddIcon sx={{mr: 1}} />
                {t('newBooking')}
            </Fab>
        </>
    );
};

export default Bookings;
