//React imports
import React, {useEffect, useRef} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
//External dependencies
import {ErrorMessage, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {Alert, AlertTitle, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup} from '@mui/material';
//Internal dependencies
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {validationBookingSchema} from '../../../../models/validationBookingSchema.model';
import {Asterisk, Form} from './BookingForm.styles';
import {
    CreditCardBox,
    PaymentMethodLabel,
    StyledCard,
    StyledCardContent,
    StyledCardLabel,
    StyledFormControlLabel,
    TermsAndConditionsLabel,
    TermsAndConditionsLink,
} from '../createBooking/Booking.styles';
import {BookingFormTypes} from './BookingForm.types';
import {formatDate} from '../../../../utils/dates';
import {getTimeUnit} from '../../../../utils/getTimeUnit';
import {useUserContext} from '../../../edit-account/contexts/UserContext';
import useClubContext from '../../../club/context/ClubContext';
import {useCreateBooking} from '../../hooks/useCreateBooking';
import {useAuth} from '../../../../hooks/useAuth';
import {useClub} from '../../../club/hooks/useClub';
import {PaymentMethod} from '../../../../models/clubDetails.model';
import MBWAY from '../../../../assets/icons/MBWay_logo.svg';
import VISA from '../../../../assets/icons/Visa_logo.svg';
import MASTERCARD from '../../../../assets/icons/Mastercard_logo.svg';
import {useFeatureToggles} from '../../../../hooks/useFeatureToggles';
import {ReservationTypeEnum} from '../../../../shared/enums/ReservationType.enum';

export const BookingForm: React.FC<BookingFormTypes> = ({
    areaId,
    price,
    privacy,
    category,
    date,
    time,
    timeUnit,
    clubId,
}) => {
    const {t} = useTranslation();
    const {isAuthenticated, role} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isReady = useRef(false);
    const {user} = useUserContext();
    const {club} = useClubContext();
    const isPaymentVisible = privacy !== 'PUBLIC';
    const {
        actions: {fetch},
    } = useClub();

    const {
        actions: {fetchDomainFeatureToggles, hasDomainFeatureToggle},
    } = useFeatureToggles();

    useEffect(() => {
        if (isReady.current) return;
        isReady.current = true;
        if (!!clubId) {
            fetch(clubId);
            fetchDomainFeatureToggles();
        }
    }, []);

    const {
        isLoading,
        actions: {createBooking},
    } = useCreateBooking();

    const initialValues: any = {
        payment: searchParams.get('payment'),
        terms: searchParams.get('terms') ? true : false,
        name: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationBookingSchema(t, isPaymentVisible)}
            onSubmit={async (values: any) => {
                const from = formatDate(dayjs.unix(Number(time)).utc());
                const [hoursToAdd, minutesToAdd] = dayjs.unix(Number(timeUnit)).utc().format('HH:mm').split(':');
                const to = formatDate(
                    dayjs.unix(Number(time)).utc().add(Number(hoursToAdd), 'hour').add(Number(minutesToAdd), 'minute')
                );
                const day = formatDate(dayjs.unix(Number(searchParams.get('date'))));

                const booking = {
                    ...values,
                    name: user?.name,
                    email: user?.email,
                    category: category.toUpperCase(),
                    day,
                    from,
                    to,
                    price: Number(price),
                    timeUnit: getTimeUnit(dayjs.unix(Number(timeUnit)).utc().format('HH:mm')),
                    areaId,
                    reservationType: ReservationTypeEnum.PRIVATE,
                    paymentMethodId: values.payment === 'null' ? null : values.payment,
                };

                const return_url = `${location.pathname}${location.search}&terms=${values.terms}&payment=${values.payment}`;

                if (isAuthenticated) {
                    createBooking(role, booking, return_url);
                } else {
                    navigate(`/checkout/booking?return_url=${encodeURIComponent(return_url)}`, {
                        state: {...booking},
                    });
                }
            }}
        >
            {(props) => (
                <Form onSubmit={props.handleSubmit}>
                    {privacy !== 'PUBLIC' && (
                        <StyledCard>
                            <StyledCardContent>
                                <StyledCardLabel>
                                    {t('booking.label.paymentMethods')}
                                    <Asterisk>*</Asterisk>
                                </StyledCardLabel>
                                {club?.paymentMethods && club.paymentMethods.length > 0 ? (
                                    <FormControl>
                                        <RadioGroup aria-labelledby="payment-group-label" name="payment-group">
                                            {club.paymentMethods.map(
                                                (pm: PaymentMethod) =>
                                                    hasDomainFeatureToggle(pm.name) && (
                                                        <FormControlLabel
                                                            key={pm.name}
                                                            name="payment"
                                                            value={pm.id}
                                                            control={
                                                                <Radio
                                                                    name="payment"
                                                                    checked={props.values.payment?.includes(pm.id)}
                                                                    onChange={props.handleChange}
                                                                />
                                                            }
                                                            label={
                                                                <PaymentMethodLabel>
                                                                    {t(`club.paymentMethods.${pm.name.toLowerCase()}`)}
                                                                    {pm.name === 'MBWAY' && (
                                                                        <img src={MBWAY} alt="MBWay logo" />
                                                                    )}
                                                                    {pm.name === 'CREDIT_CARD' && (
                                                                        <CreditCardBox>
                                                                            <img src={VISA} alt="Visa logo" />
                                                                            <img
                                                                                src={MASTERCARD}
                                                                                alt="Mastercard logo"
                                                                            />
                                                                        </CreditCardBox>
                                                                    )}
                                                                </PaymentMethodLabel>
                                                            }
                                                        />
                                                    )
                                            )}
                                        </RadioGroup>
                                        <ErrorMessage name="payment">
                                            {(msg: string) => <Alert severity="error">{msg}</Alert>}
                                        </ErrorMessage>
                                        {props.values.payment === 'BANK_TRANSFER' && (
                                            <Alert variant="filled" severity="info">
                                                {t('booking.label.importantNote')}
                                            </Alert>
                                        )}
                                    </FormControl>
                                ) : (
                                    <Alert severity="info">
                                        <AlertTitle>{t('booking.noPaymentMethodsTitle')}</AlertTitle>
                                        {t('booking.noPaymentMethodsInfo')}
                                    </Alert>
                                )}
                            </StyledCardContent>
                        </StyledCard>
                    )}
                    <StyledCard>
                        <StyledCardContent>
                            <StyledCardLabel>
                                {t('termsAndConditions')}
                                <Asterisk>*</Asterisk>
                            </StyledCardLabel>
                            <FormGroup>
                                <StyledFormControlLabel
                                    control={
                                        <Checkbox
                                            name="terms"
                                            checked={props.values.terms}
                                            onChange={props.handleChange}
                                        />
                                    }
                                    label={
                                        <TermsAndConditionsLabel>
                                            {t('booking.label.acceptance')}{' '}
                                            <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                <TermsAndConditionsLink>
                                                    {t('booking.label.termsAndConditions')}
                                                </TermsAndConditionsLink>
                                            </Link>{' '}
                                            {t('booking.label.sportsFacility')}
                                        </TermsAndConditionsLabel>
                                    }
                                />
                                <ErrorMessage name="terms">
                                    {(msg: string) => <Alert severity="error">{msg}</Alert>}
                                </ErrorMessage>
                            </FormGroup>
                        </StyledCardContent>
                    </StyledCard>
                    <AppButton
                        variant="contained"
                        type="submit"
                        disabled={
                            isLoading ||
                            (privacy !== 'PUBLIC' && props.values.payment === null) ||
                            props.values.terms === false
                        }
                    >
                        {privacy !== 'PUBLIC' ? t('booking.button.next') : t('booking.button.book')}
                    </AppButton>
                </Form>
            )}
        </Formik>
    );
};
