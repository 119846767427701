import {Box, Button, Chip} from '@mui/material';
import styled, {css} from 'styled-components';
import {SearchEngine} from '../search-engine/SearchEngine';

export const FiltersAndToggle = styled(Box)`
    display: flex;
    align-items: center;
`;

export const FiltersContainer = styled(Box)`
    flex: 1;
    gap: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const StyledChip = styled(Chip)`
    color: #1976d2;
    text-transform: capitalize;

    .MuiChip-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiChip-avatarColorPrimary {
        background: transparent !important;
    }

    &.Mui-disabled {
        opacity: 0.75 !important;
    }
`;

export const StyledButton = styled(Button).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'selected',
})<{selected?: boolean}>`
    width: 40px;
    height: 40px;
    border: 1px solid #1976d2 !important;
    border-radius: 50% !important;
    padding: unset !important;
    min-width: unset !important;

    ${({selected}) =>
        selected &&
        css`
            background: #1976d2 !important;

            img {
                filter: brightness(5);
            }
        `}
`;

export const MobileSearchEngine = styled(SearchEngine)`
    padding-top: 1rem;
`;
