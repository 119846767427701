import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Alert,
    AlertTitle,
    Box,
    Checkbox,
    Chip,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import {FilterAlt as FilterAltIcon, FilterAltOff as FilterAltOffIcon} from '@mui/icons-material';
import {AppService} from '../../../services/app.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {Category} from '../../../shared/types/Category.type';
import {HistoryDataList} from './components/HistoryDataList/HistoryDataList';
import {
    MobileFilter,
    DesktopFilter,
    FilterButton,
    SelectGrid,
    StyledSelectControl,
    StyledError,
} from './HistoryData.styles';
import {ErrorMessage, Form, Formik} from 'formik';
import {Select} from '../../../components/Common/Select/Select';
import MUIDatePicker from '../../../components/DatePicker/DatePicker';
import {Input} from '../../../components/Common/Input/TextField/Input';
import {findAreaNameById} from '../../../utils/filters';
import {HistoryDataType, HistoryDataFormValues} from './types/HistoryData.types';
import {validationHistorySchema} from '../../../models/validationHistorySchema.model';
import {ClubArea} from '../../../shared/types/ClubArea.type';
import {Layout} from '../../../components/Common/Layout/Layout';

dayjs.extend(utc);
dayjs.locale(localStorage.getItem('i18netLng') ?? 'pt');

const HistoryData = () => {
    const {clubId} = useParams();
    const {t} = useTranslation();
    const appService = new AppService();

    const [areas, setAreas] = useState<ClubArea[]>([]);
    const [sports, setSports] = useState<Category[]>([]);
    const [bookings, setBookings] = useState<HistoryDataType[]>([]);
    //const [users, setUsers] = useState<string[]>([]);
    const [isListView, setIsListView] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const initialValues: HistoryDataFormValues = {
        selectedArea: '',
        selectedSports: [],
        startDate: null,
        endDate: null,
        users: [],
        search: '',
    };

    const handleChangeCloseDate = (date: any) => {
        return dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    };

    useEffect(() => {
        appService
            .api_getClub('private', {clubId})
            .then((res) => {
                if (res.status !== 200) throw new Error();
                setSports(res.data.categories ?? []);
                setAreas(res.data.areas);
            })
            .catch((error) => console.log(error));
    }, [clubId]);

    return (
        <Layout headerType="menu" maxWidth={false}>
            <Stack alignItems="center" p={2} spacing={2}>
                <Typography variant="h5">{t('clubAdmin.menu.historyData')}</Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationHistorySchema(t)}
                    enableReinitialize={true}
                    onSubmit={async (values: HistoryDataFormValues) => {
                        await appService
                            .api_getReservationHistory('private', clubId as string, {
                                startDate: values.startDate,
                                endDate: values.endDate,
                                areaId: values.selectedArea !== 'all' ? values.selectedArea : '',
                                sports: values.selectedSports,
                                search: values.search,
                            })
                            .then((res) => {
                                if (res.status !== 200) throw new Error();
                                setIsListView(true);
                                setBookings(res.data);
                            })
                            .catch((error) => {
                                console.log(error);
                                setBookings([]);
                            });
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                            <MobileFilter>
                                {props.values.selectedArea !== '' && (
                                    <Chip
                                        label={
                                            props.values.selectedArea === 'all'
                                                ? t('clubAdmin.bookings.allAreas')
                                                : findAreaNameById(props.values.selectedArea, areas)
                                        }
                                    />
                                )}
                                {props.values.selectedSports?.length > 0 && (
                                    <Chip
                                        label={`${t('club.headers.sports')}: ${props.values.selectedSports.join(', ')}`}
                                    />
                                )}
                                {props.values.startDate !== null && (
                                    <Chip
                                        label={`${t('from')}: ${dayjs(props.values.startDate).format('DD-MM-YYYY')}`}
                                    />
                                )}
                                {props.values.endDate !== null && (
                                    <Chip label={`${t('to')}: ${dayjs(props.values.endDate).format('DD-MM-YYYY')}`} />
                                )}
                                {props.values.search !== '' && <Chip label={`${t('query')}: ${props.values.search}`} />}
                                <IconButton
                                    aria-label="filter toggle"
                                    onClick={() => setIsFilterOpen((prev) => !prev)}
                                    sx={{marginLeft: 'auto'}}
                                >
                                    {isFilterOpen ? <FilterAltOffIcon /> : <FilterAltIcon />}
                                </IconButton>
                            </MobileFilter>

                            <DesktopFilter isFilterOpen={isFilterOpen}>
                                <SelectGrid>
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.selectedArea && Boolean(props.errors.selectedArea)}
                                        >
                                            <InputLabel id="sports">{t('clubAdmin.bookings.selectArea')}</InputLabel>
                                            <Select
                                                name="selectedArea"
                                                labelId="selectedArea"
                                                value={props.values.selectedArea}
                                                onChange={props.handleChange}
                                            >
                                                <MenuItem value="all">{t('clubAdmin.bookings.allAreas')}</MenuItem>
                                                {areas.map((area: ClubArea) => (
                                                    <MenuItem key={area.id} value={area.id}>
                                                        {area?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="selectedArea">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.selectedSports && Boolean(props.errors.selectedSports)}
                                        >
                                            <InputLabel id="sports">
                                                {t('clubAdmin.bookings.selectCategory')}
                                            </InputLabel>
                                            <Select
                                                name="selectedSports"
                                                labelId="selectedSports"
                                                value={props.values.selectedSports}
                                                multiple
                                                onChange={props.handleChange}
                                                renderValue={(selected: any) => selected.join(', ')}
                                            >
                                                {sports.map((sport: Category) => (
                                                    <MenuItem key={sport} value={sport}>
                                                        <Checkbox
                                                            checked={props.values.selectedSports.indexOf(sport) > -1}
                                                        />
                                                        <ListItemText primary={sport} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="sports">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <StyledSelectControl>
                                        <MUIDatePicker
                                            name="startDate"
                                            error={Boolean(props.errors.startDate)}
                                            label={t('clubAdmin.historyData.selectStartDate')}
                                            disablePast={false}
                                            disableFuture={true}
                                            onChange={(newValue) =>
                                                props.setFieldValue('startDate', handleChangeCloseDate(newValue), true)
                                            }
                                        />
                                        <ErrorMessage name="startDate">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </StyledSelectControl>
                                    <StyledSelectControl>
                                        <MUIDatePicker
                                            name="endDate"
                                            error={Boolean(props.touched.endDate && props.errors.endDate)}
                                            label={props.errors.endDate ? '' : t('clubAdmin.historyData.selectEndDate')}
                                            disablePast={false}
                                            disableFuture={true}
                                            onChange={(newValue) =>
                                                props.setFieldValue('endDate', handleChangeCloseDate(newValue), true)
                                            }
                                        />
                                        <ErrorMessage name="endDate">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </StyledSelectControl>
                                    {/* 
                                    IN CASE WE WANT TO ADD THIS FILTER AS DESIGNED
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.users && Boolean(props.errors.users)}
                                        >
                                            <InputLabel id="users">{t('clubAdmin.historyData.selectUsers')}</InputLabel>
                                            <Select
                                                name="users"
                                                labelId="users"
                                                value={props.values.users}
                                                multiple
                                                onChange={props.handleChange}
                                                renderValue={(selected: any) => selected.join(', ')}
                                            >
                                                {users?.map((user: any) => (
                                                    <MenuItem key={user} value={user}>
                                                        <ListItemText primary={user} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="users">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box> */}
                                    <Box>
                                        <Input
                                            name="search"
                                            placeholder={t('clubAdmin.bookings.inputLabel.search')}
                                            label={t('clubAdmin.bookings.inputLabel.search')}
                                            type="text"
                                            value={props.values.search}
                                            onChange={props.handleChange}
                                            error={props.touched.search && Boolean(props.errors.search)}
                                        />
                                        <ErrorMessage name="search">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <FilterButton isFilterOpen={isFilterOpen} variant="contained" type="submit">
                                        {t('filter')}
                                    </FilterButton>
                                </SelectGrid>
                            </DesktopFilter>
                        </Form>
                    )}
                </Formik>
                {isListView ? (
                    <HistoryDataList historyData={bookings} clubId={clubId!} />
                ) : (
                    <Alert variant="filled" severity="info" sx={{width: '100%'}}>
                        {t('clubAdmin.historyData.selectFilterToShowResults')}
                    </Alert>
                )}
            </Stack>
        </Layout>
    );
};

export default HistoryData;
