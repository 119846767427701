import React, {FC, ReactNode} from 'react';
import {useAuth} from '../../../hooks/useAuth';
import {useCommon} from '../../../context/CommonContext';
import {SnackbarAlert} from '../SnackbarAlert';
import {ModalStyled} from '../Modal/Modal';
import {Breakpoint, Container} from '@mui/material';
import AdminNavbar from '../Navbar/AdminNavbar';
import NotAuthenticatedNavbar from '../Navbar/NotAuthenticatedNavbar';
import PlayerNavbar from '../Navbar/PlayerNavbar';
import Header from '../Header/Header';

export type LayoutProps = {
    children: ReactNode;
    withHeader?: boolean;
    withFooter?: boolean;
    headerType?: string;
    hideClubIcon?: boolean;
    maxWidth?: Breakpoint | false;
};

export const Layout: FC<LayoutProps> = ({
    children,
    withFooter = false,
    headerType = '',
    hideClubIcon = false,
    maxWidth,
    ...remainingProps
}) => {
    const {modal, snackbar} = useCommon();
    const {isAuthenticated, role} = useAuth();
    const isSnackbarShowing = !!(snackbar && snackbar.open);

    return (
        <>
            {headerType === 'menu' && !isAuthenticated && <NotAuthenticatedNavbar />}
            {headerType === 'menu' && isAuthenticated && role === 'club-admin' && <AdminNavbar />}
            {headerType === 'menu' && isAuthenticated && role === 'player' && <PlayerNavbar />}
            {headerType === 'logo' && <Header />}
            <Container maxWidth={maxWidth} {...remainingProps}>
                {children}
                {snackbar && (
                    <SnackbarAlert {...snackbar} open={isSnackbarShowing}>
                        {snackbar.children}
                    </SnackbarAlert>
                )}
                {modal && (
                    <ModalStyled id={modal.id} open={modal.open} onClose={modal.onClose} title={modal.title}>
                        {modal.children}
                    </ModalStyled>
                )}
            </Container>
            {withFooter && <p>Footer</p>}
        </>
    );
};
