import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {useTranslation} from 'react-i18next';
import {CloseScheduleItem} from './ClosedSchedule.styles';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Cross from '../../../../assets/icons/closed.svg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export const ClosedSchedule = ({
    closed,
    variant,
}: {
    closed: {startDate: string; endDate: string}[];
    variant: string;
}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Cross} />
                {t(`club.headers.closed`)}
            </Header>
            {!closed?.length
                ? t('club.closed.noInformation')
                : closed.map((schedule, index) => (
                      <CloseScheduleItem key={`schedule-${index}`}>
                          {dayjs.utc(schedule.startDate).format('DD/MM/YYYY HH:mm')} -{' '}
                          {dayjs.utc(schedule.endDate).format('DD/MM/YYYY HH:mm')}
                      </CloseScheduleItem>
                  ))}
        </StyledBox>
    );
};
