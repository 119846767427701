import React, {FC} from 'react';
import {ButtonProps} from '@mui/material/Button';
import {ButtonStyled} from './Button.styles';

export type AppButtonProps = {
    id?: string;
    isHidden?: boolean;
} & ButtonProps;

export const AppButton: FC<AppButtonProps> = (props) => {
    return <ButtonStyled {...props} />;
};
