import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {Box} from '@mui/material';

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;

export const Description = styled.p`
    padding: 0 16px 16px 16px;
`;

export const ButtonBox = styled(Box)`
    display: flex;
    gap: 8px;
    padding: 8px;
`;
