import React from 'react';
import {useTranslation} from 'react-i18next';
import {CarParkType} from '../../../../shared/enums/CarParkType.enum';
import Car from '../../../../assets/icons/car.svg';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';

export const Parking = ({carParkType, variant}: {carParkType: CarParkType; variant: string}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <img src={Car} />
                {t(`club.headers.parking`)}
            </Header>
            {!!carParkType ? t(`club.parking.${carParkType.toLowerCase()}`) : t('club.parking.noInformation')}
        </StyledBox>
    );
};
