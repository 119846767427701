import React from 'react';
import {Parking} from '../components/parking/Parking';
import {PaymentMethods} from '../components/payment-methods/PaymentMethods';
import {Lockerooms} from '../components/lockerooms/Lockerooms';
import {Schedules} from '../components/schedules/Schedules';
import {ClosedSchedule} from '../components/closed-schedule/ClosedSchedule';
import {Sports} from '../components/sports/Sports';
import {PriceUnit} from '../components/price-unit/PriceUnit';
import {Reservations} from '../components/reservations/Reservations';
import {PrivacyEnvironment} from '../components/privacy-environment/PrivacyEnvironment';
import {MUImageList} from '../components/image-list/ImageList';

export const renderSection = (
    {title, param}: {title: string; param: string | string[]},
    object: Record<string, any>,
    variant: string = 'big'
) => {
    switch (title) {
        case 'paymentMethods':
            return (
                <PaymentMethods
                    key={`${object.id}-${param}`}
                    variant={variant}
                    paymentMethods={object[param as string]}
                />
            );
        case 'clubImageList':
            return <MUImageList key={`${object.id}-${param}`} variant={variant} photos={object[param as string]} />;

        case 'parking':
            return <Parking key={`${object.id}-${param}`} variant={variant} carParkType={object[param as string]} />;

        case 'lockerooms':
            return (
                <Lockerooms key={`${object.id}-${param}`} variant={variant} lockerRoomType={object[param as string]} />
            );

        case 'schedules':
            return <Schedules key={`${object.id}-${param}`} variant={variant} schedules={object[param as string]} />;

        case 'closed':
            return <ClosedSchedule key={`${object.id}-${param}`} variant={variant} closed={object[param as string]} />;

        case 'sports': {
            const props = (param as string[]).reduce((acc, prop) => ({...acc, [prop]: object[prop]}), {});
            return <Sports key={`${object.id}-${param}`} {...props} />;
        }
        case 'price-time': {
            const props = (param as string[]).reduce((acc, prop) => ({...acc, [prop]: object[prop]}), {});
            return <PriceUnit key={`${object.id}-${param[0]}`} variant={variant} {...props} />;
        }
        case 'reservations':
            return (
                <Reservations key={`${object.id}-${param}`} variant={variant} reservations={object[param as string]} />
            );

        case 'privacy-environment': {
            const props = (param as string[]).reduce((acc, prop) => ({...acc, [prop]: object[prop]}), {});
            return <PrivacyEnvironment key={`${object.id}-${param[0]}`} {...props} />;
        }
        default:
            return null;
    }
};
