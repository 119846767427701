import {useTranslation} from 'react-i18next';
import React from 'react';
import {ScheduleItem, Image} from './Schedules.styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Schedule} from '../../../../shared/types/Schedule.type';
import {Header, StyledBox} from '../club-description/ClubDescription.styles';
import Clock from '../../../../assets/icons/clock.svg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export const Schedules = ({schedules, variant}: {schedules: Schedule[]; variant: string}) => {
    const {t} = useTranslation();

    return (
        <StyledBox variant={variant}>
            <Header>
                <Image src={Clock} alt="" />
                {t(`club.headers.schedules`)}
            </Header>
            {!!schedules?.length
                ? schedules.map((schedule, index) => (
                      <ScheduleItem key={`schedule-${index}`}>
                          <div>{t(`club.schedules.weekdays.${schedule?.openDay?.toLowerCase()}`)}</div>
                          <div>
                              {dayjs.utc(schedule.from).format('HH:mm')} - {dayjs.utc(schedule.to).format('HH:mm')}
                          </div>
                      </ScheduleItem>
                  ))
                : t(`club.schedules.noInformation`)}
        </StyledBox>
    );
};
