import i18n from 'i18next';
import Backend, {HttpBackendOptions} from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
// @ts-ignore
import * as locales from "./locales/*/*.json";
//import "./locales/pt/common.json"
//import "./locales/en/common.json"

async function loadResources(lng: string, ns: string) {
    const translations = await import(`./locales/${lng}/${ns}.json`);
    return translations;
}

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init<HttpBackendOptions>({
        backend: {
            loadPath: '{{lng}}|{{ns}}',
            request: async (_: any, url: any, __: any, cb: any) => {
                const [lng, ns] = url.split('|');
                const translations =  locales[lng][ns];

                if (translations) {
                    cb(null, {
                        data: JSON.stringify(translations),
                        status: 200,
                    });
                } else {
                    cb(null, {
                        data: JSON.stringify({}),
                        status: 404,
                    });
                }
            },
        },
        defaultNS: 'common',
        ns: ['common'],
        detection: {
            order: ['localStorage', 'sessionStorage'],
            caches: ['localStorage'],
        },
        fallbackLng: 'pt',
        react: {
            useSuspense: true,
        },
    });

export default i18n;
