import styled from 'styled-components';
import {Grid, Box, Typography} from '@mui/material';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';

export const CheckoutContainer = styled(Grid)`
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;
export const GridImage = styled(Grid)`
    display: flex;
`;
export const CheckoutBox = styled(Grid)`
    display: grid;
    gap: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px 0 0 4px;
`;

export const GridDetails = styled(Grid)`
    background-color: #f2f2f2;
    padding: 1rem;
    border-radius: 0 4px 4px 0;
    text-align: left;
`;

export const Detail = styled(Box)`
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid rgba(47, 51, 51, 0.4);
    justify-content: space-between;
    padding: 0.75rem 0;
`;

export const DetailTypography = styled.p`
    color: var(--black-06);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const Image = styled.img`
    margin: 8% auto;
    height: auto;
    max-width: 246px;
`;

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;

export const Title = styled.h1`
    color: var(--black-087);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
`;
