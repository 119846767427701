import React, {useMemo} from 'react';
import {Dialog, Stack, Typography} from '@mui/material';
import {Input} from '../Common/Input/TextField/Input';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {ShareButtons} from '../ShareButtons/ShareButtons';

dayjs.extend(utc);

type ShareLinkModalProps = {
    booking: {
        shareId: string;
        name: string;
        day: string;
        from: string;
        to: string;
        category?: string;
        interested: {id: string; name: string}[];
        club: {
            name: string;
            address: {
                address: string;
                county: string;
                postalCode: string;
            };
        };
        area: {
            name: string;
        };
    };
    open: boolean;
    onClose: () => void;
};

export const ShareLinkModal = ({booking, open, onClose}: ShareLinkModalProps) => {
    const {t} = useTranslation();

    const currUrl = useMemo(() => `${window.location.origin}/booking/share/${booking.shareId}`, [booking]);

    const message = useMemo(() => {
        const dateStart = dayjs(booking.day).utc().format('DD-MM-YYYY');
        const timeStart = dayjs(booking.from).utc().format('HH:mm');
        const timeEnd = dayjs(booking.to).utc().format('HH:mm');
        const interested = booking.interested ?? [];
        const names = [booking.name, ...interested.map((a) => a.name)].map((name) => `\n- ${name}`);
        return t('shareBooking.message', {
            date: dateStart,
            category: booking.category,
            startTime: timeStart,
            endTime: timeEnd,
            clubName: booking.club.name,
            areaName: booking.area.name,
            clubAddress: booking.club.address?.address,
            clubPostalCode: booking.club.address?.postalCode,
            clubCounty: booking.club.address?.county,
            names: names,
        }).replace('{{url}}', currUrl);
    }, [booking]);

    const handleModalClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleModalClose} maxWidth="sm" fullWidth>
            <Stack p={2} spacing={2} onClick={(e) => e.stopPropagation()}>
                <Typography variant="h6">{t('shareBooking.shareTitle')}</Typography>
                <Input multiline maxRows={6} value={message} aria-readonly />
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <ShareButtons currUrl={currUrl} message={message} />
                </Stack>
            </Stack>
        </Dialog>
    );
};
