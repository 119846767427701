import {Box} from '@mui/material';
import styled from 'styled-components';
import {mediumHeaderStyles, commonContainerStyles, mediumTextStyle} from '../../utils/shared.styles';

export const StepsBox = styled(Box)`
    gap: 40px;
    display: flex;
    padding: 80px 24px;
    flex-direction: column;
    background-color: #1976d2;
`;

export const StepsTitle = styled.h2`
    color: white;
    text-align: center;

    ${mediumHeaderStyles};
`;

export const StepsCardsContainer = styled(Box)`
    gap: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-inline: unset !important;
    flex-direction: column;

    @media (min-width: 720px) {
        gap: 20px;
        flex-direction: row;
        align-items: unset;
    }

    ${commonContainerStyles};
`;

export const StepCard = styled(Box)`
    flex: 1;
    gap: 20px;
    width: 100%;
    color: white;
    display: flex;
    padding: 50px;
    max-width: 350px;
    text-align: center;
    border-radius: 40px;
    flex-direction: column;
    background-color: #0363c2;

    h3 {
        ${mediumHeaderStyles};
    }

    p {
        ${mediumTextStyle};
    }
`;
