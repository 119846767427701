import {Box, FormControl, Select, FormLabel} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {Map} from '../../../../components/Map/Map';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;

export const GridBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;

export const LatLngBox = styled(Box)`
    display: flex;
    gap: 1rem;
    width: 100%;
`;

export const StyledSelect = styled(Select)`
    width: 100%;
    background-color: #fff;
    color: var(--custom-secondary);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
`;

export const Title = styled.p`
    color: var(--custom-secondary);
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0.25px !important;
    margin: 0;
`;

export const InputBox = styled(Box)`
    display: grid;
    gap: 8px;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border-radius: 4px;
`;

export const ButtonBox = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 0;
`;

export const StyledMap = styled(Map)`
    width: 100%;
    height: 35rem;

    @media (max-width: 600px) {
        height: 20rem;
    }
`;

export const FormControlStyled = styled(FormControl)`
    width: 100% !important;
`;

export const StyledError = styled.p`
    color: #d32f2f;
    font-size: 12px;
    margin: 3px 14px 0 14px;
`;

export const StyledFormLabel = styled(FormLabel)`
    color: var(--custom-secondary) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    letter-spacing: 0.25px !important;
`;

export const StyledButton = styled(AppButton).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isHidden',
})<{isHidden?: boolean}>`
    display: ${({isHidden}) => (isHidden ? 'none !important' : 'flex !important')};
`;

export const StyledAppButton = styled(AppButton)`
    width: fit-content;
    border: 1px solid var(--color-primary);
`;

export const BoxGrid = styled.div`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 16px;

    @media (max-width: 600px) {
        grid-template-columns: auto;
    }
`;

export const SuccessBox = styled(Box)`
    width: 100%;
    padding: 1rem 0 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const SuccessTitle = styled.p`
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.25px;
    line-height: 32px;
    text-align: center;
    color: var(--custom-secondary);
`;

export const SuccessDescription = styled.p`
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: center;
    color: var(--custom-secondary);
`;

export const Image = styled.img.withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isDefault',
})<{isDefault?: boolean}>`
    width: 100%;
    height: 286px;
    object-fit: cover;
    border: ${({isDefault}) => (isDefault ? '3px solid var(--color-primary)' : '')};
    padding: ${({isDefault}) => (isDefault ? '5px' : 0)};

    &:hover {
        cursor: pointer;
    }
`;

export const DisabledImage = styled.img`
    width: 100%;
    height: 286px;
    object-fit: cover;
`;

export const StyledButtonBox = styled(Box)`
    position: relative;
    width: 100%;
`;

export const PhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ImageSmall = styled.img.withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isDefault',
})<{isDefault?: boolean}>`
    width: 100%;
    height: 180px;
    object-fit: cover;
    border: ${({isDefault}) => (isDefault ? '3px solid var(--color-primary)' : '')};
    padding: ${({isDefault}) => (isDefault ? '5px' : 0)};

    &:hover {
        cursor: pointer;
    }
`;

export const ImageBox = styled(Box)`
    display: grid;
    gap: 8px;
    grid-template-columns: 50% auto;
`;
