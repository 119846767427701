import dayjs from 'dayjs';

export const threatValuesToDisplay = (key: string, value: any) => {
    switch (key) {
        case 'sport':
            return value;
        case 'district':
            return value;
        case 'date':
            return dayjs(value).format('DD-MM-YYYY');
        case 'time':
        case 'timeUnit':
            return dayjs.unix(Number(value)).utc().format('HH:mm');
        case 'privacy':
        case 'environment':
            return value.toLowerCase();
        case 'clubName':
            return value.join('; ');
        case 'domain':
            return value;
        default:
            break;
    }
};
