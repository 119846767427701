import React, {FC} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {PropTypes} from './ClosedLabel.types';
import {Label} from './ClosedLabel.styles';

export const ClosedLabel: FC<PropTypes> = ({schedules, closedSchedule, baseTranslationKey}) => {
    const {t} = useTranslation();

    return (
        <Label>
            <b>{t(`${baseTranslationKey}.club_unavailable`)}</b>
            <br />
            <span>
                {!schedules.length
                    ? t(`${baseTranslationKey}.schedule_error`)
                    : t(`${baseTranslationKey}.closed_schedule_error`, {
                          from: dayjs.utc(closedSchedule?.startDate).format('DD-MM-YYYY'),
                          to: dayjs.utc(closedSchedule?.endDate).format('DD-MM-YYYY'),
                      })}
            </span>
        </Label>
    );
};
