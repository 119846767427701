import {AppService} from '../../../services/app.service';

export const useHomePage = () => {
    const appService = new AppService();

    const fetch = () =>
        appService
            .api_getHighlightedClubs()
            .then((res) => {
                if (res.status !== 200) throw new Error();
                return res.data;
            })
            .catch((error) => console.log(error));

    return {actions: {fetch}};
};
