import React, {useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ShareAvailableSlotsDescription,
    ShareAvailableSlotsClub,
    ShareAvailableSlotsAreaName,
    ShareAvailableSlotsChip,
    ShareAvailableSlotsContainer,
    ShareAvailableSlotsChipBox,
    ShareAvailableSlotsAreaRow,
    StyledContainer,
    NoSchedulesErrorMessage,
    StyledAppButton,
} from './AvailableSlots.styles';
import {useAvailableSlots} from '../../../hooks/useAvailableSlots';
import {AppContext} from '../../../context/AppContext';
import dayjs from 'dayjs';
import {Area} from '../../../models/booking.model';
import {useNavigate, useParams} from 'react-router-dom';
import {StyledDialogActions} from '../../club-admin/bookings/components/Bookings.styles';
import {ShareButtons} from '../../../components/ShareButtons/ShareButtons';
export const AvailableSlots = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    //AppContext
    const appContext = useContext(AppContext);
    const club = appContext.club;
    const {clubId} = useParams();
    const currDate = dayjs();
    const nextDay = currDate.add(1, 'day');
    const startDate = `${nextDay.format('YYYY-MM-DD')}T00:00.000Z`;
    const endDate = `${nextDay.format('YYYY-MM-DD')}T23:59.999Z`;
    const {data} = useAvailableSlots(club ? club?.id! : clubId!, startDate, endDate);
    const currUrl = `${window.location.origin}/club/${club?.id!}/available-slots`;
    const homeLink = `${window.location.origin}`;

    const message = useMemo(() => {
        return t('shareAvailableSlotsMessage', {
            date: nextDay.format('DD-MM-YYYY'),
            clubName: data.club,
            area: data.areas?.map((area: any) => `${area.name}: ${area.slots}\n`),
        }).replace('{{link}}', homeLink);
    }, [data]);

    return (
        <StyledContainer>
            {clubId !== undefined && (
                <>
                    <ShareAvailableSlotsDescription>{t('availableSlotsMessage')}</ShareAvailableSlotsDescription>
                    <ShareAvailableSlotsDescription>
                        {t('availableSlotsDate', {date: nextDay.format('DD-MM-YYYY')})}
                    </ShareAvailableSlotsDescription>
                </>
            )}
            <ShareAvailableSlotsContainer>
                <ShareAvailableSlotsClub>{data.club}</ShareAvailableSlotsClub>
                {data &&
                    data.areas &&
                    data.areas.map((area: Area, areaIndex: number) => (
                        <ShareAvailableSlotsAreaRow
                            key={area.id}
                            style={{background: areaIndex % 2 !== 0 ? '#f2f2f2' : ''}}
                        >
                            <ShareAvailableSlotsAreaName>{area.name}</ShareAvailableSlotsAreaName>
                            <ShareAvailableSlotsChipBox>
                                {area.slots && area.slots.length > 0 ? (
                                    area.slots.map((slot: string, slotIndex: number) => (
                                        <ShareAvailableSlotsChip key={slotIndex} label={slot} />
                                    ))
                                ) : (
                                    <NoSchedulesErrorMessage>
                                        {t('shareAvailableSlotsNoSchedulesError')}
                                    </NoSchedulesErrorMessage>
                                )}
                            </ShareAvailableSlotsChipBox>
                        </ShareAvailableSlotsAreaRow>
                    ))}
            </ShareAvailableSlotsContainer>
            {clubId === undefined ? (
                <StyledDialogActions>
                    <ShareButtons currUrl={currUrl} message={message} />
                </StyledDialogActions>
            ) : (
                <StyledAppButton variant="contained" sx={{margin: '16px'}} onClick={() => navigate('/search')}>
                    {t('book')}
                </StyledAppButton>
            )}
        </StyledContainer>
    );
};
