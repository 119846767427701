import React, {useContext, useEffect, useRef, useState} from 'react';
import {Input} from '../Common/Input/TextField/Input';
import {
    Box,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {BookingCancelModal} from '../BookingCancelModal/BookingCancelModal';
import {useCommon} from '../../context/CommonContext';
import {StyledAppButton, StyledDialogContainer} from './BookingModal.styles';
import {FeatureToggleBaseComponent} from '../featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from '../../shared/enums/FeatureToggles.enum';
import {AppContext} from '../../context/AppContext';
import CloseIcon from '@mui/icons-material/Close';
import {StyledSelectControl} from '../../pages/club-admin/history/HistoryData.styles';
import {ReservationType} from '../../shared/enums/ReservationType.enum';
import {useReservationTypes} from '../../hooks/useReservationTypes';
import {Select} from '../Common/Select/Select';
import {useUpdateReservation} from '../../hooks/useUpdateReservation';
import {error} from 'console';
import {AccordionComponent} from '../Accordion/Accordion';
import {FileUpload} from '../FileUpload/FileUpload';
import {Booking} from '../../models/booking.model';

dayjs.extend(utc);

export type BookingModalProps = {
    open: boolean;
    booking: Booking;
    historyFeatureToggle?: boolean;
    onClose: () => void;
    onDelete: () => void;
    onIsPaidUpdate?: (isPaid: boolean) => void | undefined;
};

export const BookingModal = ({open, booking, onClose, onDelete, onIsPaidUpdate = undefined}: BookingModalProps) => {
    const {t} = useTranslation();
    const {setSnackbar} = useCommon();
    const appContext = useContext(AppContext);

    const submitting = useRef(false);
    const [cancelBookingModalOpen, setCancelBookingModalOpen] = useState(false);
    const {data: reservationTypes} = useReservationTypes();
    const {mutate: updateReservation} = useUpdateReservation('private');
    const [isPaid, setIsPaid] = useState(booking.isPayed);
    const [reservationType, setReservationType] = useState(booking.reservationType);

    const handleCancelBooking = () => {
        setCancelBookingModalOpen(true);
    };

    const handleDeleteBooking = () => {
        onClose();
        onDelete();
    };

    useEffect(() => {
        setReservationType(booking.reservationType);
    }, [booking.reservationType]);

    const handleIsPaidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = e.target;
        if (submitting.current) return;
        submitting.current = true;
        updateReservation(
            {id: booking.id, isPayed: checked},
            {
                onSuccess: (data) => {
                    if (data.status !== 200) throw data;
                    if (typeof onIsPaidUpdate === 'function') onIsPaidUpdate(checked);
                    setIsPaid(checked);
                    appContext.setBookingUpdate(true);
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'success',
                        children: checked
                            ? t('bookingIsPaid', {id: booking.shareId})
                            : t('bookingIsNotPaid', {id: booking.shareId}),
                    });
                },
                onError: () => {
                    console.log(error);
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t('generalError'),
                    });
                },
                onSettled: () => {
                    submitting.current = false;
                },
            }
        );
    };

    const handleReservationTypeChange = (e: any) => {
        if (submitting.current) return;
        submitting.current = true;
        setReservationType(e.target.value);
        if (e.target.value !== reservationType) {
            const formData = {id: booking.id, reservationType: e.target.value};
            updateReservation(formData, {
                onSuccess: (data) => {
                    if (data.status !== 200) throw data;
                    appContext.setBookingUpdate(true);

                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'success',
                        children: t('clubAdmin.bookings.modal.reservationTypeUpdateSuccess'),
                    });
                },
                onError: () => {
                    setSnackbar({
                        open: true,
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t('clubAdmin.bookings.modal.reservationTypeUpdateError'),
                    });
                },
                onSettled: () => {
                    submitting.current = false;
                },
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                {`${t('clubAdmin.bookings.modal.title')} ${booking.shareId}`}{' '}
                {booking.canceled && <Chip label={t('canceled')} color="error" />}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <StyledDialogContainer>
                    <Input
                        name="area"
                        type="text"
                        disabled
                        hasLabelTop={true}
                        value={booking.area.name}
                        labelTop={t('area')}
                    />
                    <Input
                        name="category"
                        type="text"
                        disabled
                        value={booking?.category}
                        hasLabelTop={true}
                        labelTop={t('clubAdmin.bookings.inputLabel.selectedSport')}
                    />
                    <Box sx={{width: '100%'}}>
                        <InputLabel>{t('clubAdmin.bookings.inputLabel.reservationType')}</InputLabel>
                        <StyledSelectControl sx={{width: '100%'}} variant="outlined">
                            <Select
                                name="reservationType"
                                labelId="reservationType"
                                value={reservationType}
                                onChange={(e) => handleReservationTypeChange(e)}
                            >
                                {reservationTypes?.map((category: ReservationType) => (
                                    <MenuItem key={category} value={category}>
                                        {t(`clubAdmin.bookings.reservationTypes.${category.toLowerCase()}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledSelectControl>
                    </Box>
                    <Input
                        name="name"
                        type="text"
                        disabled
                        hasLabelTop={true}
                        value={booking.name}
                        labelTop={t('clubAdmin.bookings.modal.clientName')}
                    />
                    <Input
                        name="email"
                        type="text"
                        disabled
                        value={booking.email}
                        hasLabelTop={true}
                        labelTop={t('clubAdmin.bookings.modal.clientEmail')}
                    />
                    <Input
                        name="mobilePhone"
                        type="text"
                        disabled
                        value={booking.mobilePhone ? booking.mobilePhone : ''}
                        placeholder={t('clubAdmin.bookings.modal.mobilePhone')}
                        hasLabelTop={true}
                        labelTop={t('clubAdmin.bookings.modal.mobilePhone')}
                    />
                    <Input
                        name="day"
                        type="text"
                        disabled
                        hasLabelTop={true}
                        labelTop={t('clubAdmin.bookings.modal.day')}
                        value={dayjs(booking.day).format('DD-MM-YYYY')}
                    />
                    <Input
                        name="day"
                        type="text"
                        disabled
                        hasLabelTop={true}
                        labelTop={t('clubAdmin.bookings.modal.time')}
                        value={`${dayjs(booking.from).utc().format('HH:mm')} - ${dayjs(booking.to)
                            .utc()
                            .format('HH:mm')}`}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPaid}
                                onChange={handleIsPaidChange}
                                disabled={submitting.current || booking.canceled ? true : false}
                            />
                        }
                        label={t('paymentConfirmed')}
                    />
                    <AccordionComponent title={t('clubAdmin.bookings.payment')}>
                        {booking.paymentProofUrl ? (
                            <img src={booking.paymentProofUrl} alt="" style={{width: '100%'}} />
                        ) : (
                            <FileUpload reservationId={booking.id!} maxFileNumber={1} onClose={onClose} />
                        )}
                    </AccordionComponent>

                    <FeatureToggleBaseComponent featureToggle={FeatureToggles.RESERVATION_HISTORY}>
                        <Divider light />
                        <AccordionComponent title={t('clubAdmin.historyData.accordionTitle')}>
                            <Box>
                                <Typography variant="caption">
                                    {t('clubAdmin.historyData.createdBy')} <b>{booking?.CreatedBy?.name}</b>{' '}
                                    {t('clubAdmin.historyData.at')}{' '}
                                    <b>{dayjs(booking?.createdAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                                </Typography>
                                {booking?.isPayed && (
                                    <Box>
                                        <Typography variant="caption">
                                            {t('clubAdmin.historyData.paidBy')} <b>{booking?.PayedBy?.name}</b> por
                                            {t('clubAdmin.historyData.at')}{' '}
                                            <b>{dayjs(booking.payedAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                                        </Typography>
                                    </Box>
                                )}
                                {booking?.canceled && (
                                    <Box>
                                        <Typography variant="caption">
                                            {t('clubAdmin.historyData.canceledBy')} <b>{booking?.CanceledBy?.name}</b>{' '}
                                            {t('clubAdmin.historyData.at')}{' '}
                                            <b>{dayjs(booking.canceledAt).utc().format('DD/MM/YYYY HH:mm')}</b>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </AccordionComponent>
                    </FeatureToggleBaseComponent>
                </StyledDialogContainer>
            </DialogContent>
            <DialogActions>
                <StyledAppButton variant="outlined" disabled={submitting.current} onClick={onClose}>
                    {t('back')}
                </StyledAppButton>
                {!booking.canceled && (
                    <StyledAppButton
                        color="error"
                        variant="contained"
                        disabled={submitting.current}
                        onClick={handleCancelBooking}
                    >
                        {t('cancel')}
                    </StyledAppButton>
                )}
                <StyledAppButton variant="contained" disabled={submitting.current} onClick={handleCancelBooking}>
                    {t('contact')}
                </StyledAppButton>
            </DialogActions>
            <BookingCancelModal
                booking={booking}
                open={cancelBookingModalOpen}
                onClose={() => setCancelBookingModalOpen(false)}
                onDelete={handleDeleteBooking}
            />
        </Dialog>
    );
};
