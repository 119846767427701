import {Box} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
import {BackgroundImage} from '../../../components/Common/BackgroundImage/BackgroundImage';

export const FormBox = styled(Box)`
    width: 100%;
    padding: 1rem 0 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const GoBackBox = styled(Box)`
    display: flex;
`;

export const LoginFormBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: auto;
`;

export const Image = styled.img`
    margin: 24px auto 50px auto;
    height: 82px;
    width: fit-content;
`;

export const ImageLogin = styled.img`
    margin: auto;
    height: auto;
    max-width: 246px;
`;

export const Form = styled.form`
    padding: 8px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StyledAppButton = styled(AppButton)`
    width: 100%;
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
    filter: brightness(0.4);
    background: var(--black-054), rgba(0, 0, 0, 0.54);
`;
