import styled, {css} from 'styled-components';
import {Box} from '@mui/material';
import {ClubsList} from '../club-list/ClubList';
import {SearchMap} from '../search-map/SearchMap';
import {SearchEngine} from '../search-engine/SearchEngine';

const sharedPaddingProp = css`
    padding: 1rem 0;
`;

export const MobileSticky = styled(Box)`
    top: 0px;
    z-index: 401;
    overflow: auto;
    position: sticky;
    padding: 1rem;
    background: #fafafa;
    max-height: calc(100vh - 84px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -3px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px;

    @media (min-width: 1024px) {
        display: none;
    }
`;

export const StyledBox = styled(Box)`
    gap: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    ${sharedPaddingProp};
    padding-inline: 1rem;
    max-width: 600px;
    margin-inline: auto;

    @media (min-width: 1024px) {
        gap: 1rem;
        padding: 0;
        margin: unset;
        max-width: unset;
        height: calc(100vh - 84px);
    }
`;

export const DesktopSearchEngine = styled(SearchEngine)`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
    }
`;

export const SearchResults = styled(Box)`
    @media (min-width: 1024px) {
        flex: 1;
        display: flex;
        overflow: auto;
    }
`;

export const StyledClubsList = styled(ClubsList)`
    @media (min-width: 1024px) {
        flex: 2;
        max-width: 500px;
        overflow-y: auto;
    }
`;

export const StyledSearchMap = styled(SearchMap)`
    flex: 3;
`;
