import React, {FC, useEffect} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../../../hooks/useAuth';
import useSearchContext from '../../../search-page/contexts/SearchContext';
import {getBookingUrlAndState} from '../../../search-page/utils/getBookingUrlAndState';

import {ClosedLabel} from '../closed-label/ClosedLabel';

import {
    AreaPrice,
    AreaSportContainer,
    ReservationItem,
    StyledButton,
    StyledChip,
} from './AreaSearchResultActions.styles';

import {PropTypes} from './AreaSearchResultActions.types';
import {Category} from '../../../../shared/types/Category.type';
import {SportIcon} from '../../../../components/SportIcon/SportIcon';
import {Box, Tooltip} from '@mui/material';

export const AreaSearchResultActions: FC<PropTypes> = ({
    clubId,
    categories: areaCategories,
    areaId,
    areaName,
    price,
    privacy,
    reservations,
    areaSchedules,
    areaClosedSchedules,
    displayOptions,
    ...remainingProps
}) => {
    const {role} = useAuth();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {searchObj, categories: allCategories} = useSearchContext();
    const {showPrice} = displayOptions;

    const [sport, selectSport] = React.useState<Category | undefined>(
        !!searchObj.sport && searchObj.sport.length > 0
            ? allCategories?.find((cat) => searchObj.sport.includes(cat.toUpperCase()))
            : areaCategories?.length === 1
            ? areaCategories[0]
            : undefined
    );

    useEffect(() => {
        if (!!searchObj.sport && searchObj.sport.length > 0) {
            selectSport(allCategories?.find((cat) => searchObj.sport.includes(cat.toUpperCase())));
        } else if (areaCategories?.length === 1) {
            selectSport(areaCategories[0]);
        }
    }, [searchObj.sport]);

    const isAreaClosed = !areaSchedules.length || areaClosedSchedules.length > 0;
    const areaClosedSchedule = areaClosedSchedules?.length ? areaClosedSchedules[0] : undefined;
    const isButtonDisabled = isAreaClosed || reservations?.length > 0 || !sport;

    const handleBookingNavigation = (sport: Category | undefined) => {
        const {url, state} = getBookingUrlAndState(
            role,
            searchObj,
            allCategories!,
            clubId,
            {id: areaId, name: areaName},
            price,
            sport
        );
        navigate(url, {state: {...state}});
    };

    return (
        <Box {...remainingProps}>
            <AreaSportContainer>
                {areaCategories!.map((category) => (
                    <StyledChip
                        key={`chip-${areaId}-cat-${category}`}
                        avatar={
                            <SportIcon
                                selected={sport === category}
                                key={`area-${areaId}-${category}`}
                                category={category}
                            />
                        }
                        label={t(`search.sports.${category.toLowerCase()}`)}
                        color="primary"
                        onClick={() => selectSport(category)}
                        variant={sport === category ? 'filled' : 'outlined'}
                        disabled={!!searchObj.sport && searchObj.sport.length < 0}
                    />
                ))}
            </AreaSportContainer>

            {isAreaClosed && (
                <ClosedLabel
                    schedules={areaSchedules}
                    closedSchedule={areaClosedSchedule}
                    baseTranslationKey={'clubList.area'}
                />
            )}
            {reservations?.length > 0 &&
                reservations.map((reservation, index) => {
                    return (
                        <ReservationItem key={`area-${areaId}-reservation-${index}`}>
                            <span>{t('alreadyBooked')}:</span>
                            <br />
                            <b>
                                {dayjs.utc(reservation.from).format('HH:mm')} -{' '}
                                {dayjs.utc(reservation.to).format('HH:mm')}
                            </b>
                        </ReservationItem>
                    );
                })}
            {showPrice ? <AreaPrice>{privacy !== 'PUBLIC' ? `${Number(price).toFixed(2)} €` : 'N/A'}</AreaPrice> : null}

            <Tooltip title={!sport ? 'Selecione um desporto' : ''} arrow placement="top">
                <StyledButton
                    id="book-now"
                    variant="contained"
                    disabledAspect={isButtonDisabled}
                    onClick={() => !isButtonDisabled && handleBookingNavigation(sport)}
                >
                    {t('clubList.book')}
                </StyledButton>
            </Tooltip>
        </Box>
    );
};
