export const enum FeatureToggles {
    RESERVATION_HISTORY = 'RESERVATION_HISTORY',
    RESERVATION_TYPES = 'RESERVATION_TYPES',
    SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
    GOOGLE_SSO = 'GOOGLE_SSO',
    SHARE_AVAILABLE_SLOTS = 'SHARE_AVAILABLE_SLOTS',
    BANK_TRANSFER = 'BANK_TRANSFER',
    CASH = 'CASH',
    MBWAY = 'MBWAY',
    CREDIT_CARD = 'CREDIT_CARD',
}

export const featureTogglesMap: {[key: string]: FeatureToggles} = {
    RESERVATION_HISTORY: FeatureToggles.RESERVATION_HISTORY,
    RESERVATION_TYPES: FeatureToggles.RESERVATION_TYPES,
    SET_ORGANIZATIONS: FeatureToggles.SET_ORGANIZATIONS,
    GOOGLE_SSO: FeatureToggles.GOOGLE_SSO,
    SHARE_AVAILABLE_SLOTS: FeatureToggles.SHARE_AVAILABLE_SLOTS,
    BANK_TRANSFER: FeatureToggles.BANK_TRANSFER,
    CASH: FeatureToggles.CASH,
    MBWAY: FeatureToggles.MBWAY,
    CREDIT_CARD: FeatureToggles.CREDIT_CARD,
};
