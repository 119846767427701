import {TFunction} from 'i18next';
import * as yup from 'yup';
import 'yup-phone-lite';
import {emailRegex} from '../utils/validation';

export const validationAdminRegisterSchema = (t: TFunction) => {
    return yup.object({
        name: yup
            .string()
            .required(t('register.name.required'))
            .min(3, t('validation.toShort', {char: '3'}))
            .max(50, t('validation.toLong', {char: '50'})),
        clubName: yup
            .string()
            .required(t('register.name.required'))
            .min(3, t('validation.toShort', {char: '3'}))
            .max(50, t('validation.toLong', {char: '50'})),
        email: yup
            .string()
            .matches(emailRegex, t('validation.requiredValidEmail'))
            .required(t('validation.requiredEmail')),
        phone: yup
            .string()
            .matches(/^[0-9]+$/, t('validation.phoneOnlyNumber'))
            .phone('PT', t('validation.requiredValidPhone'))
            .required(t('validation.requiredField')),
        observations: yup
            .string()
            .min(3, t('validation.toShort', {char: '3'}))
            .max(200, t('validation.toLong', {char: '200'})),
    });
};
