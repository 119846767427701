import {useMutation} from 'react-query';
import {AppService} from '../services/app.service';
import {BookingCreate} from '../pages/club-admin/bookings/types/Bookings.types';

export function useCreateReservation(role: string) {
    const appService = new AppService();

    const {isLoading, data, mutate, isError, error} = useMutation({
        mutationKey: ['createReservation'],
        mutationFn: async (reservation: BookingCreate) => {
            const res = await appService.api_createBooking(role, reservation);
            return res;
        },
    });

    return {data, isLoading, mutate, isError, error};
}
