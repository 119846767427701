import React from 'react';
import {StyledLayout} from '../../../homepage/components/homepage/HomePage.styles';
import {
    DesktopSearchEngine,
    SearchResults,
    MobileSticky,
    StyledBox,
    StyledClubsList,
    StyledSearchMap,
} from './Search.styles';
import {Filters} from '../filters/Filters';
const Search = () => {
    return (
        <StyledLayout headerType="menu" maxWidth={false}>
            <MobileSticky>
                <Filters />
            </MobileSticky>
            <StyledBox>
                <DesktopSearchEngine />
                <SearchResults>
                    <StyledClubsList />
                    <StyledSearchMap />
                </SearchResults>
            </StyledBox>
        </StyledLayout>
    );
};

export default Search;
