import {Box} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export const CardContentStyled = styled.div`
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const ClubListCard = styled.div.withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isActive',
})<{isActive?: boolean}>`
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    align-self: stretch;
    color: ${({isActive}) => (isActive ? 'var(--color-primary)' : 'var(--custom-secondary)')};
    background: ${({isActive}) => (isActive ? 'rgba(25, 118, 210, 0.12)' : 'var(--grey-lighten-5)')};
    border-left: ${({isActive}) => (isActive ? '5px solid var(--color-primary)' : '')};

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const ClubListContainer = styled.div`
    display: grid;
    grid-template-columns: auto;
    gap: 8px;
`;

export const Image = styled.img`
    max-width: 100%;
    min-width: 141px;
    max-height: 100px;
    object-fit: cover;
`;

export const ClubName = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const AreaCount = styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0;
`;

export const StyledBox = styled(Box)`
    display: flex;
    gap: 8px;
`;

export const StyledAppButton = styled(AppButton)`
    padding: 0 16px !important;
    width: 128px;
    height: 24px;
`;

export const StyledGoBack = styled(AppButton)`
    width: 100%;
`;

export const StyledRemoveRedEyeIcon = styled(RemoveRedEyeIcon)`
    position: absolute;
    color: #fff;
    top: 0;
    right: 0;
`;

export const ImageBox = styled.span`
    position: relative;
`;
