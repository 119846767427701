import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import React, { FC, useEffect } from "react";

export type ProtectedRouteProps = {
  allowedRoles: string[]
  redirect: string
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  allowedRoles,
  redirect
}) => {
  const { isAuthenticated, role } = useAuth();
  const navigate = useNavigate();
  const isAuthorized = allowedRoles.includes(role);

  useEffect(() => {
    if (!isAuthenticated || !isAuthorized) {
      navigate(redirect);
    }
  })

  return isAuthenticated && isAuthorized ? <Outlet /> : null;
}

export default ProtectedRoute;