export const getTimeUnit = (timeUnit: string) => {
    switch (timeUnit) {
        case '00:30':
        case '1800':
            return 'TRINTA_MINUTOS';
        case '01:00':
        case '3600':
            return 'UMA_HORA';
        case '01:30':
        case '5400':
            return 'UMA_HORA_TRINTA_MINUTOS';
        case '02:00':
        case '7200':
            return 'DUAS_HORA';
    }
};
