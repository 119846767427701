import React from 'react';
import {useTranslation} from 'react-i18next';

import {steps_cards} from '../../utils/constants';

import {StepsBox, StepsTitle, StepsCardsContainer, StepCard} from './Steps.styles';

export const Steps = () => {
    const {t} = useTranslation();

    return (
        <StepsBox>
            <StepsTitle>{t(`homepage.steps.title`)}</StepsTitle>
            <StepsCardsContainer>
                {steps_cards.map((step, index) => (
                    <StepCard key={`step-card-${index}`}>
                        <img src={step.img} />
                        <h3>{index + 1}</h3>
                        <p>{t(`homepage.steps.${step.translationKey}`)}</p>
                    </StepCard>
                ))}
            </StepsCardsContainer>
        </StepsBox>
    );
};
