import React, {useEffect} from 'react';
import {useClub} from '../../hooks/useClub';
import {useParams} from 'react-router-dom';
import useClubContext from '../../context/ClubContext';
import {ClubHeader} from '../club-header/ClubHeader';
import {ClubDescription} from '../club-description/ClubDescription';
import {StyledLayout} from '../../../homepage/components/homepage/HomePage.styles';

export const Club = () => {
    const {
        actions: {fetch},
    } = useClub();
    const {clubId} = useParams();
    const {isLoading, club} = useClubContext();

    useEffect(() => {
        if (!!clubId) {
            fetch(clubId);
        }
    }, []);

    if (isLoading || !!!club) {
        return null;
    }
    return (
        <StyledLayout headerType="menu" maxWidth={false}>
            <ClubHeader />
            <ClubDescription />
        </StyledLayout>
    );
};
