export const clubSections = [
    {title: 'clubImageList', param: 'photos'},
    {title: 'paymentMethods', param: 'paymentMethods'},
    {title: 'parking', param: 'carParkType'},
    {title: 'lockerooms', param: 'lockerRoomType'},
];
export const areaSections = [
    {title: 'privacy-environment', param: ['privacy', 'environment']},
    {title: 'price-time', param: ['price', 'timeUnit', 'privacy']},
    {title: 'sports', param: ['AreaCategory', 'id']},
    {title: 'schedules', param: 'AreaSchedule'},
    {title: 'closed', param: 'AreaClosedSchedule'},
    {title: 'reservations', param: 'Reservation'},
];
