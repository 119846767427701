const _temp0 = require("./locales/en/common.json");

const _temp1 = require("./locales/pt/common.json");

module.exports = {
  "en":   {
    "common": _temp0
  },
  "pt":   {
    "common": _temp1
  }
}