import {FC, useContext, useEffect, useRef, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ErrorMessage, Form, Formik} from 'formik';
import {validationCreateBookingSchema} from '../../../../models/validationCreateBooking.model';
import {Input} from '../../../../components/Common/Input/TextField/Input';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {AreaCategory} from '../../../../shared/types/Category.type';
import {useCommon} from '../../../../context/CommonContext';
import {StyledAlert, StyledError, TimeBox} from './Bookings.styles';
import {Select} from '../../../../components/Common/Select/Select';
import MUITimePicker from '../../../../components/TimePicker/TimePicker';
import React from 'react';
import {Club} from '../../../../shared/types/Club.type';
import {generateOpeningHours, getAreaCategories, getSelectedAreaInfo} from '../../../../utils/filters';
import {Booking, BookingCreate} from '../types/Bookings.types';
import {ClubArea} from '../../../../shared/types/ClubArea.type';
import {AppContext} from '../../../../context/AppContext';
import {ReservationType, ReservationTypeEnum} from '../../../../shared/enums/ReservationType.enum';
import {useReservationTypes} from '../../../../hooks/useReservationTypes';
import {useCreateReservation} from '../../../../hooks/useCreateReservation';
import {FeatureToggleBaseComponent} from '../../../../components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {FeatureToggles} from '../../../../shared/enums/FeatureToggles.enum';

dayjs.extend(utc);
dayjs.extend(timezone);

type CreateBookingProps = {
    club: Club;
    selectedArea?: string;
    selectedSport?: string;
    areaSports?: AreaCategory[] | undefined;
    areaInfo?: ClubArea | undefined;
    day?: {start: string; end: string};
    onSuccessSubmit?: any;
};

const CreateBooking: FC<CreateBookingProps> = ({
    club,
    selectedArea,
    selectedSport,
    areaSports,
    day,
    areaInfo,
    onSuccessSubmit,
}) => {
    const {t} = useTranslation();
    const appContext = useContext(AppContext);
    const {setSnackbar} = useCommon();
    const errorRef = useRef<HTMLDivElement>(null);
    const {data: reservationCategories} = useReservationTypes();
    const {mutate: createReservation, isError} = useCreateReservation('public');

    const [clubArea, setClubArea] = useState<string>(selectedArea ? selectedArea : 'all');
    const [selectedAreaInfo, setSelectedAreaInfo] = useState<any>(areaInfo);
    const [areaCategories, setAreaCategories] = useState<any>(areaSports);
    const [areaError, setAreaError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const selectedAreaSchedule: {from: number; to: number} | any = selectedAreaInfo
        ? generateOpeningHours(
              selectedAreaInfo?.AreaSchedule,
              dayjs(day?.start).locale('en').format('dddd').toUpperCase()
          )
        : [];

    const minTime = dayjs().set('hour', Number(selectedAreaSchedule.from)).startOf('hour');
    const maxTime = dayjs().set('hour', Number(selectedAreaSchedule.to)).startOf('hour');

    const initialValues: Booking = {
        name: '',
        email: '',
        sport: selectedSport && selectedSport.length > 0 ? selectedSport[0] : 'all',
        reservationType: 'any',
        day: dayjs(day?.start).format('YYYY-MM-DD'),
        from: day && dayjs(day?.start).format('HH:mm') !== '00:00' ? dayjs(day.start) : null,
        to: day && dayjs(day?.end).format('HH:mm') !== '00:00' ? dayjs(day.end) : null,
        mobilePhone: '',
    };

    const handleAreaChange = (e: any) => {
        setClubArea(e.target.value);
        setAreaCategories(getAreaCategories(club?.areas, e.target.value));
        setSelectedAreaInfo(getSelectedAreaInfo(club?.areas, e.target.value));

        setAreaError(false);
    };

    useEffect(() => {
        // Scroll to the error message when it appears
        if (errorRef.current) {
            errorRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }, [isError]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationCreateBookingSchema(t)}
            enableReinitialize={true}
            validate={(values) => {
                const isAreaError = clubArea === 'all';
                setAreaError(isAreaError);

                try {
                    validationCreateBookingSchema(t).validateSync(values, {
                        abortEarly: false,
                    });
                    return {};
                } catch (errors: any) {
                    return errors.inner.reduce(
                        (acc: any, error: any) => ({
                            ...acc,
                            [error.path!]: error.message,
                        }),
                        {}
                    );
                }
            }}
            onSubmit={async (values: Booking) => {
                let responseStatus: string;
                const formData: BookingCreate = {
                    day: values.day,
                    from: values.from,
                    category: values.sport?.toUpperCase(),
                    reservationType:
                        values.reservationType !== 'any' ? values.reservationType : ReservationTypeEnum.PRIVATE,
                    to: values.to,
                    name: values.name,
                    email: values.email,
                    mobilePhone: values.mobilePhone,
                    timeUnit: selectedAreaInfo ? selectedAreaInfo?.timeUnit : areaInfo?.timeUnit,
                    price: selectedAreaInfo ? Number(selectedAreaInfo?.price) : Number(areaInfo?.price),
                    areaId: selectedAreaInfo ? selectedAreaInfo?.id : selectedArea,
                };

                createReservation(formData, {
                    onSuccess: (reservationData) => {
                        responseStatus = reservationData.status;
                        if (reservationData.status !== 201) throw new Error();
                        setSnackbar({
                            open: true,
                            title: t(`clubAdmin.bookings.success.title`, {
                                area: selectedAreaInfo?.name,
                            }),
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t(`clubAdmin.bookings.success.${reservationData.status}`, {
                                area: selectedAreaInfo?.name,
                                clientName: values.name,
                                day: values.day,
                                from: dayjs(values.from).format('HH:mm'),
                                to: dayjs(values.to).format('HH:mm'),
                                email: values.email,
                            }),
                        });
                        appContext.setNewBookings(true);
                        onSuccessSubmit();
                    },
                    onError: () => {
                        setErrorMessage(t(`clubAdmin.bookings.error.${responseStatus}`));
                    },
                });
            }}
        >
            {(props) => (
                <>
                    <Form id="form-createBooking" onSubmit={props.handleSubmit} className="w-100">
                        <Stack alignItems="center" spacing={2}>
                            <Box className="w-100">
                                <InputLabel required>{t('area')}</InputLabel>
                                <FormControl variant="outlined" error={areaError} className="w-100">
                                    <Select
                                        id="areaName"
                                        name="areaId"
                                        labelId="areaId"
                                        value={clubArea}
                                        onChange={(e) => handleAreaChange(e)}
                                    >
                                        <MenuItem value="all">{t('clubAdmin.bookings.allAreas')}</MenuItem>
                                        {club.areas?.map((area: ClubArea) => (
                                            <MenuItem key={area.id} value={area.id}>
                                                {area?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {areaError && <StyledError>{t('validation.requiredField')}</StyledError>}
                            </Box>
                            <Box className="w-100">
                                <InputLabel required>{t('clubAdmin.bookings.inputLabel.selectedSport')}</InputLabel>
                                <FormControl
                                    className="w-100"
                                    variant="outlined"
                                    error={props.touched.sport && Boolean(props.errors.sport)}
                                >
                                    <Select
                                        name="sport"
                                        labelId="sport"
                                        value={props.values.sport}
                                        onChange={props.handleChange}
                                    >
                                        <MenuItem value="all" selected>
                                            {t('clubAdmin.bookings.selectCategory')}
                                        </MenuItem>
                                        {props.values.selectedArea === 'all'
                                            ? areaSports?.map((category: AreaCategory) => (
                                                  <MenuItem key={category.category} value={category.category}>
                                                      {category.category}
                                                  </MenuItem>
                                              ))
                                            : areaCategories?.map((category: AreaCategory) => (
                                                  <MenuItem key={category.category} value={category.category}>
                                                      {category.category}
                                                  </MenuItem>
                                              ))}
                                    </Select>
                                </FormControl>
                                <ErrorMessage name="sport">
                                    {(msg: string) => <StyledError>{msg}</StyledError>}
                                </ErrorMessage>
                            </Box>
                            <FeatureToggleBaseComponent featureToggle={FeatureToggles.RESERVATION_TYPES}>
                                <Box className="w-100">
                                    <InputLabel>{t('clubAdmin.bookings.inputLabel.reservationType')}</InputLabel>
                                    <FormControl
                                        className="w-100"
                                        variant="outlined"
                                        error={props.touched.reservationType && Boolean(props.errors.reservationType)}
                                    >
                                        <Select
                                            name="reservationType"
                                            labelId="reservationType"
                                            value={props.values.reservationType}
                                            onChange={props.handleChange}
                                        >
                                            <MenuItem value="any" selected>
                                                {t('clubAdmin.bookings.reservationTypes.selectReservationType')}
                                            </MenuItem>
                                            {reservationCategories?.map((category: ReservationType) => (
                                                <MenuItem key={category} value={category}>
                                                    {t(`clubAdmin.bookings.reservationTypes.${category.toLowerCase()}`)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <ErrorMessage name="reservationType">
                                        {(msg: string) => <StyledError>{msg}</StyledError>}
                                    </ErrorMessage>
                                </Box>
                            </FeatureToggleBaseComponent>

                            <Box className="w-100">
                                <Input
                                    id="clientName"
                                    name="name"
                                    placeholder={t('clubAdmin.bookings.inputPlaceholder.clientName')}
                                    type="text"
                                    value={props.values.name}
                                    hasLabelTop={true}
                                    labelTop={t('clubAdmin.bookings.inputLabel.clientName')}
                                    labelTopRequired={true}
                                    onChange={props.handleChange}
                                    error={props.touched.name && Boolean(props.errors.name)}
                                    helperText={props.touched.name && (props.errors.name as string)}
                                />
                            </Box>
                            <Box className="w-100">
                                <Input
                                    id="clientEmail"
                                    name="email"
                                    placeholder={t('clubAdmin.bookings.inputPlaceholder.clientEmail')}
                                    type="email"
                                    value={props.values.email}
                                    hasLabelTop={true}
                                    labelTop={t('clubAdmin.bookings.inputLabel.clientEmail')}
                                    labelTopRequired={true}
                                    onChange={props.handleChange}
                                    error={props.touched.email && Boolean(props.errors.email)}
                                    helperText={props.touched.email && (props.errors.email as string)}
                                />
                            </Box>
                            <Box className="w-100">
                                <Input
                                    id="clientMobilePhone"
                                    name="mobilePhone"
                                    placeholder={t('clubDetails.step.inputPlaceholder.clubPhone')}
                                    type="tel"
                                    value={props.values.mobilePhone}
                                    hasLabelTop={true}
                                    labelTop={t('clubDetails.step.inputLabel.clubMobile')}
                                    labelTopRequired={true}
                                    onChange={props.handleChange}
                                    error={props.touched.mobilePhone && Boolean(props.errors.mobilePhone)}
                                    helperText={props.touched.mobilePhone && (props.errors.mobilePhone as string)}
                                />
                            </Box>
                            <Box className="w-100">
                                <Input
                                    id="selectedDay"
                                    name="day"
                                    type="text"
                                    value={props.values.day}
                                    hasLabelTop={true}
                                    labelTop={t('clubAdmin.bookings.inputLabel.selectedDay')}
                                    disabled
                                />
                            </Box>
                            <Box className="w-100">
                                <InputLabel required>{t('schedule')}</InputLabel>
                                <TimeBox>
                                    <Box>
                                        <FormControl className="w-100">
                                            <MUITimePicker
                                                name="from"
                                                label={t('search.fields.time')}
                                                error={props.touched.from && Boolean(props.errors.from)}
                                                value={props.values.from}
                                                disablePast={
                                                    dayjs().utc().format('YYYY-MM-DD') === props.values.day
                                                        ? true
                                                        : false
                                                }
                                                minTime={minTime}
                                                maxTime={maxTime}
                                                onChange={(newValue) => props.setFieldValue('from', newValue, true)}
                                            />
                                            {props.touched.from && props.errors.from && (
                                                <StyledError>{props.errors.from as string}</StyledError>
                                            )}
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl className="w-100">
                                            <MUITimePicker
                                                name="to"
                                                error={props.touched.to && Boolean(props.errors.to)}
                                                label={t('search.fields.endTime')}
                                                value={props.values.to}
                                                minTime={minTime}
                                                maxTime={maxTime}
                                                onChange={(newValue) => props.setFieldValue('to', newValue, true)}
                                            />
                                            {props.touched.to && props.errors.to && (
                                                <StyledError>{props.errors.to as string}</StyledError>
                                            )}
                                        </FormControl>
                                    </Box>
                                </TimeBox>
                            </Box>
                            {isError && (
                                <StyledAlert severity="error">
                                    <div ref={errorRef}>{errorMessage}</div>
                                </StyledAlert>
                            )}
                        </Stack>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default CreateBooking;
