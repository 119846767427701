import {Box, IconButton, IconButtonProps, Tooltip} from '@mui/material';
import React from 'react';
type ShareIconProps = {
    icon: React.ReactNode;
    text: string;
    onClick: () => void;
} & IconButtonProps;
export const AppShareIcon = ({icon, text, onClick, ...rest}: ShareIconProps) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Tooltip title={text} arrow>
                <IconButton
                    size="large"
                    {...rest}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                    aria-label={text}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        </Box>
    );
};
