import React, {useMemo} from 'react';
import useClubContext from '../../context/ClubContext';
import {Container, Info, InfoContainer, StyledIcon} from './ClubHeader.styles';
import Pin from '../../../../assets/icons/pin.svg';
import {Layout} from '../../../../components/Common/Layout/Layout';

export const ClubHeader = () => {
    const {club} = useClubContext();
    const image = useMemo(() => {
        if (club?.photos?.length) {
            const defaultPhoto = club.photos.find((photo: any) => photo.isDefault);
            return defaultPhoto ? defaultPhoto.photoUrl : club.photos[0].photoUrl;
        } else {
            return 'https://placehold.co/400';
        }
    }, [club]);
    const address = useMemo(() => {
        return `${club?.address.address}, ${club?.address.county}, ${club?.address.district}`;
    }, [club]);

    return (
        <Container image={image}>
            <InfoContainer>
                <Layout>
                    <Info>
                        <h1>{club?.name}</h1>
                        <p>
                            <StyledIcon src={Pin} /> {address}
                        </p>
                    </Info>
                </Layout>
            </InfoContainer>
        </Container>
    );
};
