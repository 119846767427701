import {Box} from '@mui/material';
import styled from 'styled-components';
import {commonContainerStyles, mediumHeaderStyles, largeTextStyle, smallTextStyle} from '../../utils/shared.styles';

export const AdvantagesBox = styled(Box)`
    gap: 40px;
    display: flex;
    padding: 80px 24px;
    flex-direction: column;

    ${commonContainerStyles};
`;

export const AdvantagesTitle = styled.h2`
    color: #212a5c;

    ${mediumHeaderStyles};
`;

export const AdvantagesCardsContainer = styled(Box)`
    gap: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-inline: unset !important;
    flex-direction: column;

    @media (min-width: 720px) {
        gap: 20px;
        flex-direction: row;
        align-items: unset;
    }
`;

export const AdvantagesCard = styled(Box)`
    flex: 1;
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 20px;
    color: #21295c;
    max-width: 350px;
    border-radius: 20px;
    flex-direction: column;
    background-color: #f2f2f2;

    p {
        flex: 1;
        ${largeTextStyle};
    }

    span {
        ${smallTextStyle};
    }
`;

export const AdvantageImageContainer = styled(Box)`
    border-radius: 20px;
    background-color: white;

    img {
        width: 90%;
        display: block;
        max-width: 150px;
        max-height: 150px;
        margin: 25px auto;
    }
`;
