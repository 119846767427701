import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {statistics_cards} from '../../utils/constants';
import {roundDownToNearestMultipleOf5} from '../../utils/roundDownToNearestMultipleOf5';

import Pin from '../../../../assets/icons/pin.svg';
import {SportIcon} from '../../../../components/SportIcon/SportIcon';

import {
    Club,
    ClubAddress,
    ClubDescription,
    ClubImage,
    ClubInfo,
    ClubName,
    ClubSports,
    Clubs,
    LocationsBox,
    LocationsText,
    LocationsTitle,
    StatisticsCard,
    StatisticsCards,
    StyledIcon,
} from './Locations.styles';

import {PropTypes} from './Locations.types';
import {StyledChip} from '../../../club/components/area-search-result/AreaSearchResultActions.styles';

export const Locations: FC<PropTypes> = ({numberofClubs, numberOfSports, highlightedClubs}) => {
    const {t} = useTranslation();

    return (
        <LocationsBox>
            <LocationsTitle>{t(`homepage.statistics.title`)}</LocationsTitle>
            <LocationsText>{t(`homepage.statistics.text`)}</LocationsText>
            <StatisticsCards>
                <StatisticsCard>
                    <h5>{roundDownToNearestMultipleOf5(numberofClubs)}+</h5>
                    <p>{t(`homepage.statistics.locations`)}</p>
                </StatisticsCard>
                <StatisticsCard>
                    <h5>{roundDownToNearestMultipleOf5(numberOfSports)}+</h5>
                    <p>{t(`homepage.statistics.sports`)}</p>
                </StatisticsCard>
                {statistics_cards.map((statistic, index) => (
                    <StatisticsCard key={`statistics-card-${index}`}>
                        <h5>{statistic.value}+</h5>
                        <p>{t(`homepage.statistics.${statistic.translationKey}`)}</p>
                    </StatisticsCard>
                ))}
            </StatisticsCards>
            {highlightedClubs?.length && (
                <Clubs>
                    {highlightedClubs.map((club) => (
                        <Club key={`club-${club.id}`}>
                            <ClubImage>
                                <img src={club.photos?.[0]?.photoUrl || 'https://placehold.co/400'} />
                            </ClubImage>
                            <ClubInfo>
                                <ClubName href={`/club/${club.id}`}>{club?.name}</ClubName>
                                <ClubSports>
                                    {club.categories?.map((category) => (
                                        <StyledChip
                                            key={`club-${club.id}-${category}`}
                                            avatar={<SportIcon category={category} />}
                                            label={t(`search.sports.${category.toLowerCase()}`)}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    ))}
                                </ClubSports>
                                <ClubAddress>
                                    <StyledIcon src={Pin} /> {club?.address?.address}, {club?.address?.county},{' '}
                                    {club?.address?.district}
                                </ClubAddress>
                                <ClubDescription>{club.description}</ClubDescription>
                            </ClubInfo>
                        </Club>
                    ))}
                </Clubs>
            )}
        </LocationsBox>
    );
};
