import {useSearchParams} from 'react-router-dom';
import {AppService} from '../../../services/app.service';
import {removeEmptyeKeys} from '../../../utils/removeEmptyKeysFromObject';
import useSearchContext from '../contexts/SearchContext';
import {districts, environments, hours, privacies, timeUnits} from '../utils/constants';
import dayjs from 'dayjs';
import {SearchObjectType} from '../types/SearchObject.type';
import {threatSearchValues} from '../utils/threatSearchValues';
import {fromDayjsToTimeStamp} from '../utils/fromDayjsToTimeStamp';
import {checkIfFieldsAreValidAndReturnError} from '../utils/checkValid';
import {useEffect, useState} from 'react';
import {AvailableHourType} from '../types/AvailableHour.type';
import {useAuth} from '../../../hooks/useAuth';
import {getNextHalfAnHour} from '../../homepage/utils/mountQueryString';

export const useSearch = () => {
    const {role} = useAuth();
    const appService = new AppService();
    const [searchParams, setSearchParams] = useSearchParams();
    const [availableHours, setAvailableHours] = useState<AvailableHourType[]>(hours);

    const {
        setIsLoading,
        searchObj,
        setSearchObj,
        setResults,
        isLoadingFilters,
        isFirstSearchPerformed,
        setIsFirstSearchPerformed,
    } = useSearchContext();

    useEffect(() => {
        if (!isLoadingFilters) {
            if (searchObj.date) {
                const today = dayjs();
                if (searchObj.date.isSame(today, 'day')) {
                    const timeStamp = fromDayjsToTimeStamp(today);
                    setAvailableHours(
                        hours.reduce((acc: AvailableHourType[], hour: AvailableHourType) => {
                            if (timeStamp < Number(hour.value)) {
                                return [...acc, hour];
                            }
                            return [...acc];
                        }, [])
                    );
                } else {
                    setAvailableHours(hours);
                }
            }
        }
    }, [searchObj.date]);

    const fetchCategories = () =>
        appService
            .api_getCategories()
            .then((res) => {
                if (res.status !== 200) throw new Error();
                return res.data;
            })
            .catch((error) => console.log(error));

    const fecthClubNames = () =>
        appService
            .api_getClubNames()
            .then((res) => {
                if (res.status !== 200) throw new Error();
                return res.data;
            })
            .catch((error) => console.log(error));

    const fetchEverything = (role: string | undefined) => Promise.all([fetchCategories(), fecthClubNames()]);

    const checkQueryString = (inputObj: Record<string, string> = {}) => {
        let date = searchParams.get('date') ? dayjs.unix(Number(searchParams.get('date'))) : dayjs();
        let time = searchParams.get('time') ? searchParams.get('time') : `${getNextHalfAnHour()}`;

        if (!!date) {
            let today = dayjs();
            if (date.isSame(today, 'day')) {
                if (time) {
                    const inputDate = dayjs.unix(Number(time)).utc().format('HH:mm');
                    const todayDate = dayjs(`1970-01-01 ${today.format('HH:mm')}`).format('HH:mm');

                    const inputTime = dayjs(inputDate, 'HH:mm');
                    const todayTime = dayjs(todayDate, 'HH:mm');

                    if (todayTime.isAfter(inputTime)) {
                        const minutes = today.minute();
                        const roundedMinutes = Math.ceil(minutes / 30) * 30;
                        if (roundedMinutes === 60) {
                            today = today.add(1, 'hour').minute(0);
                        } else {
                            today = today.minute(roundedMinutes);
                        }
                        const timeStamp = fromDayjsToTimeStamp(today);
                        const option = hours.find((hour) => Number(hour.value) === timeStamp);
                        if (!!option) {
                            time = `${option.value}`;
                        } else {
                            date = date?.add(1, 'day');
                            time = `${hours[0].value}`;
                        }
                    }
                }
            }
        }

        if (time && !isNaN(Number(time))) {
            if (Number(time) < Number(hours[0].value)) {
                time = `${hours[0].value}`;
            } else if (Number(time) > Number(hours[hours.length - 1].value)) {
                time = `${hours[0].value}`;
                if (!!date) {
                    date = date?.add(1, 'day');
                }
            }

            if (!hours.find((hour) => hour.value === time)) {
                for (let i = 0; i < hours.length; i++) {
                    if (parseInt(hours[i].value) > Number(time)) {
                        time = hours[i].value;
                        break;
                    }
                }
            }
        }

        const searchObjFromQuery = {
            sport: searchParams.get('sport') ? (searchParams.get('sport')?.split(';') as string[]) : '',
            district: districts.includes(searchParams.get('district') as string)
                ? searchParams.get('district')
                : 'Aveiro',
            date,
            time,
            timeUnit: searchParams.get('timeUnit') ?? '3600',
            privacy: privacies.includes(searchParams.get('privacy')?.toUpperCase() as string)
                ? searchParams.get('privacy')?.toUpperCase()
                : '',
            environment: environments.includes(searchParams.get('environment')?.toUpperCase() as string)
                ? searchParams.get('environment')?.toUpperCase()
                : '',
            clubName: searchParams.get('clubName') ? (searchParams.get('clubName')?.split(';') as string[]) : '',
        };

        const cleanSearchObj = removeEmptyeKeys(searchObjFromQuery);

        setSearchObj((previous) => ({...previous, ...cleanSearchObj}));
        setSearchParams((searchParams) => {
            Object.keys(cleanSearchObj).map((key) => {
                searchParams.set(
                    key,
                    Array.isArray(cleanSearchObj[key])
                        ? cleanSearchObj[key].join(',')
                        : threatSearchValues(key, cleanSearchObj[key])
                );
            });
            return searchParams;
        });

        const error = checkIfFieldsAreValidAndReturnError({...cleanSearchObj, ...inputObj} as SearchObjectType);

        if (error) {
            return;
        }
        search({...cleanSearchObj, ...inputObj} as SearchObjectType);
    };

    const handleInputChange = (name: string, value: string | string[], inputObj: Record<string, string> = {}) => {
        setSearchObj((prev) => {
            const newObj = {...prev, [name]: value};
            if (!checkIfFieldsAreValidAndReturnError(newObj)) {
                search({...newObj, ...inputObj});
            }
            return newObj;
        });
        setSearchParams((searchParams) => {
            searchParams.set(name, Array.isArray(value) ? value.join(';') : threatSearchValues(name, value));
            return searchParams;
        });
    };

    const search = (searchObj: SearchObjectType) => {
        const final: Record<string, any> = {
            ...searchObj,
            ...(searchObj.date && {date: searchObj.date.format('YYYY-MM-DD')}),
            ...(searchObj.time && {time: hours.find((hour) => hour.value === searchObj.time)!.label}),
            ...(searchObj.timeUnit && {
                timeUnit: timeUnits.find((timeUnit) => timeUnit.value === searchObj.timeUnit)!.label,
            }),
        };
        appService
            .api_searchClubs({...removeEmptyeKeys(final)}, role === 'club-admin' ? 'private' : undefined)
            .then((res) => {
                if (res.status !== 200) throw new Error();
                if (!isFirstSearchPerformed) {
                    setIsFirstSearchPerformed(true);
                }
                setResults(res.data);
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    return {
        availableHours,
        actions: {search, checkQueryString, fetchEverything, handleInputChange},
    };
};
