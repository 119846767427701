import {AppButton} from '../Common/Buttons/AppButton';
import styled from 'styled-components';

export const StyledAppButton = styled(AppButton).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isColorBlack',
})<{isColorBlack?: boolean}>`
console.log(isColorBlack)
    padding: 0 !important;
    justify-content: left !important;
    color: ${({isColorBlack}) => (isColorBlack ? 'black !important' : 'white !important')};
`;
